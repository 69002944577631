<form novalidate>
  <div data-aid="CustomerSearch" class="modal-content">
    <div class="modal-header modal-header--assertive">
      <button data-aid="button-CustomerSearch-add" type="button" class="close pull-right" (click)="hide.emit()" aria-label="Close">
        <i class="fa fa-times"></i>
      </button>
      <div class="modal-header__content">
        <i class="icon-rc-teams-icon"></i>
        <h5>ADD TEAM MEMBER</h5>
      </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="input-group">
                <div class="col-md-12">
                  <div class="col-md-12 search-title">
                    <span>Select Customer</span>
                  </div>
                  <input data-aid="customerSearch-input-search" id="customer-search" type="text" placeholder="Search by name" class="form-control"
                    (input)="onSearchChange($event)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loading" *ngIf="loading">
        <loaders-css [loader]="'ball-pulse'" [loaderClass]="'rc-loader-assertive'"></loaders-css>
      </div>
      <div *ngIf="!loading" class="search-result-list">
        <div *ngFor="let customer of customers" class="search-result-list-item" (click)="onSelectCustomer(customer)">
          <span>{{customer.name}}</span>
        </div>
      </div>
    </div>
  </div>
</form>