<div data-aid="RemoveUserFromTeamModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog rc-modal">
    <div class="modal-content">
      <div class="modal-header modal-header--assertive">
        <div class="modal-header__content">
          <i class="icon-rc-teams-icon"></i>
          <h5>Participant removed from the team</h5>
        </div>
      </div>
      <div class="modal-body">
        <div class="row notice-content">
          <span>
            <b>{{ player?.name }}</b> was removed from the team.</span>
          <div *ngIf="hasPayment()">
            <span>If you'd want to refund his payment, you can go to the <a href="#"
                (click)="navigateToInvoice($event)">invoice</a>
            </span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <rc-loading-btn [text]="'OK'" [loading]="false" (onClick)="hideModal()"
          [classes]="['button', 'button-assertive', 'button-padded', 'submit-btn']">
        </rc-loading-btn>
      </div>
    </div>
  </div>