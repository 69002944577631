<div data-aid="MatchEditModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog rc-modal" *ngIf="isShowing">
		<form class="form" [formGroup]="matchForm" novalidate (ngSubmit)="onSubmit(matchForm.value)">
			<div class="modal-content">
				<div class="modal-header modal-header--assertive">
					<button data-aid="button-MatchEditModal-close" type="button" class="close pull-right" (click)="cancel()" aria-label="Close">
						<i class="fa fa-times"></i>
					</button>
					<div class="modal-header__content">
						<i class="icon-rc-games-per-season"></i>
						<h5>{{ editMode ? 'GAME DETAILS' : 'ADD GAME' }}</h5>
					</div>
				</div>
				<div class="modal-body" [ngClass]="{ 'modal-body--disabled-form': disableEdit }">
					<div class="row">
						<div class="col-md-12">
							<div class="row">
								<div class="col-md-6" *ngIf="divisions?.length > 1 && !editMode">
									<div class="input-group">
										<label for="">DIVISION</label>
										{{ !divisionSelection?.length ? 'No divisions found' : '' }}
										<!-- <ng-select
                      *ngIf="divisionSelection?.length"
                      (selected)="divisionSelected($event)"
                      [items]="divisionSelection" placeholder="Select divisions">
                    </ng-select> -->
										<select
												*ngIf="divisionSelection?.length"
												(change)="divisionSelected($event.target.value)"
												name="divisionSelection"
												class="select"
												placeholder="Select divisions"
										>
											<option value="null">Select divisions</option>
											<option value="{{ i.id }}" [ngValue]="[i]" *ngFor="let i of divisionSelection">
												{{ i.text }}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div class="input-group">
										<label for="">ROUND</label>
										{{ !roundsSelection?.length ? 'No rounds found' : '' }}
										<select *ngIf="roundsSelection?.length" class="select" formControlName="roundId">
											<option [value]="null" disabled>Select round</option>
											<option [ngValue]="[i]" *ngFor="let i of roundsSelection">{{ i.text }}</option>
										</select>
									</div>
								</div>
								<div class="col-md-6">
									<div class="checkbox" style="margin-bottom: 10px; margin-top: 14px">
										<input data-aid="leagueMatchEdit-checkbox-exclude" type="checkbox" formControlName="excludeStandings" class="styled-checkbox" />
										<label>
											<b>Not counting for standings</b>
										</label>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div
											class="input-group"
											[ngClass]="{ 'has-error': !matchForm.get('teamA').valid && matchForm.get('teamA').touched }"
									>
										<label for="">TEAM A</label>
										<ng-container *ngIf="editMode">
											{{ match.match.participants[0].entity.name }}
										</ng-container>
										<ng-container *ngIf="!editMode">
											{{ teamsSelection?.length ? '' : 'No teams found' }}
											<select
											  data-aid="leagueMatchEdit-select-team"
												*ngIf="teamsSelection?.length"
												name="teamA"
												class="select"
												formControlName="teamA"
												[required]="true"
												placeholder="Select team"
											>
												<option [value]="null" disabled>Select a team</option>
												<option [ngValue]="[i]" *ngFor="let i of teamsSelection">{{ i.text }}</option>
											</select>
										</ng-container>
									</div>
								</div>
								<div class="col-md-6">
									<div
											class="input-group"
											[ngClass]="{ 'has-error': !matchForm.get('teamB').valid && matchForm.get('teamB').touched }"
									>
										<label for="">TEAM B</label>
										<ng-container *ngIf="editMode">
											{{ match.match.participants[1].entity.name }}
										</ng-container>
										<ng-container *ngIf="!editMode">
											{{ teamsSelection?.length ? '' : 'No teams found' }}
											<select
											  data-aid="leagueMatchEdit-select-divsion"
												*ngIf="teamsSelection?.length"
												(select)="divisionSelected($event)"
												name="teamA"
												class="select"
												formControlName="teamB"
												[required]="true"
												placeholder="Select team"
											>
												<option [value]="null" disabled>Select a team</option>
												<!-- option value="">Select a GL</option -->
												<option [ngValue]="[i]" *ngFor="let i of teamsSelection">{{ i.text }}</option>
											</select>
										</ng-container>
									</div>
								</div>
							</div>
							<!-- <div class="row">
                <div class="col-md-6">
                  <div class="input-group" [ngClass]="{'has-error': !matchForm.get('startDate').valid && matchForm.get('startDate').touched}">
                    <label for="">START DATE</label>
                    <rc-datepicker placeholder="Select match date"
                                   [required]="true"
                                   formControlName="startDate">
                    </rc-datepicker>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-group" [ngClass]="{'has-error': !matchForm.get('endTime').valid && matchForm.get('endTime').touched}">
                    <label for="">END DATE</label>
                    <rc-datepicker placeholder="Select match date"
                                   [required]="true"
                                   formControlName="endTime">
                    </rc-datepicker>
                  </div>
                </div>
              </div>-->
							<div class="row">
								<div data-aid="leagueMatchEdit-input-start" class="col-md-6">
									<div
											class="input-group"
											[ngClass]="{ 'has-error': !matchForm.get('startTime').valid && matchForm.get('startTime').touched }"
									>
										<label for="">START TIME</label>
										<sq-datetimepicker [mode]="'input'" [options]="dateTimeOptions" formControlName="startTime">
										</sq-datetimepicker>
									</div>
								</div>

								<div data-aid="leagueMatchEdit-input-end" class="col-md-6">
									<div
											class="input-group"
											[ngClass]="{ 'has-error': !matchForm.get('endTime').valid && matchForm.get('endTime').touched }"
									>
										<label for="">END TIME</label>
										<sq-datetimepicker [mode]="'input'" [options]="dateTimeOptions" formControlName="endTime">
										</sq-datetimepicker>
									</div>
								</div>
							</div>
							<div class="row">
								<div data-aid="leagueMatchEdit-select-venues" class="col-md-6">
									<div
											class="input-group"
											[ngClass]="{ 'has-error': !matchForm.get('venueId').valid && matchForm.get('venueId').touched }"
									>
										<label for="">Venue</label>
										{{ venuesSelection?.length ? '' : 'No venues found' }}
										<select
												*ngIf="venuesSelection?.length"
												(change)="venueSelected($event.target.value)"
												name="venueId"
												class="select"
												[disabled]="editMode"
												formControlName="venueId"
												[required]="false"
												placeholder="Select venue"
										>
											<option [value]="null">Select a venue</option>
											<option data-aid="option-venue" value="{{ i.id }}" *ngFor="let i of venuesSelection">{{ i.name }}</option>
										</select>
									</div>
								</div>

								<div data-aid="leagueMatchEdit-select-space" class="col-md-6">
									<div
											class="input-group"
											[ngClass]="{ 'has-error': !matchForm.get('spaceId').valid && matchForm.get('spaceId').touched }"
									>
										<label for="">Space</label>
										<ng-container *ngIf="loadingSpacesSelection">
											<loaders-css [loader]="'ball-pulse'" [loaderClass]="'rc-loader-assertive'"></loaders-css>
										</ng-container>
										<ng-container *ngIf="!loadingSpacesSelection">
											<ng-container *ngIf="!spacesSelection"> Select a venue first </ng-container>
											<ng-container *ngIf="spacesSelection && !spacesSelection.length"> No spaces found </ng-container>

											<select
													*ngIf="spacesSelection?.length"
													(change)="spaceSelected($event.target.value)"
													name="spaceId"
													class="select"
													[disabled]="editMode"
													formControlName="spaceId"
													[required]="false"
													placeholder="Select Space"
											>
												<option data-aid="option-space" [value]="null">Select a space</option>
												<option value="{{ i.id }}" *ngFor="let i of spacesSelection">{{ i.name }}</option>
											</select>
										</ng-container>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div data-aid="leagueMatchEdit-input-desc" class="input-group">
										<label for="">DESCRIPTION</label>
										<textarea formControlName="description" class="input" name="" cols="30" rows="2"></textarea>
									</div>
								</div>
							</div>

							<div class="row" *ngIf="reportPrintVisible">
								<div data-aid="leagueMatchEdit-input-location" class="col-md-12">
									<rc-loading-btn
											[text]="'DOWNLOAD PRINTABLE MATCH REPORT'"
											(onClick)="downloadPrintableReport()"
											[loading]="reportDownloadLoading"
											[classes]="['button', 'button-assertive', 'button-full']"
									>
									</rc-loading-btn>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer">
					<button data-aid="button-MatchEditModal-show"
							type="button"
							class="button button-empty pull-left match-cancel"
							(click)="cancelConfirmModal.showModal()"
							*ngIf="editMode"
					>
						<i class="fa fa-trash"></i> DELETE
					</button>
					<button data-aid="button-MatchEditModal-cancel"
							type="button"
							class="button button-empty pull-left match-cancel"
							(click)="cancelMatch()"
							*ngIf="editMode && match?.status !== 4"
					>
						<i class="fa fa-times"></i> Cancel this match
					</button>
					<button data-aid="button-MatchEditModal-save" type="button" class="button button-empty cancel-btn" (click)="cancel()">Cancel</button>
					<rc-loading-btn
							[text]="editMode ? 'SAVE CHANGES' : 'CREATE MATCH'"
							[disabled]="!matchForm.valid"
							(onClick)="onSubmit(matchForm.value)"
							[loading]="loading"
							[classes]="['button', 'button-assertive', 'button-padded', 'submit-btn']"
					>
					</rc-loading-btn>
				</div>
			</div>
		</form>
	</div>
</div>

<rc-confirmation-modal
		[type]="'danger'"
		[title]="'DELETE EVENT'"
		[content]="'Are you sure you want to delete this event? No one will be able to access this event any more.'"
		[confirmText]="'YES, DELETE'"
		(onConfirm)="delete()"
		#cancelConfirmModal="modal"
>
</rc-confirmation-modal>
