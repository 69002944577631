<div data-aid="SeasonCreatorBasic" class="row" [formGroup]="form">
	<div class="col-md-12" [formGroupName]="groupName">
		<div class="form__section">
			<div class="form__section__header">ABOUT</div>
			<div class="form__section__helper">Custom your season information</div>

			<div class="input-group">
				<label for="">
					<i class="icon-rc-calendar"></i> SEASON NAME
					<span class="pull-right">{{ form.get(groupName).get('name').value?.length }}/60</span>
				</label>

				<div class="input-wrapper">
					<span
						class="rc-tooltip"
						nzTooltipPlacement="right"
						nz-tooltip
						nzTooltipTitle='If you are running multiple seasons of the same league at the same time, each on a different day of the week or at a different facility, you can create the two concurrent seasons. See image below. If there is just one season at a time in the league, simply name the season the respective time period and year. "Spring 2017" or "Late Fall 2017."'
					>
						<i class="fa fa-info"></i>
					</span>
					<input data-aid="seasonCreator-input-name" type="text" class="input" formControlName="name" maxlength="60" />
				</div>
			</div>

			<div data-aid="seasonCreator-input-short" class="input-group">
				<label for="">
					<i class="icon-rc-long-des"></i> SHORT DESCRIPTION
					<span class="pull-right">{{ description_clean_text.length }}/2000</span>
				</label>
				<div class="input-wrapper">
					<span
						class="rc-tooltip"
						nzTooltipPlacement="right"
						nz-tooltip
						nzTooltipTitle="Only include information not provided in the league description.
                 You may want to write: what weeks games are played/not played,
                 how many times a week a team will play, season specific rules,
                 the location of post game drinks, and any other useful information."
					>
						<i class="fa fa-info"></i>
					</span>
					<rc-rich-text-wrapper
						[initialValue]="form.get(this.groupName).get('description').value || ''"
						max="2000"
						(handleChange)="shortDescInputChanged($event)"
						(setDescLength)="shortDescInputLengthChanged($event)"
					></rc-rich-text-wrapper>
				</div>
			</div>
			<div data-aid="seasonCreator-input-long" class="input-group">
				<label for="">
					<i class="icon-rc-long-des"></i> LONG DESCRIPTION
					<span class="pull-right">{{ longDescription_clean_text.length }}/2000</span>
				</label>
				<div class="input-wrapper">
					<span
						class="rc-tooltip"
						nzTooltipPlacement="right"
						nz-tooltip
						nzTooltipTitle="Only include information not provided in the league description.
                 You may want to write: what weeks games are played/not played,
                 how many times a week a team will play, season specific rules,
                 the location of post game drinks, and any other useful information."
					>
						<i class="fa fa-info"></i>
					</span>
					<rc-rich-text-wrapper
						[initialValue]="form.get(this.groupName).get('longDescription').value || ''"
						max="2000"
						(handleChange)="descInputChanged($event)"
						(setDescLength)="descInputLengthChanged($event)"
					></rc-rich-text-wrapper>
				</div>
			</div>
		</div>
	</div>
</div>
