/* * @jsx jsx */

import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { jsx, css } from '@emotion/react';
import { Pricify } from '@bondsports/utils';
import { productApi } from '../../lib/api/productsApi';
import { Button, getIcon, Checkbox } from '@bondsports/utils';
import { colors } from 'app/react/styles/theme';
import { Product } from 'app/react/types/product';
import { TranslationEn } from 'assets/i18n/en';
import { Table } from '../../components/shared/NewTable';
import { NO_SUBJECT } from '../../types/helpers';

const container = css`
	display: flex;
	flex-direction: column;
	max-width: 500px;

	h1 {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 2rem;
		line-height: 2.4rem;
		text-align: center;
		color: ${colors.brandPrimary};
	}

	h2 {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.2rem;
		line-height: 1.5rem;
		text-align: center;
		color: ${colors.formInputBg};
	}

	.error {
		width: 100%;
		margin: 1rem 0;
		text-align: center;
		min-height: 15px;
		color: ${colors.dangerRed};
	}
	.skeleton {
		animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
		background: #e4e4e4;
	}
	@-webkit-keyframes pulse {
		50% {
			opacity: 0.5;
		}
	}

	@keyframes pulse {
		50% {
			opacity: 0.5;
		}
	}
`;

const tableContainerCss = css`
	max-height: 400px;
	margin: 1rem;
	.MuiTableCell-stickyHeader {
		font-style: normal;
		font-weight: 500;
		font-size: 1.2rem !important;
		line-height: 1.5rem;
	}
	.MuiTableCell-root {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 130%;

		color: ${colors.brandPrimary};
	}
`;

const headerCss = css`
	border-bottom: 1px solid ${colors.borderSeperator};
	padding: 24px 40px;
`;

const resourceWrapper = css`
	display: flex;
	flex-wrap: wrap;
	padding: 0 30px;
`;

const buttonContainer = css`
	border-top: 1px solid ${colors.borderSeperator};
	padding: 24px 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.price {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: ${colors.brandPrimary};
		label {
			font-weight: 500;
			margin-right: 10px;
			text-transform: uppercase;
			color: ${colors.formInputBg};
		}
	}
`;

const loadingBox = css`
	width: 212px;
	height: 82px;
	display: block;

	margin: 10px;
`;

interface IResouce {
	id: number;
}

interface IProduct extends Product {
	resources: IResouce[];
	resourceType: string;
}

export const SchedulePopover = ({ product, handleSubmit }) => {
	const [productWithResources, setProductWithResources] = useState<IProduct>();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [selectedResources, setSelectedResources] = useState([]);
	const [err, setError] = useState('');

	const labels = TranslationEn.season.dashboard.scheduledPopover;
	useEffect(() => {
		if (product.id) {
			productApi.getResources(product.id, {includeResources:true}).then(res => {
				setProductWithResources(res[0]);
				setLoading(false);
				if (res[0].resources.length === product.quantity) {
					setSelectedResources(res[0].resources.map(resource => resource.id));
				}
			});
		}
	}, [product.id]);

	const handleResourceSelection = resource => {
		if (selectedResources.includes(resource)) {
			setSelectedResources(selectedResources.filter(id => id !== resource));
			setError('');
		} else {
			if (selectedResources.length !== Number(product.quantity)) {
				setSelectedResources([...selectedResources, resource]);
				setError('');
			} else {
				setError(`Oops, you can choose max ${product.quantity} sessions`);
			}
		}
	};

	const onSubmit = () => {
		const chosenResources = productWithResources.resources
			.map(re => {
				re['type'] = productWithResources.resourceType;
				return re;
			})
			.filter(r => selectedResources.includes(r.id));
		handleSubmit(chosenResources);
	};

	return (
		<div data-aid="schedulePopover" css={container}>
			<div css={headerCss}>
				<h1>
					{product.name} {labels.schedule}
				</h1>
				<h2>
					{labels.selectFor} {product.quantity} {labels.labelsActivity}
				</h2>
			</div>
			<div css={resourceWrapper}>
				{isLoading ? (
					<Fragment>
						<div className="skeleton" css={loadingBox} />
						<div className="skeleton" css={loadingBox} />
						<div className="skeleton" css={loadingBox} />
						<div className="skeleton" css={loadingBox} />
					</Fragment>
				) : productWithResources && productWithResources.resourceType === 'event' ? (
					<ResourceEventItems
						resources={productWithResources.resources}
						handleSelection={handleResourceSelection}
						selectedResources={selectedResources}
					/>
				) : (
					productWithResources.resources.map((resource, index) => (
						<ResourceItem
							key={index}
							resource={resource}
							checked={selectedResources.includes(resource.id)}
							handleSelection={handleResourceSelection}
						/>
					))
				)}
			</div>
			<div className="error">{err}</div>
			<div css={buttonContainer}>
				<div className="price">
					<label>{labels.price}</label>
					{Pricify(product.currPrice.price || 0)}
				</div>
				<Button data-aid="button-schedulePopover-select" theme="primary" sizer="M" onClick={onSubmit}>
					{labels.selectCustomer}
				</Button>
			</div>
		</div>
	);
};

const ResourceEventItems = ({ resources, handleSelection, selectedResources }) => {
	const [timeMapper, setMapper] = useState({});

	// should run on resource
	const DayOfWeek = (day: number) => {
		if (TranslationEn.days[day]) {
			return TranslationEn.days[day];
		}
		return '';
	};

	const labels = TranslationEn.season.dashboard.scheduledPopover;

	const eventsColumns = [
		{
			id: 'date',
			label: `${labels.date}`,
			type: 'custom',
			component: state => {
				return <CustomDate {...state} />;
			},
		},
		{
			id: 'time',
			label: `${labels.time}`,
			type: 'string',
		},
		{
			id: 'space',
			label: `${labels.resource}`,
			type: 'string',
		},
	];

	const rows = resources.map(r => {
		return {
			date: { value: r.startDateString },
			time: `${moment(r.startTime, 'HH:mm:ss').format('hh:mmA')} - ${moment(r.endTime, 'HH:mm:ss').format('hh:mmA')} `,
			space: '-',
			id: r.id,
		};
	});

	return (
		<div css={tableContainerCss}>
			<Table
				onSelect={val => handleSelection(val)}
				selectedArr={selectedResources}
				columns={eventsColumns}
				rows={rows}
				pagination={false}
				subject={NO_SUBJECT}
				isHoverRow={false}
				isSelectRow
			/>
		</div>
	);
};

const customCss = css`
	padding-right: 2rem;
	.date {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 130%;
		display: flex;
		align-items: center;
		color: ${colors.brandPrimary};
	}
	.day {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		display: flex;
		align-items: center;
		color: ${colors.formInputBg};
	}
`;

const CustomDate = ({ value }) => {
	return (
		<div css={customCss}>
			<div className="date">{moment(value).format('MMM DD, YYYY')}</div>
			<div className="day">{moment(value).format('ddd')}</div>
		</div>
	);
};

export const ResourceItemContainer = (checkable: boolean) => css`
	display: flex;
	flex-direction: column;
	border: 1px solid ${colors.borderPrimary};
	border-left: 4px solid ${colors.formInputBg};
	border-radius: 4px;
	padding: 1rem;
	position: relative;
	cursor: ${checkable ? 'pointer' : 'default'};
	margin: 5px;
	&:hover {
		box-shadow: ${checkable ? '0 0 4px rgba(0, 0, 0, 0.1)' : 'unset'};
	}

	.title {
		display: flex;
		svg {
			color: ${colors.lightText};
		}
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 1.7rem;
		color: ${colors.brandPrimary};
	}
	.dates {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: ${colors.brandPrimary};
		margin: 5px 0;
	}
`;

const checkboxContainer = css`
	position: absolute;
	right: 0;
`;

const hoursContainer = css`
	display: flex;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.brandPrimary};
	.days {
	}
	.hours {
		margin-left: 15px;
	}
`;

export const ResourceItem = ({ resource, checked, handleSelection, selectById = true, checkable = true }) => {
	const [timeMapper, setMapper] = useState({});

	// should run on resource
	const DayOfWeek = (day: number) => {
		if (TranslationEn.days[day]) {
			return TranslationEn.days[day];
		}
		return '';
	};

	useEffect(() => {}, [checked]);

	useEffect(() => {
		let startTime = '';
		let endTime = '';
		let mapper = {};

		resource.activityTimes.map((activityTime, index) => {
			if (index === 0) {
				startTime = activityTime.open;
				endTime = activityTime.close;
				mapper[`${startTime}-${endTime}`] = {
					startTime,
					endTime,
					days: [activityTime.dayOfWeek],
				};
			} else {
				startTime = activityTime.open;
				endTime = activityTime.close;
				if (mapper[`${startTime}-${endTime}`]) {
					mapper[`${startTime}-${endTime}`].days = [...mapper[`${startTime}-${endTime}`].days, activityTime.dayOfWeek];
				} else {
					mapper[`${startTime}-${endTime}`] = {
						startTime,
						endTime,
						days: [activityTime.dayOfWeek],
					};
				}
			}
		});
		setMapper(mapper);
		// timePhrase = DayOfWeek(activityTime.dayOfWeek);
	}, []);

	return (
		<div
			css={ResourceItemContainer(checkable)}
			onClick={() => {
				if (selectById) {
					handleSelection(resource.id);
				} else {
					handleSelection(resource);
				}
			}}
		>
			<div className="title">
				{getIcon('calendar', 'r')}
				{resource.name}
			</div>
			{checkable && (
				<div css={checkboxContainer}>
					<Checkbox
						checked={checked}
						isDisabled
						setChecked={() => {
							// handleSelection(resource.id);
						}}
						sizer="M"
					/>
				</div>
			)}
			<div className="dates">
				{moment(resource.startDate).format('MMM DD')} - {moment(resource.endDate).format('MMM DD,YYYY')}
			</div>
			<div>
				{Object.keys(timeMapper).map((timeRange, index) => {
					const arr = timeMapper[timeRange].days;
					let inARow = true;
					let timePhrase = '';
					arr.sort().map((day, index) => {
						if (index != 0) {
							if (day - 1 !== arr.sort()[index - 1]) {
								inARow = false;
							}
							timePhrase += `,${DayOfWeek(day)}`;
						} else {
							timePhrase += `${DayOfWeek(day)}`;
						}
					});

					if (inARow) {
						timePhrase = `${DayOfWeek(arr[0])} - ${DayOfWeek(arr[arr.length - 1])}`;
					}
					return (
						<div css={hoursContainer} key={index}>
							<div className="days">{timePhrase}</div>
							<div className="hours">{`${moment(timeMapper[timeRange].startTime, 'HH:mm:ss').format(
								'hh:mmA'
							)} -${moment(timeMapper[timeRange].endTime, 'HH:mm:ss').format('hh:mmA')}`}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
