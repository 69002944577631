import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PermissionDto, DeprecatedRoleDto } from '@bondsports/types';

@Injectable()
export class PermissionsService {
	permissions: BehaviorSubject<string[]> = new BehaviorSubject([]);
	roles: BehaviorSubject<string[]> = new BehaviorSubject([]);

	constructor(private http: HttpClient) {
	}

	async getMyPermissions(organizationId: number) {
		return await new Promise<void>((resolve, reject) => {
			this.http
				.get<DeprecatedRoleDto[]>(`${environment.CS_URLS.API_ROOT_V4}/roles/organization/${organizationId}/user/my`)
				.pipe(
					map((roles: DeprecatedRoleDto[]) => {
						const permissionsSet = new Set<string>();
						const rolesList: string[] = [];

						roles.forEach(role => {
							rolesList.push(role.name);
							role.permissions.forEach((permission: PermissionDto) => {
								permissionsSet.add(permission.name);
							});
						});

						this.roles.next(rolesList);
						this.permissions.next(Array.from(permissionsSet));
					}),
				)
				.subscribe(
					() => resolve(),
					(error) => reject(error),
				);
		});
	}

	/**
	 * Check if the user has at least one of the required permissions
	 * @param permissions - list of permissions to check
	 */
	isAllowed(...permissions: string[]): boolean {
		const currentPermissions = this.permissions.value;
		const requiredPermissions = permissions.flatMap(expandPermissions);

		return requiredPermissions.some(permission => currentPermissions.includes(permission));
	}

	/**
	 * Deprecated: Check if any of the provided strings appear as a substring in one of the roles.
	 * @param roles - list of strings to check
	 */
	deprecatedIsAllowed(...roles: string[]): boolean {
		// if the user has no roles at all, return true
		if (!this.roles.value.length) {
			return true;
		}
		const currentRoles = this.roles.value;
		return roles.some(role => currentRoles.some(currentRole => currentRole.toLowerCase().includes(role)));
	}
}

function expandPermissions(permission: string) {
	const parts = permission.split('.');
	return parts.map((_, index) => parts.slice(0, index + 1).join('.'));
}
