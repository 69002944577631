<nz-layout data-aid="MembershipPrices">
	<nz-row>
		<nz-col>
			<nz-form-label *ngIf="labelString">{{ labelString }}</nz-form-label>
			<p *ngIf="subLabelString">
				{{ subLabelString }}
			</p>
			<nz-card>
				<!-- Read only mode -->
				<nz-row
					nzType="flex"
					nzJustify="space-between"
					nzAlign="middle"
					style="margin-bottom: 8px"
					*ngFor="let membership of memberships; let idx = index"
				>
					<nz-divider *ngIf="idx > 0"></nz-divider>
					<div data-aid="MembershipPrices-name">
						<nz-switch [(ngModel)]="membership.active" class="switch-padding"></nz-switch>
						<span class="bold-text">{{ membership.name }}</span>
					</div>
					<div data-aid="membershipPrices-input-number1">
						<nz-input-number
							class="switch-padding"
							nzSize="large"
							[(ngModel)]="membership.price"
							[nzMin]="0"
							[nzStep]="1"
							[nzDisabled]="!membership.active"
						></nz-input-number>
						( 30% )
					</div>
				</nz-row>
				<!-- Add mode -->
				<nz-row nzType="flex" nzJustify="space-between" nzAlign="middle" style="margin-bottom: 8px" *ngIf="addPrice">
					<nz-divider></nz-divider>
					<label>
						<input data-aid="membershipPrices-input-name" class="half-row-button" nz-input placeholder="Price name" [(ngModel)]="tempMembership.name" />
					</label>
					<div data-aid="membershipPrices-input-number2">
						<nz-input-number
							class="switch-padding"
							nzSize="large"
							[(ngModel)]="tempMembership.price"
							[nzMin]="0"
							[nzStep]="1"
							[nzDisabled]="!tempMembership.active"
						></nz-input-number>
						( 30% )
					</div>
				</nz-row>
				<nz-row nzType="flex" nzAlign="middle" nzJustify="end" class="button-row">
					<!-- Button to add constraint -->
					<button data-aid="button-MembershipPrices-add" *ngIf="!addPrice" nz-button nzType="primary" (click)="addPriceBtn()">
						<i nz-icon nzType="plus" nzTheme="outline"></i>{{ 'Add a membership price' | uppercase }}
					</button>
					<!-- Buttons for edit/add constraint -->
					<nz-button-group *ngIf="addPrice">
						<button data-aid="button-MembershipPrices-cancel" nz-button nzType="danger" (click)="cancelMembershipBtn()">
							<i nz-icon nzType="close-circle" nzTheme="outline"></i>{{ 'Cancel' | uppercase }}</button
						><button data-aid="button-MembershipPrices-done" nz-button nzType="primary" class="success-button" (click)="savePriceBtn()">
							<i nz-icon nzType="check-circle" nzTheme="outline"></i>{{ 'Done' | uppercase }}
						</button>
					</nz-button-group>
				</nz-row>
			</nz-card>
		</nz-col>
	</nz-row>
</nz-layout>
