import { resourceApi } from '@app/react/lib/api/resourceApi';
import { IInstrocturesFilters, InstructorRow } from './types';
import { InstructorResourceDto, ResourceTypeEnum, UpdateInstructorDto, UpdateInstructorsDto } from '@bondsports/types';
import { ERR } from '@bondsports/utils';

export function mapDataToTable(data: InstructorResourceDto[]): InstructorRow[] {
	return data.map(resource => {
		return {
			id: resource.id,
			firstName: resource.firstName,
			lastName: resource.lastName,
			email: resource.email,
			status: resource.status,
			facilities: resource.facilities?.map(facility => facility.name) || [],
			activities: resource.sports || [],
			more: { instructorId: resource.id, status: resource.status, organizationId: resource.organizationId },
		};
	});
}

export const initialFilters: IInstrocturesFilters = {
	facilitiesIds: [],
	statuses: [],
	sports: [],
	resourceTypes: [],
	resourceIds: [],
};

export function updateInstrctor(
	organizationId: number,
	data: UpdateInstructorDto,
	handleSuccess: (firstInstructorStatus: InstructorResourceDto) => void,
	handleError: () => void
) {
	resourceApi
		.updateResource(organizationId, ResourceTypeEnum.INSTRUCTOR, { data: [{ ...data }] })
		.then(res => {
			if (ERR in res) {
				handleError();
			} else if ('data' in res) {
				// Currently we are updating only a single instructor
				const firstInstructorStatus: InstructorResourceDto = res.data?.[0];
				handleSuccess(firstInstructorStatus);
			}
		})
		.catch(err => {
			handleError();
		});
}
