import { ValuesOf } from '../types';

export enum CurrencySymbolEnum {
	USD = '$',
	EUR = '€',
}

export enum ContactMethodEnum {
	SMS = 'sms',
	EMAIL = 'email',
}

export enum ProductPaymentPlanTypeEnum {
	NO_PLAN = 'no_plan',
	ROLLING = 'rolling',
	PAYMENT_DATE = 'payment_date',
	CUSTOM = 'custom',
}

export enum ProductPaymentStatusEnum {
	INACTIVE = 'inactive',
	ACTIVE = 'active',
}

export const CARRIAGE_RETURNS_ALL_PLATFORMS = ['\n', '\r', '\n\r'];

export enum SizeEnum {
	EXTRA_SMALL = 'XS',
	SMALL = 'S',
	MEDIUM = 'M',
	LARGE = 'L',
}

export type TSizeEnum = ValuesOf<typeof SizeEnum>;

export const CHARACHERS = {
	DASH: '-',
	DOT: '•',
	COLON: ':',
	FORWARD_SLASH: '/',
	OPEN_PARENTHESES: '(',
	CLOSE_PARENTHESES: ')',
};

export const STYLE_UNITS = {
	PX: 'px',
	PERCENT: '%',
	REM: 'rem',
};

export const N_A = 'n/a';

export const ERR = 'err';

export const FIRST_PAGE = 1;
export const SELECT_ITEMS_PER_PAGE_DEFAULT = 8;

export const MAX_SELECT_OPTIONS_HEIGHT = 400;

export const ELLIPSES = '...';
