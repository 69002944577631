/** @jsxRuntime classic*/
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, Fragment, useMemo } from 'react';
import { ThreeRowModalLayout } from '../../../molecules/layouts';
import { DiscountHeader } from './discount-header';
import { CustomDiscount } from './discount-custom';
import { DiscountFooter } from './discount-footer';
import { Tabs } from '../../../molecules/tabs';
import { PromoCodeTab } from './promo-code/promo-code-tab';
import { useDiscountModal } from '../hooks/useDiscountModal';
import { DiscountModalProps, DiscountModalTabEnum } from '../types';
import { getActiveState } from '../utils';
import { Modal } from '../../../components/Modal/modal';

export const DiscountModal: FC<DiscountModalProps> = ({
	totalAmount,
	invoiceSubtotalBalance,
	discountType,
	reasons,
	isOpen,
	onClose,
	isAppliedPromoCode,
	withTabs = true,
	fetchData,
	isApplyProcessing,
}) => {
	const {
		handleOnChangeCustom,
		handleOnChangePromoCode,
		isValid,
		currentTab,
		customState,
		promoCodeState,
		remainingAmount,
		remainingPercentage,
		tabs,
		handleTabChange,
	} = useDiscountModal(withTabs, totalAmount, isAppliedPromoCode, invoiceSubtotalBalance);
	const mappedReasons = reasons?.map(reason => ({
		value: reason.id,
		label: reason.reason,
		key: `option-${reason.ordinal}`,
	}));

	const handleSubmit = () => {
		const activeState = getActiveState(customState, promoCodeState, currentTab);
		onClose(activeState);
	};

	const tabsComponents = useMemo(
		() => [
			{
				id: DiscountModalTabEnum.PROMO_CODE,
				component: fetchData ? (
					<PromoCodeTab
						key={DiscountModalTabEnum.PROMO_CODE}
						onChange={handleOnChangePromoCode}
						initialState={promoCodeState}
						fetchData={fetchData}
					/>
				) : (
					<Fragment />
				),
			},
			{
				id: DiscountModalTabEnum.CUSTOM,
				component: (
					<CustomDiscount
						key={DiscountModalTabEnum.CUSTOM}
						totalAmount={totalAmount}
						remainingAmount={remainingAmount}
						remainingPercentage={remainingPercentage}
						reasons={mappedReasons}
						onChange={handleOnChangeCustom}
						discountApplicant={discountType}
						initialState={customState}
					/>
				),
			},
		],
		[totalAmount, remainingAmount, remainingPercentage, mappedReasons, promoCodeState, customState]
	);

	const activeTabComponent = tabsComponents.find(tab => tab.id === currentTab)?.component;

	return (
		<Modal
			isShowing={isOpen}
			toggle={() => onClose()}
			padding={0}
			minWidth={'480px'}
			maxWidth={'480px'}
			isDismissable
		>
			<ThreeRowModalLayout
				headerSeparator
				footerSeparator
				iswithTabs={withTabs}
				header={
					<DiscountHeader
						totalAmount={totalAmount}
						invoiceSubtotalBalance={invoiceSubtotalBalance}
						type={discountType}
						tabs={
							withTabs && currentTab ? (
								<Tabs tabs={tabs} currentTab={currentTab} handleTabSelect={handleTabChange} fullWidthSpread />
							) : (
								<Fragment />
							)
						}
					/>
				}
				content={
					withTabs ? (
						activeTabComponent
					) : (
						<CustomDiscount
							totalAmount={invoiceSubtotalBalance ?? totalAmount}
							remainingAmount={remainingAmount}
							remainingPercentage={remainingPercentage}
							reasons={mappedReasons}
							onChange={handleOnChangeCustom}
							discountApplicant={discountType}
						/>
					)
				}
				contentMinHeight={'280px'}
				footer={
					<DiscountFooter
						isValid={isValid}
						onCancel={() => onClose()}
						onSubmit={handleSubmit}
						isProcessing={isApplyProcessing}
					/>
				}
			/>
		</Modal>
	);
};
