import React, { useMemo, useState } from 'react';
import * as dayjs from 'dayjs';
import * as moment from 'moment';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
	EventAsSeasonSegment,
	IBasicSpaceAndSlotCreator,
	ProgramSeason,
	ResourceNameTypeEnum,
	SeasonAsSeasonSegment,
	Slot,
} from '@bondsports/types';

import { environment } from '../../../environments/environment';
import { GenderEnum, LevelOfPlayEnum } from 'app/shared/services/programs/programs.service';
import { getProductsBySeason } from 'app/react/lib/api/seasonDeshboardApi';
import { programsApi, IUpdateEvents, MoveParticipantsRequestParams } from '../lib/api/programsApi';
import { apiDateStringWithoutZ, bondsportsDays, createDefaultDateRange } from '../lib/dates';
import { sessionStore } from '../stores/sessionStore';
import { SportsEnum } from 'app/shared/services/utils/sports.service';
import { useTimeFormats } from './useTimeFormats';
import { facilityApi } from '../lib/api/facilityApi';
import { useOrganization } from './useOrganization';
import { useCalendar, ITimeSlot } from './useCalendar';
import { timeSerialization } from '../components/calendar/newReservation/lib';
import { EDateTimeFormats } from '../types/times';
import { TranslationEn } from '@assets/i18n/en';
import { ENotificationType } from '@bondsports/utils';
import { useNotification } from './useNotification';
import { DateTimeFormats } from '@bondsports/date-time';
import { isEqual } from 'lodash';
import { IApiError } from '@app/react/lib/network';

/**
 * To be used at the top level to prevent an old session
 * from being displayed after navigating to another one
 */
export const useResetSession = () => {
	for (const state of Object.values(sessionStore)) {
		useResetRecoilState(state)?.apply(null);
	}
};

export const useSession = () => {
	const { generateDelta } = useTimeFormats();
	const [sessionState, setSessionState] = useRecoilState(sessionStore.sessionState);
	const [segments, setSegments] = useRecoilState<any>(sessionStore.segments);
	const [scheduleDateRange, setScheduleDateRange] = useRecoilState(sessionStore.scheduleDateRangeState);
	/**
	 * @deprecated
	 * use useEvents
	 */
	const [events, setEvents] = useRecoilState(sessionStore.events);
	const [participants, setParticipants] = useRecoilState(sessionStore.participants);
	const [isSessionHasSegments, setSessionHasSegments] = useRecoilState(sessionStore.isSessionHasSegments);
	const [sessionApiLoading, setSessionApiLoading] = useRecoilState(sessionStore.participantsLoading);
	const [products, setProducts] = useRecoilState<any[]>(sessionStore.products);
	const [isFetchingSegments, setIsFetchingSegments] = useRecoilState(sessionStore.isFetchingSegments);
	const [isFetchingProducts, setIsFetchingProducts] = useRecoilState(sessionStore.isFetchingProducts);
	/**
	 * @deprecated
	 * use useEvents
	 */
	const [isFetchingEvents, setIsFetchingEvents] = useRecoilState(sessionStore.isFetchingEvents);
	/**
	 * @deprecated
	 * use useEvents
	 */
	const [isUpdatingEvents, setIsUpdatingEvents] = useRecoilState(sessionStore.isUpdatingEvents);
	/**
	 * @deprecated
	 * use useEvents
	 */
	const [isErrorUpdatingEvent, setIsErrorUpdatingEvent] = useRecoilState(sessionStore.isErrorUpdatingEvent);
	const [spaces, setSpaces] = useRecoilState(sessionStore.spaces);
	const [isFetchingSpaces, setIsFetchingSpaces] = useRecoilState(sessionStore.isFetchingSpaces);
	const [isEditingSession, setIsEditingSession] = useRecoilState(sessionStore.isEditingSession);
	const [isFetchingData, setIsFetchingData] = useRecoilState(sessionStore.isFetchingData);
	const [editSessionError, setEditSessionError] = useRecoilState(sessionStore.editSessionError);
	/**
	 * @deprecated
	 * use useEvents
	 */
	const [isAddingEvents, setIsAddingEvents] = useRecoilState(sessionStore.isAddingEvents);
	/**
	 * @deprecated
	 * use useEvents
	 */
	const [addEventsError, setAddEventsError] = useRecoilState(sessionStore.addEventsError);
	const [totalParticipantsAmount, setTotalParticipantsAmount] = useRecoilState(sessionStore.totalParticipantsAmount);
	const [totalParticipantsWithoutPunchPass, setTotalParticipantsWithoutPunchPass] = useRecoilState(
		sessionStore.totalParticipantsWithoutPunchPass
	);
	const [isFetchingParticipants, setIsFetchingParticipants] = useRecoilState(sessionStore.isFetchingParticipants);
	const [fetchProductsError, setFetchProductsError] = useState<boolean>(false);
	const [participantsPage, setParticipantsPage] = useRecoilState(sessionStore.participantsPage);
	const [moveParticipantsError, setMoveParticipantsError] = useState<string>('');
	const [isMovingParticipant, setIsMovingParticipant] = useState<boolean>(true);

	const sessionId = sessionState.id;
	const { organizationId } = useOrganization();
	const { handleNavigateToConflicts } = useCalendar();
	const { setPopupNotification } = useNotification();

	// should be session interface
	const initiateSessionState = (data: any) => {
		setSessionState({
			...data,
			registrationEndDate: apiDateStringWithoutZ(data?.registrationEndDate ?? ''),
			registrationStartDate: apiDateStringWithoutZ(data?.registrationStartDate ?? ''),
			earlyRegistrationStartDate: apiDateStringWithoutZ(data?.earlyRegistrationStartDate ?? ''),
			earlyRegistrationEndDate: apiDateStringWithoutZ(data?.earlyRegistrationEndDate ?? ''),
			lateRegistrationStartDate: apiDateStringWithoutZ(data?.lateRegistrationStartDate ?? ''),
			lateRegistrationEndDate: apiDateStringWithoutZ(data?.lateRegistrationEndDate ?? ''),
			startDate: apiDateStringWithoutZ(data?.startDate ?? ''),
			endDate: apiDateStringWithoutZ(data?.endDate ?? ''),
		});
	};

	const initiateEvents = (data: any) => {
		setEvents(data);
		setIsFetchingEvents(false);
	};

	/**
	 * @deprecated
	 * use useEvents
	 */
	const fetchEvents = () => {
		programsApi.getEvents(sessionId).then(res => {
			if (res?.data?.events) {
				initiateEvents(res.data.events);
			}
		});
	};

	const createDateRange = (res: ProgramSeason) => {
		const now: Date = new Date();
		const startDate: Date = dayjs(res.startDate).utc().isBefore(now) ? now : res.startDate;
		const endDate: Date = dayjs(res.endDate).utc().isAfter(now) ? res.endDate : now;

		return {
			startDate: dayjs(startDate).utc().startOf('day').format(DateTimeFormats.YYYY_MM_DD_T_HH_MM_SS),
			endDate: dayjs(endDate).utc().endOf('day').format(DateTimeFormats.YYYY_MM_DD_T_HH_MM_SS),
		};
	};

	const fetchSessionData = (sessionId: number) => {
		if (organizationId) {
			setIsFetchingData(true);
			programsApi.getSession(organizationId, sessionId).then(res => {
				setIsFetchingData(false);
				if ((res as IApiError).err) {
					const error: string = (res as IApiError).err;
					setPopupNotification(String(error), ENotificationType.warning);
				} else {
					const dateRange = createDateRange(res as ProgramSeason);
					if ((!scheduleDateRange.startDate && !scheduleDateRange.endDate) || !isEqual(scheduleDateRange, dateRange)) {
						setScheduleDateRange(dateRange);
					}
					initiateSessionState(res);
				}
			});
		}
	};

	const moveParticipant = (organizationId: number, moveParticipantRequest: MoveParticipantsRequestParams) => {
		if (!isMovingParticipant) {
			setIsMovingParticipant(true);
		}
		programsApi.moveParticipant(moveParticipantRequest, organizationId).then(response => {
			if (response.data) {
				setIsMovingParticipant(false);
				// If there was an error, then the response will contain the error object
			} else if (response.message) {
				setMoveParticipantsError(response.message);
			}
		});
	};

	const initiateSessionProducts = (allProducts: any[]) => {
		setProducts(allProducts);
		setIsFetchingProducts(false);
	};

	const initiateSegments = (allSegments: (EventAsSeasonSegment | SeasonAsSeasonSegment)[]) => {
		setSegments(allSegments);
		setIsFetchingSegments(false);
	};

	const handleFetchProductsError = () => {
		fetchProductsError ? setFetchProductsError(false) : setFetchProductsError(true);
	};

	const fetchProductsBySession = (sessionId: number, isAddons: boolean, organizationId: number) => {
		!isFetchingProducts && setIsFetchingProducts(true);
		getProductsBySeason(organizationId, sessionId, isAddons).then(response => {
			if (response) {
				initiateSessionProducts(response);
			} else {
				handleFetchProductsError();
			}
		});
	};

	/**
	 * @deprecated
	 * use useEvents
	 */
	const updateSingleEvent = (
		organizationId: number,
		sessionId: number,
		eventId: number,
		data: IUpdateEvents,
		callback: () => void,
		handleError: (message: string) => void
	) => {
		programsApi
			.updateEvent(organizationId, sessionId, eventId, data)
			.then(res => {
				if (res.data) {
					callback();
					fetchSessionData(sessionId);
				}
			})
			.catch(() => handleError(TranslationEn.genericError));
	};

	const fetchSegments = async (
		ignoreEventType?: boolean,
		sessionID?: number,
		startDate?: string,
		endDate?: string,
		attendeeId?: number
	): Promise<number[]> => {
		setSessionHasSegments(false);
		setIsFetchingSegments(true);

		return programsApi
			.getSegments(sessionID || sessionId, !!ignoreEventType, startDate, endDate, attendeeId)
			.then(res => {
				const segments = Array.isArray(res) ? res : [];

				if (segments) {
					if (ignoreEventType) {
						// TODO: filter(...) left here for backwards compatibility.
						// Once API handles ignoreEvents, the list here will be empty anyway
						const arr = segments.filter(item => item.segmentType !== 'event').sort((a, b) => a.id - b.id);

						setSessionHasSegments(arr.length > 0);
						initiateSegments(arr);
						return arr.map(a => a.id);
					} else {
						setSessionHasSegments(segments.every(segment => segment.segmentType !== ResourceNameTypeEnum.EVENT));
						initiateSegments(segments);
						return segments.map(s => s.id);
					}
				}
			})
			.then(ids => {
				setSessionApiLoading(false);
				setIsFetchingSegments(false);
				return ids;
			});
	};

	const fetchParticipantsWithoutLoad = (filter?: string, customPage?: number) => {
		setIsFetchingParticipants(true);

		const page = customPage ?? participantsPage;

		programsApi
			.getParticipants(organizationId, sessionState.id, page, filter)
			.then(res => {
				setParticipants(page === 1 ? res.data : [...participants, ...res.data]);

				if (res.meta?.totalItems) {
					setTotalParticipantsAmount(res.meta.totalItems);
				}
			})
			.finally(() => {
				setIsFetchingParticipants(false);
				setSessionApiLoading(false);
			});
	};

	const fetchSessionParticipants = (filter?: string) => {
		setParticipantsPage(1);
		setSessionApiLoading(true);
		fetchParticipantsWithoutLoad(filter ?? '', 1);
	};

	const fetchTotalParticipantsWithoutPunchPass = async () => {
		// TODO: totalItems could be 0 when backend supports fetching an empty list
		const res = await programsApi.getParticipants(organizationId, sessionId, 1, undefined, 1, false);

		setTotalParticipantsWithoutPunchPass(res?.meta?.totalItems);
	};

	const initiateSessionSpaces = spaces => {
		setIsFetchingSpaces(false);
		setSpaces(spaces);
	};

	const fetchSessionSpaces = (facilityId: number) => {
		!isFetchingSpaces && setIsFetchingSpaces(true);
		facilityApi.getResourcesByFacilityID(organizationId, facilityId).then(res => {
			res.data && initiateSessionSpaces(res.data);
		});
	};

	const editSessionSchedule = (sessionId: number, data: any) => {
		programsApi.recreateSession(sessionId, data).then(res => {
			if (res.data) {
				setIsEditingSession(false);
			} else if (res.err) {
				setEditSessionError(res.err);
			}
		});
	};

	const timeDetailsFormatter = (segmentState: any, isSimple?: boolean) => {
		let time, date;

		if (Object.keys(segmentState).includes('isSelected') || isSimple) {
			const diff = moment(segmentState.activityTimes[0].close, 'HH:mm a').diff(
				moment(segmentState.activityTimes[0].open, 'HH:mm a'),
				'minutes'
			);

			const label = `${bondsportsDays[segmentState.activityTimes[0].dayOfWeek]}, ${moment(
				segmentState.activityTimes[0].open,
				'HH:mm'
			).format('hh:mm a')} - ${moment(segmentState.activityTimes[0].close, 'HH:mm').format(
				'hh:mm a'
			)} ( ${generateDelta(diff)})`;

			time = label;
		} else {
			if (segmentState.segmentType === 'program_season') {
				const hours = {};
				segmentState.activityTimes.map(t => {
					//day of week
					const newKey = `${t.open}_${t.close}`;
					if (hours[newKey]) {
						hours[newKey] = [...hours[newKey], t.dayOfWeek];
					} else {
						hours[newKey] = [t.dayOfWeek];
					}
				});
				const arr = [];

				Object.keys(hours).map((h, j) => {
					const hour = h.split('_');
					const m = moment(hour[1], 'HH:mm:ss').diff(moment(hour[0], 'HH:mm:ss'), 'minutes');
					let days = '';
					hours[h].map((day, index) => {
						if (index == 0) {
							days += bondsportsDays[day];
						} else {
							days += ', ' + bondsportsDays[day];
						}
					});
					arr.push(
						<label key={j}>
							{days +
								', ' +
								moment(hour[0], 'HH:mm:ss').format('hh:mm A') +
								' - ' +
								moment(hour[1], 'HH:mm:ss').format('hh:mm A')}
							<span> ({generateDelta(m)})</span>
						</label>
					);
				});

				time = <div style={{ display: 'flex', flexDirection: 'column' }}>{arr}</div>;
			} else {
				const m = moment(segmentState.endTime, 'HH:mm:ss').diff(moment(segmentState.startTime, 'HH:mm:ss'), 'minutes');
				time = (
					<label>
						{moment(segmentState.startDateString).format('ddd') +
							', ' +
							moment(segmentState.startTime, 'HH:mm:ss').format('hh:mm A') +
							' - ' +
							moment(segmentState.endTime, 'HH:mm:ss').format('hh:mm A')}
						<span> ({generateDelta(m)})</span>
					</label>
				);
			}
		}

		date =
			moment(segmentState.startDate).format('MMM DD') + ' - ' + moment(segmentState.endDate).format('MMM DD, YYYY');

		return { time, date };
	};

	const getLevelOfPlayText = (ids: number[]): string => {
		let tempString = '';
		for (const level of ids) {
			tempString = tempString + '-' + LevelOfPlayEnum[level];
		}
		return tempString;
	};

	const generatedPublicLink = () => {
		let publicSiteUrl =
			environment.CONSUMER_SITE_URL +
			'/activity/programs/' +
			(sessionState.gender === GenderEnum.OTHER ? 'coed' : GenderEnum[sessionState.gender]) +
			getLevelOfPlayText(sessionState.level) +
			'-' +
			SportsEnum[sessionState.sport] +
			'/' +
			sessionState.programId +
			'/season/' +
			sessionState.name.toLowerCase().replace(/[^a-zA-Z0-9-_]/g, '') +
			'/' +
			sessionState.id;
		publicSiteUrl = publicSiteUrl.replace(/ /g, '-').toLowerCase();

		return publicSiteUrl;
	};

	const openData = useMemo(() => {
		let num = undefined;
		let type = undefined;
		if (sessionState.registrationConstraints) {
			if (sessionState.registrationConstraints.openNumMinutes) {
				type = 'hours';
				num = sessionState.registrationConstraints.openNumMinutes / 60;
			}
			if (sessionState.registrationConstraints.openNumDays) {
				if (sessionState.registrationConstraints.openNumDays % 30 === 0) {
					type = 'months';
					num = sessionState.registrationConstraints.openNumDays / 30;
				} else {
					if (sessionState.registrationConstraints.openNumDays % 7 === 0) {
						type = 'weeks';
						num = sessionState.registrationConstraints.openNumDays / 7;
					} else {
						type = 'days';
						num = sessionState.registrationConstraints.openNumDays;
					}
				}
			}
		}
		return { num, type };
	}, [sessionState]);

	const closeData = useMemo(() => {
		let num = undefined;
		let type = undefined;
		if (sessionState.registrationConstraints) {
			if (sessionState.registrationConstraints.closeNumMinutes) {
				type = 'hours';
				num = sessionState.registrationConstraints.closeNumMinutes / 60;
			}
			if (sessionState.registrationConstraints.closeNumDays) {
				if (sessionState.registrationConstraints.closeNumDays % 30 === 0) {
					type = 'months';
					num = sessionState.registrationConstraints.closeNumDays / 30;
				} else {
					if (sessionState.registrationConstraints.closeNumDays % 7 === 0) {
						type = 'weeks';
						num = sessionState.registrationConstraints.closeNumDays / 7;
					} else {
						type = 'days';
						num = sessionState.registrationConstraints.closeNumDays;
					}
				}
			}
		}
		return { num, type };
	}, [sessionState]);

	interface IEventData {
		startTime: string;
		endTime: string;
		startDate: string;
		endDate: string;
		spaces?: IBasicSpaceAndSlotCreator[];
		spaceId?: number | string;
		resources?: IBasicSpaceAndSlotCreator[];
		resourceIds?: number[];
		slotId?: number;
		slots?: Slot[];
		id?: number;
		parentSlotId?: number;
	}

	/**
	 * refacor required- the given time foramts have been tested only with program events
	 * @param event a prpogrm event, only {startTime, endTime, startDate, endDate, spaces, slotId} data is needed though
	 * @returns a timeslot object needed for handleNavigateToConflicts function
	 */
	const createTimeSlot = <T extends IEventData>(event: T): ITimeSlot => {
		const { startTime, endTime, startDate, endDate, resourceIds, slotId } = event;
		let resourceId: number;

		//for next rerenders, then resourceIds will be exist on 'values' from FormContext:
		if (resourceIds) {
			const lastPickedResourceIdx = resourceIds.length - 1;
			resourceId = +resourceIds[lastPickedResourceIdx];

			//when navigating direcly from attendance, or from edit:
		} else if (event.slots) {
			resourceId = slotId ? event.slots.find(slot => slot.id === slotId)?.spaceId : event.spaces[0]?.id;
		}

		const id = slotId ?? event.parentSlotId ?? event.slots[0].id; //TODO: handle multiple slots
		const obj = {
			resourceId,
			startTime: timeSerialization(startTime),
			endTime: timeSerialization(endTime),
			startDate: dayjs(startDate, EDateTimeFormats.SLASHED_DATE).format(EDateTimeFormats.YYYY_MM_DD),
			endDate: dayjs(endDate, EDateTimeFormats.SLASHED_DATE).format(EDateTimeFormats.YYYY_MM_DD),
			relevantSlotId: id,
			id,
		};

		return obj;
	};

	/**
	 * This is a function for navigating from a modal or page, to the ConflictsPanel.
	 * *refacor required- the given time foramts this function relys on, have been tested only with program events
	 * @param obj.event a prpogrm event, only {startTime, endTime, startDate, endDate, spaces, slotId} data is needed though
	 * @param obj.isFullpage a boolean to indicate whether a full-page, restricted calendar view, is needed (e.g from schedule)
	 * @param obj.callback a toggle function to close the current modal /popup /page you redireted from
	 */
	const navigateToConflicts = <T extends IEventData>({
		event,
		isFullpage = false,
		callback,
	}: {
		event: T;
		isFullpage?: boolean;
		callback?: () => void;
	}) => {
		callback && callback();
		const timeSlot = createTimeSlot(event);
		handleNavigateToConflicts({ ...timeSlot, isFullpage });
	};

	const defaultDateRange = useMemo(
		() => createDefaultDateRange(sessionState.startDate, sessionState.endDate, sessionState.facilityTimezone),
		[sessionState.startDate, sessionState.endDate, sessionState.facilityTimezone]
	);

	return {
		products,
		spaces,
		sessionApiLoading,
		sessionState,
		sessionId,
		segments,
		participants,
		isSessionHasSegments,
		openData,
		closeData,
		isErrorUpdatingEvent,
		isUpdatingEvents,
		setIsErrorUpdatingEvent,
		setIsUpdatingEvents,
		updateSingleEvent,
		isFetchingSpaces,
		isFetchingSegments,
		isFetchingProducts,
		isMovingParticipant,
		isEditingSession,
		moveParticipant,
		isAddingEvents,
		setIsAddingEvents,
		editSessionSchedule,
		fetchSessionSpaces,
		fetchSessionData,
		fetchSegments,
		fetchSessionParticipants,
		timeDetailsFormatter,
		generatedPublicLink,
		fetchProductsBySession,
		setIsEditingSession,
		isFetchingData,
		editSessionError,
		setEditSessionError,
		moveParticipantsError,
		setMoveParticipantsError,
		addEventsError,
		setAddEventsError,
		fetchProductsError,
		handleFetchProductsError,
		totalParticipantsAmount,
		isFetchingParticipants,
		fetchParticipantsWithoutLoad,
		setParticipantsPage,
		participantsPage,
		createTimeSlot,
		navigateToConflicts,
		fetchTotalParticipantsWithoutPunchPass,
		totalParticipantsWithoutPunchPass,
		initiateSessionState,
		setScheduleDateRange,
		scheduleDateRange,
		defaultDateRange,
	};
};
