<div data-aid="ConfirmationModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div
		class="modal-dialog rc-modal RCConfirmationModal"
		[ngClass]="{
			'RCConfirmationModal--success': type === 'success',
			'RCConfirmationModal--danger': type === 'danger'
		}"
	>
		<div class="modal-content">
			<div class="modal-header">
				<div class="header-icon">
					<i [ngClass]="type === 'success' ? 'icon-rc-wand' : 'fa fa-times-circle'"></i>
				</div>
				<div class="header-content">
					<h5 class="text-uppercase">
						{{ title }}
					</h5>
				</div>
			</div>
			<div class="modal-body">
				<p>
					{{ content }}
				</p>
			</div>
			<div class="modal-footer">
				<button data-aid="button-ConfirmationModal-cancel" class="button button-empty cancel-btn" (click)="modal.hide()">Cancel</button>
				<rc-loading-btn [text]="confirmText" (onClick)="confirm()" [classes]="['button', 'button-success']">
				</rc-loading-btn>
			</div>
		</div>
	</div>
</div>
