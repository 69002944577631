<div data-aid="TournamentScheduleGenerator" class="row" [formGroup]="form">
	<div class="col-md-12">
		<div class="form__section">
			<div class="form__section__header">TOURNAMENT DATES</div>
			<div class="form__section__helper">
				According to the number of teams and the available facility times we’ll generate your schedule, in case the date
				range you’ve entered is too short/ long we will help you make the necessary changes.
			</div>
			<div class="input-group">
				<div class="row">
					<div class="col-lg-6">
						<div data-aid="picker-start" class="schedule-item">
							<div class="item-side number-side">
								{{ form.get('startDate')?.value | date: 'd' }}
							</div>
							<div
								class="item-side text-side"
								(click)="startDatePicker.togglePicker($event); endDatePicker.showDatePicker = false"
							>
								<div class="item-top-label">START DATE</div>
								<div class="item-mid-label text-uppercase">
									{{ form.get('startDate')?.value | date: 'MMMM' }}
								</div>
								<div class="item-bottom-label">
									{{ form.get('startDate')?.value | date: 'y' }}
								</div>
								<div class="date-select-btn">
									<i class="icon-rc-calendar"></i>
								</div>
							</div>
							<rc-datepicker
								formControlName="startDate"
								style="position: relative; left: -157px; top: 74px"
								#startDatePicker="picker"
								[hideInput]="true"
							></rc-datepicker>
							<span class="corner bottom-left"></span>
							<span class="corner bottom-right"></span>
							<span class="corner top-left"></span>
							<span class="corner top-right"></span>
						</div>
					</div>
					<div class="col-lg-6">
						<div data-aid="picker-end" class="schedule-item">
							<div class="item-side number-side">
								{{ form.get('endDate')?.value | date: 'd' }}
							</div>
							<div
								class="item-side text-side"
								(click)="endDatePicker.togglePicker($event); startDatePicker.showDatePicker = false"
							>
								<div class="item-top-label">END DATE</div>
								<div class="item-mid-label text-uppercase">
									{{ form.get('endDate')?.value | date: 'MMMM' }}
								</div>
								<div class="item-bottom-label">
									{{ form.get('endDate')?.value | date: 'y' }}
								</div>
								<div class="date-select-btn">
									<i class="icon-rc-calendar"></i>
								</div>
							</div>
							<rc-datepicker
								formControlName="endDate"
								style="position: relative; left: -157px; top: 74px"
								#endDatePicker="picker"
								[hideInput]="true"
							></rc-datepicker>
							<span class="corner bottom-left"></span>
							<span class="corner bottom-right"></span>
							<span class="corner top-left"></span>
							<span class="corner top-right"></span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form__section">
			<div class="form__section__header">BRACKET TEAMS</div>
			<div class="form__section__helper">You can create an empty brackets schedule or select specific teams</div>
			<div class="input-group">
				<div class="empty-brackets-wrapper">
					<div class="checkbox">
						<input data-aid="tournamentSchedule-empty" formControlName="emptyGenerate" type="checkbox" class="styled-checkbox" checked />
						<label for=""> CREATE EMPTY BRACKETS FOR </label>
					</div>
					<select name="" class="select" formControlName="emptyGenerateNumber">
						<option value="4">4</option>
						<option value="8">8</option>
						<option value="16">16</option>
						<option value="32">32</option>
						<option value="64">64</option>
						<option value="128">128</option>
					</select>
					<span>TEAMS</span>
				</div>
			</div>
			<div class="items-divider" *ngIf="teams?.length">
				<span>OR</span>
			</div>
			<div class="input-group" *ngIf="teams?.length">
				<div class="TeamParticipantsSelector">
					<div class="row">
						<div class="col-md-12">
							<h5 class="label-title">PARTICIPATING TEAMS ({{ getSelectedTeams().length }})</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-3 cold-md-3" *ngFor="let seasonTeam of teams">
							<div
								class="box-item box--shadow team-box-item box-top-border box-top-border--small"
								(click)="seasonTeam.active = !seasonTeam.active; $event.stopPropagation()"
							>
								<div class="team-box-item__header">
									<div class="checkbox">
										<input data-aid="checkbox-season" type="checkbox" class="styled-checkbox" [checked]="seasonTeam.active" />
										<label for=""></label>
									</div>
									<h3 class="text-center">{{ seasonTeam?.team?.name }}</h3>
									<div class="team-box-item__header__label">{{ seasonTeam.team?.teamMembers?.length }} athletes</div>
								</div>
								<div class="team-box-item__details">
									<div class="member-data" *ngIf="seasonTeam.captain">
										<div class="member-image">
											<div
												class="image-wrapper image--round image--border image--shadow"
												[rcBackgroundImage]="seasonTeam.captain?.profilePicture"
											></div>
										</div>
										<div class="member-info text-uppercase">
											{{ seasonTeam.captain?.firstName }} {{ seasonTeam.captain?.lastName }}
										</div>
									</div>
									<div class="member-status" *ngIf="seasonTeam.captain">
										<i class="icon-rc-star-icon"></i> team captain
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form__section">
			<div class="form__section__header">GAME FACILITIES & TIMES</div>
			<div class="form__section__helper">Set up the times and locations in order to auto assign the tournament</div>
			<div [formArrayName]="'activityTimes'">
				<div data-aid="activity" *ngFor="let activity of activitiesArray.controls; let i = index">
					<rc-activity-time-box-widget
						[arrayName]="'activityTimes'"
						[form]="form"
						[arrayGroup]="activity"
						[groupName]="i"
					>
					</rc-activity-time-box-widget>
				</div>
			</div>
			<div data-aid="button-add" class="add-activity-venue-btn">
				<a href="" (click)="addActivityItem(); $event.preventDefault()">
					<i class="fa fa-plus"></i> ADD ANOTHER FACILITY
				</a>
			</div>
		</div>
	</div>
</div>
