<div data-aid="EntityRoundImage" class="entity-large-photo">
	<div class="entity-image">
		<div class="image-wrapper image--round image--shadow image--border" [rcBackgroundImage]="image"></div>
	</div>
	<div class="entity-sport-icon">
		<i [ngClass]="getSportIcon()">
			<span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span>
		</i>
		<div class="sport-shadow"></div>
	</div>
</div>
