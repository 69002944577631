<nz-layout>
	<nz-row nzType="flex">
		<nz-col nzSpan="24">
			<nz-form-label nzFor="days-hours-picker">{{ labelString }}</nz-form-label>
			<p>You can choose add ons from your list of products to offer as part of the season registration</p>
			<nz-layout>
				<nz-card>
					<nz-layout>
						<nz-row
							*ngFor="let addOn of addOns; let i = index"
							nzType="flex"
							nzAlign="middle"
							nzJustify="space-between"
							nzGutter="8"
						>
							<!-- Not edit mode -->
							<nz-row
								nzType="flex"
								nzAlign="middle"
								nzJustify="space-between"
								nzGutter="8"
								class="full-width"
								*ngIf="!addOn.editing || disabled"
							>
								<nz-col nzSpan="8">
									<nz-row nzType="flex" nzAlign="middle" nzGutter="8" class="bold-text">
										{{ addOn.name | titlecase }}
									</nz-row>
								</nz-col>
								<nz-col nzSpan="8">
									<nz-row nzType="flex" nzAlign="middle">
										{{ addOn.timePeriod | titlecase }}
									</nz-row>
								</nz-col>
								<nz-col nzSpan="1" *ngIf="!addOnToEdit.editing && !disabled">
									<button data-aid="button-AddOnsManagement-edit" nz-button nzShape="circle" nzType="default" (click)="editAddOn(i)">
										<i nz-icon nzType="edit" nzTheme="outline"></i></button
								></nz-col>
							</nz-row>
							<!-- Edit mode -->
							<nz-row
								*ngIf="addOn.editing && !disabled"
								nzType="flex"
								nzAlign="middle"
								nzJustify="space-between"
								nzGutter="8"
								class="full-width"
							>
								<nz-col nzSpan="8">
									<label for="prgAddOnSelect" class="half-row-button"> Name </label>
									<nz-select
										nzAllowClear
										nzShowSearch
										id="prgAddOnSelect"
										class="half-row-button"
										style="width: 100%"
										[(ngModel)]="selectedProduct.id"
										(ngModelChange)="onNameChange($event)"
									>
										<nz-option *ngFor="let addon of addonSelection" [nzLabel]="addon.name" [nzValue]="addon.id">
										</nz-option>
									</nz-select>
								</nz-col>
								<nz-col nzSpan="8">
									<label for="prgAddOnTimePeriodInput" class="half-row-button"> Time period </label>
									<nz-select
										id="prgAddOnTimePeriodInput"
										class="half-row-button"
										style="width: 100%"
										[ngModel]="addOnToEdit.timePeriod"
										(ngModelChange)="onTimePeriodChange($event)"
									>
										<nz-option nzLabel="Full season" nzValue="full"></nz-option>
										<nz-option nzLabel="Per session" nzValue="session"></nz-option>
										<nz-option nzLabel="Per event" nzValue="event"> </nz-option>
									</nz-select>
								</nz-col>
								<button data-aid="button-AddOnsManagement-delete"
									nz-button
									nz-popconfirm
									nzShape="circle"
									nzType="default"
									nzTitle="Are you sure delete this add on?"
									(nzOnConfirm)="deleteAddOn(i)"
								>
									<i nz-icon nzType="delete" nzTheme="outline" style="color: red"></i>
								</button>
							</nz-row>
							<nz-divider></nz-divider>
						</nz-row>
						<nz-row nzType="flex" nzAlign="middle" nzJustify="end" class="button-row">
							<!-- Button to add addOn -->
							<button data-aid="button-AddOnsManagement-new" *ngIf="!addOnToEdit.editing && !disabled" nz-button nzType="primary" (click)="addAddOnBtn()">
								<i nz-icon nzType="plus" nzTheme="outline"></i>{{ 'New Add On' | uppercase }}
							</button>
							<!-- Buttons for edit/add addOn -->
							<nz-button-group *ngIf="addOnToEdit.editing">
								<button data-aid="button-AddOnsManagement-danger" nz-button nzType="danger" (click)="cancelEdit(addOnToEdit.index)">
									<i nz-icon nzType="close-circle" nzTheme="outline"></i>{{ 'Cancel' | uppercase }}</button
								><button data-aid="button-AddOnsManagement-done" nz-button nzType="primary" class="success-button" (click)="doneEditing()">
									<i nz-icon nzType="check-circle" nzTheme="outline"></i>{{ 'Done' | uppercase }}
								</button>
							</nz-button-group>
						</nz-row>
					</nz-layout>
				</nz-card>
			</nz-layout>
		</nz-col>
	</nz-row>
</nz-layout>
