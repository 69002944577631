<form [formGroup]="inviteForm" novalidate>
  <div data-aid="InviteCustomer" class="modal-content">
    <div class="modal-header modal-header--assertive">
      <button data-aid="button-InviteCustomer-invite" type="button" class="close pull-right" (click)="hide.emit()" aria-label="Close">
        <i class="fa fa-times"></i>
      </button>
      <div class="modal-header__content">
        <i class="icon-rc-teams-icon"></i>
        <h5>INVITE TO TEAM</h5>
      </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <label for="">FIRST NAME</label>
                <input data-aid="inviteCustomer-input-first" type="text" class="input" formControlName="firstName" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group">
                <label for="">LAST NAME</label>
                <input data-aid="inviteCustomer-input-last" type="text" class="input" formControlName="lastName" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group"
                [ngClass]="{ 'has-error': !inviteForm.get('email').valid && inviteForm.get('email').touched }">
                <label for="">EMAIL ADDRESS</label>
                <input data-aid="inviteCustomer-input-email" type="email" class="input" formControlName="email" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group" [ngClass]="{
                  'has-error': !inviteForm.get('phoneNumber').valid && inviteForm.get('phoneNumber').touched
                }">
                <label for="">PHONE NUMBER</label>
                <input data-aid="inviteCustomer-input-phone" type="tel" class="input" formControlName="phoneNumber" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group">
                <div data-aid="inviteCustomer-checkbox-captain" class="checkbox">
                  <input type="checkbox" class="styled-checkbox" formControlName="asCaptain" />
                  <label>Invite as captain (Only one allowed)</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button data-aid="button-InviteCustomer-cancel" class="button button-empty cancel-btn" (click)="hide.emit()">Cancel</button>
      <rc-loading-btn [text]="'INVITE TO TEAM'" [disabled]="!inviteForm.valid" (onClick)="submit.emit(inviteForm.value)"
        [loading]="loading" [classes]="['button', 'button-assertive', 'button-padded', 'submit-btn']">
      </rc-loading-btn>
    </div>
  </div>
</form>