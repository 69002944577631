import { attendanceApi } from '@app/react/lib/api/attendanceApi';
import { AttendanceCreateDto, AttendanceDto, PaginationMetaDto } from '@bondsports/types';
import { useState } from 'react';

export const useAttendance = () => {
	const [attendance, setAttendance] = useState<AttendanceDto[]>([]);
	const [attendanceMeta, setAttendanceMeta] = useState<PaginationMetaDto>();
	const [isFetchingAttendance, setIsFetchingAttendance] = useState<boolean>(false);
	const [isCreatingAttendance, setIsCreatingAttendance] = useState<boolean>(false);
	const [createAttendanceError, setCreateAttendanceError] = useState<string | null>(null);
	const [createAttendanceSuccess, setCreateAttendanceSuccess] = useState<boolean>(false);

	const fetchAttendance = async (organizationId: number, userId: number, itemsPerPage: number, page = 1) => {
		setIsFetchingAttendance(true);
		try {
			const res = await attendanceApi.fetchAttendance(organizationId, userId, itemsPerPage, page);
			setIsFetchingAttendance(false);
			setAttendance(res.data);
			setAttendanceMeta(res.meta);
		} catch (err) {
			console.error(err);
		}
		setIsFetchingAttendance(false);
	};

	const createAttendance = async (organizationId: number, userId: number, data: AttendanceCreateDto) => {
		setCreateAttendanceSuccess(false);
		setCreateAttendanceError(null);
		setIsCreatingAttendance(true);

		try {
			const res = await attendanceApi.createAttendance(organizationId, userId, data);

			if (!res.succeeded) {
				setCreateAttendanceError(res.message ?? (res as any).err);
				return;
			}
			setCreateAttendanceSuccess(true);
		} catch (err) {
			setCreateAttendanceError(err.message);
		}
		setIsCreatingAttendance(false);
	};

	return {
		attendance,
		attendanceMeta,
		isFetchingAttendance,
		createAttendance,
		createAttendanceError,
		isCreatingAttendance,
		createAttendanceSuccess,
		fetchAttendance,
	};
};
