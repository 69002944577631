<div data-aid="VenueSelectModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog rc-modal">
		<div class="modal-content">
			<div class="modal-header modal-header--assertive">
				<button data-aid="button-VenueSelectModal-close" type="button" class="close pull-right" (click)="modal.hide()" aria-label="Close">
					<i class="fa fa-times"></i>
				</button>
				<div class="modal-header__content">
					<i class="icon-rc-nav-venues"></i>
					<h5>CHANGE LOCATION</h5>
				</div>
			</div>
			<div class="modal-body">
				<form action="" class="form" [formGroup]="matchForm" novalidate>
					<div class="row">
						<div class="col-md-12">
							<div
								class="input-group"
								[ngClass]="{ 'has-error': !matchForm.get('venueName').valid && matchForm.get('venueName').touched }"
							>
								<label for="">LOCATION</label>
								<rc-location-search-input
									formControlName="venueName"
									[required]="true"
									(onSelected)="onAddressSelect($event)"
									[placeholder]="'Enter city, neighborhood, borough or full address.'"
								></rc-location-search-input>
							</div>
							<div class="text-center">- OR -</div>
							<div class="input-group">
								<label for="">VENUE</label>
								<rc-venue-search-input
									(onSelect)="onVenueSelect($event)"
									formControlName="venue"
								></rc-venue-search-input>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="modal-footer">
				<button data-aid="button-VenueSelectModal-cancel" class="button button-empty cancel-btn" (click)="modal.hide()">Cancel</button>
				<rc-loading-btn
					(onClick)="submit(matchForm.value)"
					[disabled]="!matchForm.valid"
					[text]="'CHANGE LOCATION'"
					[classes]="['button', 'button-success']"
				>
				</rc-loading-btn>
			</div>
		</div>
	</div>
</div>
