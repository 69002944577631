<nz-layout data-aid="ScheduleConstraints">
	<nz-row nzType="flex">
		<nz-col nzSpan="24">
			<nz-form-label nzFor="days-hours-picker">{{ labelString }}</nz-form-label>
			<p>
				Select from list of public holidays in your date range and/or enter dates that the program will not take place
			</p>
			<nz-layout>
				<nz-card [className]="dangerousUpdateCss ? 'dangerous-edit' : ''">
					<nz-layout>
						<nz-row
							*ngFor="let constraint of constraints; let i = index"
							nzType="flex"
							nzAlign="middle"
							nzJustify="space-between"
							nzGutter="8"
						>
							<!-- Not edit mode -->
							<nz-row nzType="flex" nzAlign="middle" nzGutter="8" class="full-width" *ngIf="!constraint.editing">
								<nz-col nzSpan="13">
									<nz-row nzType="flex" nzAlign="middle" nzGutter="8" class="bold-text">
										<nz-switch
											nzSize="small"
											[(ngModel)]="constraint.active"
											(ngModelChange)="onActiveChange()"
										></nz-switch>
										{{ constraint.name | titlecase }}
									</nz-row>
								</nz-col>
								<nz-col nzSpan="10">
									<nz-row nzType="flex" nzAlign="middle">
										{{
											constraint.startDate === constraint.finishDate
												? datePipe.transform(constraint.startDate, 'MMM d, y')
												: datePipe.transform(constraint.startDate, 'MMM d, y') +
												  ' - ' +
												  datePipe.transform(constraint.finishDate, 'MMM d, y')
										}}
									</nz-row>
								</nz-col>
								<nz-col nzSpan="1">
									<button data-aid="button-ScheduleConstraints-edit"
										nz-button
										nzShape="circle"
										nzType="default"
										*ngIf="!this.constraintToEdit.editing"
										(click)="editConstraint(i)"
									>
										<i nz-icon nzType="edit" nzTheme="outline"></i></button
								></nz-col>
							</nz-row>
							<!-- Edit mode -->
							<div class="full-width" *ngIf="constraint.editing">
								<nz-col nzSpan="12">
									<label class="half-row-button">
										<input
										    data-aid="schedule-input-edit"
											class="half-row-button"
											nz-input
											*ngIf="constraintToEdit.editing"
											placeholder="Constraint name"
											[(ngModel)]="nameEditField"
											(ngModelChange)="onNameChange($event)"
										/>
									</label>
								</nz-col>
								<nz-col data-aid="schedule-range" nzSpan="12">
									<rc-date-range-dropdown
										labelString=""
										size="small"
										[startDate]="datePipe.transform(constraintToEdit.startDate, 'MM/dd/yyyy')"
										[endDate]="datePipe.transform(constraintToEdit.finishDate, 'MM/dd/yyyy')"
										(onSelectChange)="dateChanged($event)"
									></rc-date-range-dropdown>
								</nz-col>
							</div>
							<nz-divider></nz-divider>
						</nz-row>
						<nz-row nzType="flex" nzAlign="middle" nzJustify="end" class="button-row">
							<!-- Button to add constraint -->
							<button data-aid="button-ScheduleConstraints-add" *ngIf="!this.constraintToEdit.editing" nz-button nzType="primary" (click)="addConstraintBtn()">
								<i nz-icon nzType="plus" nzTheme="outline"></i>{{ 'Add a custom constraint' | uppercase }}
							</button>
							<!-- Buttons for edit/add constraint -->
							<nz-button-group *ngIf="this.constraintToEdit.editing">
								<button data-aid="button-ScheduleConstraints-cancel" nz-button nzType="danger" (click)="cancelEdit(constraintToEdit.index)">
									<i nz-icon nzType="close-circle" nzTheme="outline"></i>{{ 'Cancel' | uppercase }}</button
								><button data-aid="button-ScheduleConstraints-done" nz-button nzType="primary" class="success-button" (click)="doneEditing()">
									<i nz-icon nzType="check-circle" nzTheme="outline"></i>{{ 'Done' | uppercase }}
								</button>
							</nz-button-group>
						</nz-row>
					</nz-layout>
				</nz-card>
			</nz-layout>
		</nz-col>
	</nz-row>
</nz-layout>
