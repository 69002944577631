<nz-layout>
	<nz-row nzType="flex">
		<nz-col nzSpan="24">
			<nz-form-label nzFor="days-hours-picker">{{ labelString }}</nz-form-label>
			<nz-layout>
				<nz-card [className]="dangerousUpdateCss ? 'dangerous-edit' : ''">
					<nz-layout>
						<nz-row
							*ngFor="let week of weekData; let idx = index"
							nzType="flex"
							nzAlign="middle"
							nzJustify="space-between"
							nzGutter="16"
							style="margin-bottom: 32px"
						>
							<!--   Day of week row  -->
							<nz-row nzType="flex" nzAlign="middle" nzGutter="4" style="margin-bottom: 16px">
								<nz-col *ngFor="let day of week; let idxDay = index">
									<label nz-checkbox [(ngModel)]="day.active" (ngModelChange)="onChangeHandle($event)">{{
										daysOfWeekNames[day.index - 2]
									}}</label>
								</nz-col>
							</nz-row>
							<!--   Single time picker row  -->
							<nz-row nzType="flex" nzAlign="middle" nzJustify="space-between" nzGutter="4">
								<nz-col nzLg="12" nzMd="24">
									<nz-form-label nzFor="start-time-picker">Start</nz-form-label>
									<nz-row
										id="start-time-picker"
										nzType="flex"
										nzAlign="middle"
										nzJustify="start"
										nzGutter="8"
										style="width: 100%"
									>
										<nz-col nzXl="6" nzLg="10" nzMd="6" nzSm="8" class="time-picker-internal-col">
											<nz-input-number
												[nzMax]="12"
												[nzMin]="1"
												[nzPlaceHolder]="'Hour'"
												[(ngModel)]="week[0].startTime.hour"
												(ngModelChange)="onChangeHandle($event)"
												style="width: 100%"
												[nzSize]="'small'"
											>
											</nz-input-number>
										</nz-col>
										<span> : </span>
										<nz-col nzXl="6" nzLg="10" nzMd="6" nzSm="8" class="time-picker-internal-col">
											<nz-input-number
												[nzMax]="59"
												[nzMin]="0"
												[nzPlaceHolder]="'Minutcones'"
												[nzFormatter]="formatMinutes"
												[(ngModel)]="week[0].startTime.minute"
												(ngModelChange)="onChangeHandle($event)"
												style="width: 100%"
												[nzSize]="'small'"
											>
											</nz-input-number>
										</nz-col>
										<nz-col class="time-picker-internal-col">
											<nz-radio-group
												[(ngModel)]="week[0].startTime.pm"
												[nzButtonStyle]="'solid'"
												(ngModelChange)="onChangeHandle($event)"
												style="width: 100%"
												[nzSize]="'small'"
											>
												<label nz-radio-button [nzValue]="false">AM</label>
												<label nz-radio-button [nzValue]="true">PM</label>
											</nz-radio-group>
										</nz-col>
									</nz-row>
								</nz-col>
								<nz-col nzLg="12" nzMd="24">
									<nz-form-label nzFor="finish-time-picker">End</nz-form-label>
									<nz-row
										id="finish-time-picker"
										nzType="flex"
										nzAlign="middle"
										nzJustify="start"
										nzGutter="8"
										style="width: 100%"
									>
										<nz-col nzXl="6" nzLg="10" nzMd="6" nzSm="8" class="time-picker-internal-col">
											<nz-input-number
												[nzMax]="12"
												[nzMin]="1"
												[nzPlaceHolder]="'Hour'"
												[(ngModel)]="week[0].finishTime.hour"
												(ngModelChange)="onChangeHandle($event)"
												style="width: 100%"
												[nzSize]="'small'"
											>
											</nz-input-number>
										</nz-col>
										<span> : </span>
										<nz-col nzXl="6" nzLg="10" nzMd="6" nzSm="8" class="time-picker-internal-col">
											<nz-input-number
												[nzMax]="59"
												[nzMin]="0"
												[nzPlaceHolder]="'Minutes'"
												[nzFormatter]="formatMinutes"
												[(ngModel)]="week[0].finishTime.minute"
												(ngModelChange)="onChangeHandle($event)"
												style="width: 100%"
												[nzSize]="'small'"
											>
											</nz-input-number>
										</nz-col>
										<nz-col class="time-picker-internal-col">
											<nz-radio-group
												[(ngModel)]="week[0].finishTime.pm"
												(ngModelChange)="onChangeHandle($event)"
												[nzButtonStyle]="'solid'"
												style="width: 100%"
												[nzSize]="'small'"
											>
												<label nz-radio-button [nzValue]="false">AM</label>
												<label nz-radio-button [nzValue]="true">PM</label>
											</nz-radio-group>
										</nz-col>
									</nz-row>
								</nz-col>
							</nz-row>
						</nz-row>
						<nz-layout>
							<!--  Day breakdown -->
							<!--<nz-row
                *ngFor="let day of weekData; let idx = index"
                nzType="flex"
                nzAlign="middle"
                nzJustify="space-between"
                nzGutter="8"
              >
                <nz-col nzSpan="8">
                  <nz-row nzType="flex" nzAlign="middle" nzGutter="8" class="bold-text">
                    <nz-switch
                      [(ngModel)]="day.active"
                      nzSize="small"
                      (ngModelChange)="onChangeHandle()"
                      [nzDisabled]="disabled"
                    ></nz-switch>
                    {{ daysOfWeekNames[day.index - 2] }}
                  </nz-row>
                </nz-col>
                <nz-col style="flex-grow: 1;">
                  <nz-row nzType="flex" nzAlign="middle" nzJustify="space-between" nzGutter="8">
                    <nz-col nzSpan="10">
                      <nz-col>
                        <nz-select
                          nzShowSearch
                          nzSize="small"
                          nzAllowClear
                          nzPlaceHolder="Start"
                          [nzDisabled]="!day.active || disabled"
                          [(ngModel)]="day.startTimeValue"
                          (ngModelChange)="onChangeHandle()"
                        >
                          <nz-option
                            *ngFor="let time of timeList"
                            [nzLabel]="time.display"
                            [nzValue]="time.timeValue"
                          ></nz-option>
                        </nz-select> </nz-col
                    ></nz-col>
                    <nz-col nzSpan="1"><i nz-icon nzType="arrow-right" nzTheme="outline"></i> </nz-col>
                    <nz-col nzSpan="10">
                      <nz-col>
                        <nz-select
                          nzShowSearch
                          nzSize="small"
                          nzAllowClear
                          nzPlaceHolder="Finish"
                          [nzDisabled]="!day.active || disabled"
                          [(ngModel)]="day.finishTimeValue"
                          (ngModelChange)="onChangeHandle()"
                        >
                          <nz-option
                            *ngFor="let time of timeList"
                            [nzLabel]="time.display"
                            [nzValue]="time.timeValue"
                          ></nz-option>
                        </nz-select>
                      </nz-col>
                    </nz-col>
                  </nz-row>
                </nz-col>
                <nz-divider *ngIf="idx < 6"></nz-divider>
              </nz-row>-->
						</nz-layout>
						<nz-row nzType="flex" nzJustify="end" nzAlign="middle">
							<button data-aid="button-WeekDaysHoursSelect-add" nz-button nzType="primary" (click)="addNewWeekObj()">
								<i nz-icon nzType="plus" nzTheme="outline"></i>{{ 'Add another time slot' | uppercase }}
							</button>
						</nz-row>
					</nz-layout>
				</nz-card>
			</nz-layout>
		</nz-col>
	</nz-row>
</nz-layout>
