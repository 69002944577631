<div data-aid="SeasonCreatorTiming" [formGroup]="form">
	<div class="row">
		<div class="col-md-12">
			<div class="form__section">
				<div class="form__section__header">SEASON GAME DAYS AND TIMES</div>
				<div class="form__section__helper">Custom your season information</div>
			</div>

			<div [formArrayName]="'activityTimes'">
				<div class="concurrent-wrapper">
					<div class="concurrent-wrapper--header">
						Setting the days and times for the season will currently not block your calendar. To block the calendar for
						the season you will need to create an internal reservation via the booking flow. When you create the season
						schedule and allocate the specific resource for each game it will create the games schedule on the calendar.
					</div>
				</div>

				<div *ngFor="let activity of activitiesArray.controls; let i = index">
					<rc-activity-time-box-widget
						[orgVenues]="orgVenues"
						[arrayName]="'activityTimes'"
						[form]="form"
						[arrayGroup]="activity"
						[groupName]="i"
					>
					</rc-activity-time-box-widget>
				</div>
			</div>
			<div class="add-activity-venue-btn">
				<a href="" (click)="addActivitiyItem(); $event.preventDefault()">
					<i class="fa fa-plus"></i> ADD ANOTHER FACILITY
				</a>
			</div>
		</div>
	</div>
</div>
