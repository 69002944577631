<form novalidate>
  <div data-aid="SelectType" class="modal-content">
    <div class="modal-header modal-header--assertive">
      <button data-aid="button-SelectType-add" type="button" class="close pull-right" (click)="hide.emit()" aria-label="Close">
        <i class="fa fa-times"></i>
      </button>
      <div class="modal-header__content">
        <i class="icon-rc-teams-icon"></i>
        <h5>ADD TEAM MEMBER</h5>
      </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="input-group">
                <div class="col-md-12">
                  <div data-aid="invitePlayer-radio-new" class="col-md-1">
                    <input id="radio-customer-invite" type="radio" value="" (click)="selectType('customerInvite')"
                      [checked]="selected === 'customerInvite'" />
                  </div>
                  <div class="col-md-11">
                    <label for="radio-customer-invite">Invite new customer</label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div data-aid="invitePlayer-radio-existing" class="col-md-1">
                    <input id="radio-customer-search" type="radio" (click)="selectType('customerSearch')"
                      [checked]="selected === 'customerSearch'" />
                  </div>
                  <div class="col-md-11">
                    <label for="radio-customer-search">Add an existing customer to the team</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button data-aid="button-SelectType-cancel" class="button button-empty cancel-btn" (click)="hide.emit()">Cancel</button>
      <rc-loading-btn [text]="'NEXT'" [classes]="['button', 'button-assertive', 'button-padded', 'submit-btn']"
        (onClick)="submit.emit(selected)">
      </rc-loading-btn>
    </div>
  </div>
</form>