<div data-aid="LoginPage" class="login-wrapper-page">
	<div class="wrapper-side left">
		<div class="logo-wrapper">
			<img src="/assets/img/logo_dark.png" alt="" />
		</div>

		<div class="login-form form-wrapper login-wrapper" *ngIf="currentState === 'login'">
			<h1>LOGIN</h1>
			<form action="" (ngSubmit)="onLogin()">
				<div class="input inline-input input-icon" [ngClass]="{ 'has-error': isError }">
					<i class="icon-rc-person-icon"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input data-aid="loginPage-input-email" type="email" placeholder="EMAIL" [(ngModel)]="user.email" name="email" />
				</div>
				<div class="input inline-input input-icon" [ngClass]="{ 'has-error': isError }">
					<i class="icon-rc-private"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input data-aid="loginPage-input-password" type="password" class="password" placeholder="PASSWORD" [(ngModel)]="user.password" name="password" />
					<div class="error-message">
						{{ errorMessage }}
					</div>
					<a class="forgot-password-btn" (click)="goToState('forgot-password'); $event.preventDefault()" href=""
						>FORGOT PASSWORD?</a
					>
				</div>
				<div class="buttons-row">
					<div class="row">
						<div class="col-md-6">
							<!--   <div class="checkbox logged-in-checkbox">
                <input type="checkbox" class="styled-checkbox" name="savePassword" />
                <label>keep me logged in</label>
              </div>-->
						</div>
						<div class="col-md-6">
							<button data-aid="button-LoginPage-submit" class="button button-loader button-shadow button-assertive button-full" type="submit">
								{{ loading ? null : 'LOGIN' }}
								<span>
									<loaders-css *ngIf="loading" [loader]="'ball-pulse'" [loaderClass]="'rc-loader'"></loaders-css>
								</span>
							</button>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="CreateOrganizationSuggest" *ngIf="userConnectOrganizationWidget">
								<p>
									It seems like you are trying to login using an existing Bond Sports account. If this is correct,
									you'll need to create your organization account first. Don't worry, it's quick and painless!
									<br />
									JUST CLICK HERE AND WE’LL TAKE YOU THERE
								</p>
								<a data-aid="loginPage-button-createOrg" href="" class="button button-assertive" (click)="goToState('signup'); $event.preventDefault()">
									CREATE ORGANIZATION
								</a>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="login-form form-wrapper forgot-wrapper" *ngIf="currentState === 'forgot-password'">
			<h1>FORGOT PASSWORD</h1>
			<p>NO WORRIES! ENTER YOUR EMAIL ADDRESS AND WE'LL SEND YOU PASSWORD RESET INSTRUCTIONS</p>
			<form action="" (ngSubmit)="onPasswordResetRequest()">
				<div class="input inline-input input-icon" [ngClass]="{ 'has-error': isError }">
					<i class="fa fa-envelope"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input data-aid="forgot-input-email" type="email" placeholder="EMAIL ADDRESS" [(ngModel)]="user.email" name="email" />
				</div>
				<div class="buttons-row">
					<div class="row">
						<div class="col-md-6"></div>
						<div class="col-md-6">
							<button data-aid="button-LoginPage-send" class="button button-loader button-shadow button-assertive button-full" type="submit">
								{{ loading ? null : 'SEND' }}
								<span>
									<loaders-css *ngIf="loading" [loader]="'ball-pulse'" [loaderClass]="'rc-loader'"></loaders-css>
								</span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="login-form form-wrapper forgot-wrapper" *ngIf="currentState === 'email-sent'">
			<h1>
				CHECK <br />
				YOUR INBOX!
			</h1>
			<p>RESET PASSWORD INSTRUCTIONS EMAIL WAS SENT SUCCESSFULLY TO THE ADDRESS YOU'VE ENTERED</p>
		</div>
		<div class="login-form form-wrapper forgot-wrapper" *ngIf="currentState === 'password-change'">
			<h1>RESET PASSWORD</h1>
			<p>YOUR NEW PASSWORD SHOULD BE AT LEAST 6 CHARACTERS AND NUMBERS</p>
			<form action="" (ngSubmit)="onPasswordChange()">
				<div class="input inline-input input-icon" [ngClass]="{ 'has-error': isError }">
					<i class="icon-rc-private"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input
					    data-aid="new-input-password"
						type="password"
						class="password"
						placeholder="NEW PASSWORD"
						[(ngModel)]="user.password"
						name="password"
					/>
				</div>
				<div class="input inline-input input-icon" [ngClass]="{ 'has-error': isError }">
					<i class="icon-rc-private"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input
					    data-aid="new-input-passwordConfirm"
						type="password"
						class="password"
						placeholder="RE-TYPE NEW PASSWORD"
						[(ngModel)]="user.passwordRepeat"
						name="passwordRepeat"
					/>
					<div class="error-message">
						{{ errorMessage }}
					</div>
				</div>
				<div class="buttons-row">
					<div class="row">
						<div class="col-md-6"></div>
						<div class="col-md-6">
							<button data-aid="button-LoginPage-reset" class="button button-loader button-shadow button-assertive button-full" type="submit">
								{{ loading ? null : 'RESET PASSWORD' }}
								<span>
									<loaders-css *ngIf="loading" [loader]="'ball-pulse'" [loaderClass]="'rc-loader'"></loaders-css>
								</span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="login-form form-wrapper login-wrapper" *ngIf="currentState === 'signup'">
			<h1 class="bold">SIGNUP</h1>
			<form
				action=""
				[formGroup]="organizationForm"
				novalidate
				(submit)="submitOrganizationRegister(organizationForm.value)"
			>
				<div class="input inline-input input-icon">
					<i class="icon-icn-sport-multi"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input data-aid="org-input-name" type="email" placeholder="ORGANIZATION NAME" formControlName="name" />
				</div>
				<div class="input inline-input input-icon" [ngClass]="{ 'has-error': isError }">
					<i class="icon-rc-person-icon"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input data-aid="org-input-email" type="email" placeholder="EMAIL ADDRESS" formControlName="email" />
					<div class="error-message">
						{{ errorMessage }}
					</div>
				</div>
				<div class="input inline-input input-icon">
					<i class="icon-rc-private"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input data-aid="org-input-password" type="password" class="password" placeholder="PASSWORD" formControlName="password" />
				</div>
				<div class="input inline-input input-icon">
					<i class="icon-rc-private"></i>
					<i class="fa fa-exclamation-triangle error-icon"></i>
					<input data-aid="org-input-passwordConfirm" type="password" class="password" placeholder="CONFIRM PASSWORD" formControlName="passwordRepeat" />
				</div>
				<div class="buttons-row">
					<div class="row">
						<div class="col-md-6 signup-login-wrapper">
							<div data-aid="org-checkbox-terms" class="checkbox logged-in-checkbox" style="display: inline-block">
								<input type="checkbox" class="styled-checkbox" formControlName="termsAgreed" />
								<label></label>
							</div>
							<span>
								By signing up you agree to the
								<a (click)="openTerms('https://bondsports.co/terms')" href="https://bondsports.co/terms" target="_blank"
									>Terms & Conditions</a
								>
							</span>
						</div>
						<div class="col-md-6">
							<button data-aid="button-LoginPage-continue"
								class="button button-loader button-shadow button-assertive button-full"
								[disabled]="!organizationForm.valid"
								type="submit"
							>
								CONTINUE
								<span>
									<loaders-css *ngIf="loading" [loader]="'ball-pulse'" [loaderClass]="'rc-loader'"></loaders-css>
								</span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div class="login-bottom-wrapper">
			<div class="bottom-inner">
				<!-- <button data-aid="button-LoginPage" class="button button-full button-empty" (click)="goToState('forgot-password')" *ngIf="currentState === 'login'">
          FORGOT PASSWORD
        </button>-->
				<button data-aid="button-LoginPage-signup" class="button button-full button-empty" (click)="goToState('signup')" *ngIf="currentState === 'login'">
					DON'T HAVE AN ACCOUNT? <span style="color: #ffa31d">SIGNUP HERE</span>
				</button>
				<button data-aid="button-LoginPage-login" class="button button-full button-empty" (click)="goToState('login')" *ngIf="currentState !== 'login'">
					ALREADY HAVE AN ACCOUNT? <span style="color: #ffa31d">GO TO LOGIN</span>
				</button>
			</div>
		</div>
	</div>
	<div class="wrapper-side right">
		<div class="inner-background" [ngStyle]="{ 'background-image': 'url(' + getBGImage() + ')' }"></div>
		<div class="gradient-bg"></div>
		<div class="content-wrapper">
			<div class="content-bottom">
				<div class="text-wrapper">
					<h1 *ngIf="this.currentState !== 'signup'">
						HEY, <br />
						GOOD TO SEE YOU TODAY!
					</h1>
					<h1 *ngIf="this.currentState === 'signup'">
						HEY! <br />
						IT’S SO GREAT YOU’RE JOINING US
					</h1>
				</div>
				<div class="widget-wrapper"></div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="showContactSupportModal" class="support-modal">
	<div class="support-modal-body">
		<header class="support-modal-header">
			<img _ngcontent-hxd-c376="" src="/assets/img/logo_dark.png" alt="" />
		</header>
		<main class="support-modal-content">
			<div class="support-modal-message">
				Thanks for your interest in Bond Sports. If you want to learn more about partnering with Bond or get a demo of
				our system, then reach out to <a>support@bondsports.co</a>, and we’ll be in touch right away!
			</div>
		</main>
		<footer class="support-modal-footer">
			<button data-aid="button-LoginPage-toggle"
				(click)="toggleContactSupportModal()"
				class="button button-loader button-shadow button-assertive support-modal-button"
			>
				Got it!
			</button>
		</footer>
	</div>
</div>
