import { environment } from '../../../../environments/environment';
import { network } from '../network';
import { MediaItem } from 'app/react/types/media';
import { imageApi } from './imageApi';
import { Resource, ResourceDto } from 'app/react/types/resource';
import { SectionEnum } from 'app/react/types/settings';
import { ResourceSection } from 'app/react/forms/settings/resource/types';
import {
	DEFAULT_PAGE,
	FindResourcesOptionsDto,
	GenericResponseDto,
	InstructorResourceDto,
	PaginationResultDto,
	ResourceTypeEnum,
	UpdateInstructorsDto,
	User,
} from '@bondsports/types';
import { IBasicError } from '@app/react/types/errors';
import { buildQueryString } from '../urlUtils';
import { QueryDataParams, SearchParams } from '@bondsports/utils';
const defaultsPageSize = 12;

const resource_url = `${environment.CS_URLS.API_ROOT_V4}/resources/organization`;
const pricing_url = `${environment.CS_URLS.API_ROOT_V4}/product-pricing`;

function getResourceEndPoint(organizationId: number, resourceId: number, section: SectionEnum): string {
	switch (section) {
		case SectionEnum.AMENTIES:
		case SectionEnum.PROPERTIES:
			return `${resource_url}/${organizationId}/space/${resourceId}/${section}`;
		default:
			return `${resource_url}/${organizationId}/resource/${resourceId}/${section}`;
	}
}

const createResource = async (
	organizationId: number,
	data: Resource,
	resourceType: ResourceTypeEnum
): Promise<GenericResponseDto<ResourceDto | InstructorResourceDto>> => {
	const response = await network.post(`${resource_url}/${organizationId}/resource/${resourceType}`, data);
	return response;
};

const uploadResourceMedia = (file: MediaItem, facilityId: number, resourceId: number) => {
	return imageApi.uploadFileItemImage(file).then(async response => {
		const fileObject = {
			url: response.secure_url,
			provider: 'cloudinary',
			fileType: response.format,
			mediaKey: response.public_id,
			fileName: response.original_filename,
		};
		return await network.post(
			`${environment.CS_URLS.API_ROOT}/venues/${facilityId}/spaces/${resourceId}/uploadMedia?handleType=main`,
			{
				file: fileObject,
			}
		);
	});
};

const getResourceById = async (
	organizationId: number,
	resourceId: number,
	resourceType: ResourceTypeEnum,
	includeFacilities = true,
	includeProducts = false,
	includeAdditionalData = true
): Promise<GenericResponseDto<ResourceDto>> => {
	const queryParams = {
		resourceType,
		includeProducts,
		includeAdditionalData,
		includeFacilities: Boolean(includeFacilities),
	};

	const queryString = buildQueryString(queryParams);
	const response = await network.get(`${resource_url}/${organizationId}/${resourceId}${queryString}`);
	return response;
};

const updateResourceData = async (
	organizationId: number,
	resourceId: number,
	resourceType: ResourceTypeEnum,
	section: SectionEnum,
	data: ResourceSection
): Promise<GenericResponseDto<ResourceDto>> => {
	const url: string = getResourceEndPoint(organizationId, resourceId, section);
	const response = await network.put(`${url}?resourceType=${resourceType}`, data);

	return response;
};

const getProductByResource = async (spaceId: number) => {
	const response = await network.get(`${pricing_url}/product/resource/space/${spaceId}`);
	return response;
};

const updateResource = async (
	organizationId: number,
	resourceType: ResourceTypeEnum,
	data: UpdateInstructorsDto
): Promise<GenericResponseDto<InstructorResourceDto> | IBasicError> => {
	const response = await network.put(
		`${environment.CS_URLS.API_ROOT_V4}/resources/organization/${organizationId}/${resourceType}`,
		data
	);

	return response;
};

async function getInstructors({
	organizationId,
	queryData = { itemsPerPage: 8, page: 1, filters: { search: '' } },
}: {
	organizationId: number;
	queryData: QueryDataParams<SearchParams>;
}): Promise<PaginationResultDto<User> | IBasicError> {
	const queryString = buildQueryString({ itemsPerPage: queryData.itemsPerPage, page: queryData.page });
	const searchParam = queryData?.filters?.search ? `&search=${encodeURIComponent(queryData?.filters?.search)}` : '';
	return await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/users/instructors${queryString}${searchParam}`
	);
}

const getResourcesByOrganizationID = async (
	organizationId: number,
	options: FindResourcesOptionsDto = {}
): Promise<PaginationResultDto<ResourceDto> | IBasicError> => {
	const queryString = buildQueryString({
		...options,
		resourceTypes: options.resourceTypes ?? [ResourceTypeEnum.SPACE],
		itemsPerPage: defaultsPageSize,
		page: options.page ?? DEFAULT_PAGE,
		nameSearch: undefined,
	});
	const searchParam = options.nameSearch ? `&nameSearch=${encodeURIComponent(options.nameSearch)}` : '';

	return await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/resources/organization/${organizationId}${queryString}${searchParam}`
	);
};

export const resourceApi = {
	createResource,
	uploadResourceMedia,
	getResourceById,
	updateResourceData,
	getProductByResource,
	updateResource,
	getInstructors,
	getResourcesByOrganizationID,
};
