<div data-aid="MovePlayerToSeasonModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog rc-modal" *ngIf="player">
		<form [formGroup]="playerEditForm" novalidate>
			<div class="modal-content">
				<div class="modal-header modal-header--assertive">
					<button data-aid="button-MovePlayerToSeasonModal-move" type="button" class="close pull-right" (click)="reset(true)" aria-label="Close">
						<i class="fa fa-times"></i>
					</button>
					<div class="modal-header__content">
						<i class="icon-rc-person-icon"></i>
						<h5>MOVE PARTICIPANT TO SEASON</h5>
					</div>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12">
							<div class="row">
								<div class="col-md-6">
									<div class="input-group">
										<label for="">Move Partticipant</label>
										<span>{{ player?.name?.toUpperCase() }}</span>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-group">
										<label for="">From Season</label>
										<span>{{ currentSeason?.name?.toUpperCase() }}</span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div
										class="input-group"
										[ngClass]="{
											'has-error': !playerEditForm.get('toLeagueId').valid && playerEditForm.get('toLeagueId').touched
										}"
									>
										<label for="">To League</label>
										{{ leaguesSelections?.length ? '' : 'No leagues found' }}
										<select
											*ngIf="leaguesSelections?.length"
											(change)="leagueSelected($event.target.value)"
											name="toLeagueId"
											class="select"
											formControlName="toLeagueId"
											[required]="true"
										>
											<option value="{{ i.id }}" *ngFor="let i of leaguesSelections">
												{{ i.name?.toUpperCase() }}
											</option>
										</select>
									</div>
								</div>

								<div class="col-md-6">
									<div
										class="input-group"
										[ngClass]="{
											'has-error': !playerEditForm.get('toSeasonId').valid && playerEditForm.get('toSeasonId').touched
										}"
									>
										<label for="">To Season</label>
										{{ seasonsSelections?.length ? '' : 'No seasons found' }}
										<select
											*ngIf="seasonsSelections?.length"
											(change)="seasonSelected($event.target.value)"
											name="toSeasonId"
											class="select"
											formControlName="toSeasonId"
											[required]="true"
										>
											<option value="{{ i.id }}" *ngFor="let i of seasonsSelections">
												{{ i.name?.toUpperCase() }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer">
					<button data-aid="button-MovePlayerToSeasonModal-cancel" type="button" class="button button-empty cancel-btn" (click)="reset(true)">Cancel</button>
					<rc-loading-btn
						[text]="'MOVE PARTICIPANT'"
						[disabled]="!playerEditForm.valid"
						(onClick)="submit(playerEditForm.value)"
						[loading]="loading"
						[classes]="['button', 'button-assertive', 'button-padded', 'submit-btn']"
					>
					</rc-loading-btn>
				</div>
			</div>
		</form>
	</div>
</div>
