/** @jsx jsx */
import { jsx } from '@emotion/react';
import { TCustomer } from '@app/react/types/customers';
import { marginCss, marginTopCss, scrollYCss, widthCss } from '@app/react/styles/utils';
import { Memberships } from '../../../Identification/sections/Memberships';
import { Footer } from '../../../Identification/sections/utils/Footer';
import { Payments } from '../../../Identification/sections/Payments';
import { PersonalProfile } from '../../../Identification/sections/PersonalProfile';
import { useOverview } from './usOverview';
import {
	generateMembershipAlertsText,
	generatePersonalProfileState,
	getSectionStatus,
	MAX_ALERTS_TO_DISPLAY,
	viewAllInvoicesPath,
	viewAllMembershipsPath,
} from './utils';
import { TranslationEn } from '@assets/i18n/en';
import { useCustomer } from '../../hooks/useCustomer';
import { geShowingAlerts } from '../../../Identification/lib';
import { ETypographyColor, ToastContainer } from '@bondsports/utils';
import { colors } from '../../../../../styles/theme';
import { useEffect, useState } from 'react';

interface Props {
	customerState: TCustomer;
}

const DELAY_TOAST_ANIMATION_BY_SECONDS = 0.5;
const DELAY_EACH_TOAST_ANIMATION_BY_SECONDS = 0.25;

export const Overview = ({ customerState }: Props) => {
	const {
		paymentAlerts,
		alertStatus,
		memershipsDataState,
		isLoadingAlerts,
		isFetchingMembershipsFaild,
		isFetchingPaymentsFaild,
		isExistingInvoices,
		totalPaymentsAlerts,
		isLoadingMemberships,
		alertNotes,
	} = useOverview(customerState);

	const [toasts, setToasts] = useState<any[]>();

	const { connectedAccounts, isLoadingLinkedAccounts, profilePictureUrl } = useCustomer();

	const { membershipAlerts, isExistingMemberships, numberOfMembershipAlerts } = memershipsDataState;

	const personalProfileState = generatePersonalProfileState(customerState, connectedAccounts, isLoadingLinkedAccounts);
	const labels = TranslationEn.customers.overview;
	const membershipsToDispaly = membershipAlerts.slice(0, MAX_ALERTS_TO_DISPLAY);

	const membershipsStatus = getSectionStatus(
		isFetchingMembershipsFaild,
		isExistingMemberships,
		numberOfMembershipAlerts
	);

	const numberOfPaymentsAlerts = paymentAlerts.length;

	const paymentsStatus = getSectionStatus(isFetchingPaymentsFaild, isExistingInvoices, numberOfPaymentsAlerts);

	useEffect(() => {
		if (alertNotes?.length) {
			setToasts(
				alertNotes.map(alerts => ({
					toastColor: colors.statusBlue,
					textColor: ETypographyColor.white,
					content: alerts.title,
				}))
			);
		}
	}, [alertNotes]);

	return (
		<div css={scrollYCss}>
			<div css={marginCss(20)}>
				<PersonalProfile
					status={alertStatus}
					state={personalProfileState}
					profilePictureUrl={profilePictureUrl(customerState)}
					isLoading={isLoadingAlerts}
				/>
				<div css={[widthCss(50), marginTopCss(16)]}>
						<Memberships
							status={membershipsStatus}
							memberships={membershipsToDispaly}
							footer={
								<MembershipsFooter
									labels={labels.memberships.footer}
									isExistingData={isExistingMemberships}
									numberOfMembershipAlerts={numberOfMembershipAlerts}
								/>
							}
							isLoading={isLoadingMemberships}
						/>
					<Payments
						status={paymentsStatus}
						payments={paymentAlerts}
						footer={
							<PaymentsFooter
								labels={labels.payments.footer}
								isExistingData={isExistingInvoices}
								numberOfPaymentsAlerts={numberOfPaymentsAlerts}
								totalPaymentsAlerts={totalPaymentsAlerts}
							/>
						}
						isLoading={isLoadingAlerts}
					/>
				</div>
			</div>
			<ToastContainer
				useAnimation
				delayEach={DELAY_EACH_TOAST_ANIMATION_BY_SECONDS}
				delay={DELAY_TOAST_ANIMATION_BY_SECONDS}
				toastsData={toasts}
			/>
		</div>
	);
};

interface IFooterLabels {
	viewAll: string;
	noDataText: string;
	noAlerts: string;
}

const MembershipsFooter = ({
	labels,
	isExistingData,
	numberOfMembershipAlerts,
}: {
	labels: IFooterLabels;
	isExistingData: boolean;
	numberOfMembershipAlerts: number;
}) => {
	const { viewAll, noDataText, noAlerts } = labels;
	const alertsText = generateMembershipAlertsText(numberOfMembershipAlerts);
	return (
		<Footer
			isExistingData={isExistingData}
			viewAllPath={viewAllMembershipsPath}
			alertsText={alertsText}
			viewAll={viewAll}
			noDataText={noDataText}
			noAlerts={noAlerts}
		/>
	);
};

const PaymentsFooter = ({
	labels,
	isExistingData,
	numberOfPaymentsAlerts,
	totalPaymentsAlerts,
}: {
	labels: IFooterLabels;
	isExistingData: boolean;
	numberOfPaymentsAlerts: number;
	totalPaymentsAlerts: number;
}) => {
	const { viewAll, noDataText, noAlerts } = labels;
	const alertsText = numberOfPaymentsAlerts > 0 ? geShowingAlerts(numberOfPaymentsAlerts, totalPaymentsAlerts) : '';
	return (
		<Footer
			isExistingData={isExistingData}
			viewAllPath={viewAllInvoicesPath}
			alertsText={alertsText}
			viewAll={viewAll}
			noDataText={noDataText}
			noAlerts={noAlerts}
		/>
	);
};
