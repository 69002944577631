<div data-aid="ScheduleXlsUploadModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog rc-modal rc-modal--small-header">
		<div class="modal-content">
			<div class="modal-header modal-header--purple">
				<button data-aid="button-ScheduleXlsUploadModal-cancel" type="button" class="close pull-right" (click)="cancel()" aria-label="Close">
					<i class="fa fa-times"></i>
				</button>
				<div class="modal-header__content">
					<h5>UPLOAD SCHEDULE FROM EXCEL</h5>
				</div>
			</div>
			<div class="modal-body" style="height: 250px">
				<p class="text-center">
					To upload your league schedule please download this sample
					<a href="/assets/schedule-upload-example.csv" style="font-weight: bold" target="_blank">file</a>, <br />
					After downloading the file, you can fill out the fields with the relevant data and then upload it through this
					uploader. Please notice: The supported file type is .csv only. The uploader will not work with an Excel
					(.xlsx) file. The rounds and teams filled out in the file must be added to the season in advance.
				</p>
				<div
					ng2FileDrop
					(click)="fileUploader.click()"
					[uploader]="uploader"
					class="BoxedDropZone BoxedDropZone--shadow"
				>
					<div class="BoxedDropZone__inner" *ngIf="!selectedFile; else previewView">
						<div class="BoxedDropZone__bottom">
							<div class="BoxedDropZone__bottom__side">
								<i class="fa fa-upload"></i>
								UPLOAD
							</div>
						</div>
					</div>
					<ng-template #previewView>
						<div class="BoxedDropZone__preview" style="height: 60px">
							<p class="text-center" style="margin-top: 30px">
								Selected file: <b>{{ selectedFile?._file?.name }}</b>
							</p>
						</div>
					</ng-template>

					<input
					    data-aid="scheduleUpload-input-file"
						type="file"
						ng2FileSelect
						[uploader]="uploader"
						[accept]="allowedMimeTypes.join(', ')"
						#fileUploader
						style="display: none"
					/>
				</div>
			</div>

			<div class="modal-footer">
				<button data-aid="button-ScheduleXlsUploadModal-submit" type="button" class="button button-empty cancel-btn" (click)="cancel()">Cancel</button>
				<rc-loading-btn
					[text]="'UPLOAD'"
					(onClick)="onSubmit()"
					[loading]="loading"
					[classes]="['button', 'button-assertive', 'button-padded', 'submit-btn']"
				>
				</rc-loading-btn>
			</div>
		</div>
	</div>
</div>
