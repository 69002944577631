import { IInvoice } from '../components/customers/singleInvoice/types';
import { EndRepeatEnum, TimeFrameEnum } from './Booking';
import { ICustomer } from './customers';
import { CurrencyEnum, PlatformsEnum, ProductTypesEnum } from './orders';
import { EPaymentMethod, EPaymentStatus, IPayingUser } from './payment';
import { Product } from './product';
import { EReservationStatus, MaintenanceDto, UpdateReservationDto } from './reservation';
import { AddonDto, Invoice, PaymentMethodTypeEnum, Slot } from '@bondsports/types';
import { Icons, IOption, ISlot, TRadioType } from '@bondsports/utils';

export enum ReservationTypeEnum {
	RENTAL = 'rental',
	INTERNAL = 'internal',
	MAINTENANCE = 'maintenance',
	// CUSTOM = 'custom',
}

export enum SlotTypeEnum {
	EXTERNAL = 'external',
	INTERNAL = 'internal',
	MAINTENANCE = 'maintenance',
	CUSTOM = 'custom',
}

export enum SlotDurationTypeEnum {
	DATES = 'dates',
	ALL_DAY = 'all day',
	DURATION = 'duration',
}

enum DurationUnitTypesEnum {
	MINUTES = 'minutes',
	HOURS = 'hours',
}

export enum FrequencyEnum {
	NONE = 'none',
	WEEKLY = 'weekly',
	DAILY = 'daily',
	MONTHLY = 'monthly',
	YEARLY = 'yearly',
}

enum MaintenanceTimingEnum {
	BEFORE = 1,
	AFTER = 2,
	AT_THE_BEGINING = 3,
	AT_THE_END = 4,
}

export enum InvoiceStatusEnum {
	ACTIVE = 'active',
	WAITING_ADMIN = 'waitingAdmin',
	WAITING_CLIENT = 'waitingClient',
	CANCELED = 'canceled',
}

export enum LineItemsStatusEnum {
	USER_PRODUCT = 'UserProduct',
	RENTAL_PRODUCT = 'RentalProduct',
}

enum CreatorTypeEnum {
	SPACE = 'space',
	PROGRAM_SEASON = 'program_season',
}

export type MaintenanceSlotDto = SlotDto & MaintenanceDto;

export interface SlotDto {
	// init after step 1
	title: string;
	creatorId: number;
	creatorType: string;
	userCreatorId: number;
	startDate: string;
	startTime?: string;
	endDate?: string;
	endTime?: string;
	spaceId: number;
	publicNotes?: string;
	privateNotes?: string;
	slotType: SlotTypeEnum;
	timezone?: string;
	sportIds: number[];
	parentSlotId?: number;
	maintenanceDurationdurationType?: DurationUnitTypesEnum;
	maintenanceTiming?: MaintenanceTimingEnum;
	durationValue?: number;
	paymentStatus: EPaymentStatus;
	approvalStatus: EReservationStatus;
	displayName?: string;
	internalName?: string;
	maintenance?: MaintenanceDto[];
	maintenanceSlots?: MaintenanceSlotDto[];

	relevantProducts?: Product[];
	product: Product;

	totalPrice: number; // how to calculate

	// init by server in step 2
	addonProducts?: Product[];

	// init after step 4
	id?: number;
	reservationId?: number;
	segmentId?: number;
	seriesId?: number;
	eventId?: number;
	invoiceId?: number;
	addonsProductUserIds?: number[] | null;
	reservation?: any;
	event?: any;
	isPrivate: boolean;

	addons?: AddonDto[];
	addonsIds?: number[];
	conflicts?: Slot[];
	colorCodeId?: string | number;
}

export interface Duration {
	duration: boolean;
	allDay: boolean;
	durationEndsAfter?: number;
	durationUnit?: DurationUnitTypesEnum;
}

export interface SeriesDto {
	startDate: string;
	startTime?: string;
	endDate?: string;
	endTime?: string;
	slotDurationType: SlotDurationTypeEnum;
	durationEndsAfter?: number;
	durationUnit?: DurationUnitTypesEnum;
	frequency: FrequencyEnum;
	isRepeat: boolean;
	repeatEvery?: number;
	repeatOn?: number[];
	repeatEndDate?: string;
	numberOccurrences?: number;
	maintenance?: MaintenanceDto[];
	slots?: SlotDto[];
	id?: number;
	resources?: { id: number; name: string }[];
}

export type SeriesClientDto = SeriesDto & { duration: boolean; allDay: boolean; occurrencesType?: EndRepeatEnum };

export interface SegmentDto {
	title: string;
	isPrivate: boolean;
	resourceIds: number[];
	sportIds: number[];
	series: SeriesDto[];

	publicNotesForSlots?: string;
	privateNotesForSlots?: string;

	addonIds?: number[];
	id: number;
	type: string; // birthday / practice / etc
	// tasks?:ITask[] // V3
	// isResourcesBlocked:boolean, // V3
	product?: Product;
	colorCodeId?: number | string;
}

export type SegmentsClientDto = Omit<SegmentDto, 'series'> & { sportId: string; series: SeriesClientDto[] };

export interface ReservationDto {
	customerId: number;
	name: string; //aka title in the client
	reservationType: ReservationTypeEnum;
	segments: SegmentDto[];
	description?: string;
	creatorId?: number;
	userCreatorId: number;
	privacySetting?: string;
	organizationId?: number;
	paymentStatus?: EPaymentStatus;
	creatorType?: CreatorTypeEnum;
	publicNotes?: string;
	privateNotes?: string;
	forms?: number[];
	addonProducts?: Product[];
	id?: number; // needed for update
	status?: EReservationStatus;
	invoiceId?: string;
	price?: number;
	addons?: any[];
	colorCodeId?: string | number;
	purchasingUserId?: number;
}

export type ReservationClientDto = Omit<ReservationDto, 'segments'> & {
	addons?: { productId: number; quantity: number }[];
	slotsBySeriesBySegments?: SlotDto[][][];
	segments: SegmentsClientDto[];
	optionalAddons?: Product[];
	optionalAddonsBySegments?: { [key: number]: Product[] };
	customer?: ICustomer;
	finalReservation: ReservationDto;
	resourcesProducts: any;
	invoice: any;
	colorCodeId?: string | number;
};

export interface LineItemsDto {
	resources?: ResourceDto[];
	type: LineItemsStatusEnum;
	organizationId: number;
	userId: number;
	product?: Product;
	productId: number;
	productType: ProductTypesEnum;
	ordinal: number;
	price: number;
	parentOrdinal?: number;
	originalPrice: number;
	paidAmount: number;
	currency: CurrencyEnum;
	paymentStatus: EPaymentStatus;
	isRefunded: boolean;
	isTaxInclusive: boolean;
	taxPrecent: number;
	unitPrice: number;
	quantity: number;
}

export interface InvoiceDto {
	invoiceId?: string | null;
	organizationId: number;
	price: number | null;
	status: InvoiceStatusEnum;
	lineItems: LineItemsDto[];
	paymentStatus: EPaymentStatus;
	currency: CurrencyEnum;
	creatingUserId: number;
	platform: PlatformsEnum;
}

interface ResourceDto {
	endDate: string;
	endTime: string;
	resourceId: number;
	resourceType: string;
	startDate: string;
	startTime: string;
}

export enum ReservationPricesUpdateTypeEnum {
	INDIVIDUAL = 'indvidual',
	CATEGORY = 'category',
	GLOBAL = 'global',
}

export class PurchasePaymentDto {
	// token - id of payment method
	token: string;
	type: EPaymentMethod;
}
export class BookingDto {
	reservation: ReservationDto;
	platform: PlatformsEnum;
	shiftId?: number;
	paymentData: PurchasePaymentDto;
	amountToPay: number;
	total: number;
	purchasingUserId: number;
	invoiceId?: number;
}

export enum MassEditTypeEnum {
	INDIVIDUAL = 'individual',
	COLLECTIVELY = 'collectively',
}
// TimSlotData - the time slot data for calculatiing the end repeat section
export interface TimeSlotData {
	startDate: string;
	startTime: string;
	endTime: string;
	selectedDays?: number[];
	occurrences: number;
	frequencies?: FrequencyEnum;
	repeatEvery?: string;
	endRepeatDate?: string;
}

export interface ISlotId {
	slotIndex: number;
	segmentIndex: number;
	seriesIndex: number;
	sortedSlotIndex?: number;
}

export type ISlotDtoExpanded = SlotDto & ISlotId;
export type ClientSlotDto = ISlotDtoExpanded & { addonIds?: number[]; isAddons?: boolean; optionalAddons?: Product[] };

export enum SectionToEditEnum {
	TITLE = 'title',
	ISPRIVATE = 'isPrivate',
	COLOR_SET = 'colorSet',
	RESOURCE = 'resource',
	ACTIVITY_TYPE = 'activityType',
	DATE_AND_TIME = 'dateAndTime',
	MAINTENANCE = 'maintenance',
	PUBLIC_NOTE = 'publicNotes',
	PRIVATE_NOTE = 'privateNotes',
	ADD_ONS = 'addons',
}

export interface SlotFormValues {
	sportId: number;
	title?: string;
	colorCodeId?: string | number;
	startDate: string;
	endDate: string;
	startTime: string;
	endTime: string;
	isPrivate: boolean;
	resourceIds?: number;
	isMaintenance?: boolean;
	maintenance?: MaintenanceDto[];
	publicNotes?: string;
	privateNotes?: string;
	seriesIndex?: number;
	slotIndex?: number;
	sortedSlotIndex?: number;
	segmentIndex?: number;
	isAddons?: boolean;
	addonIds?: (string | number)[];
	optionalAddons: IOption[];
	isDisplayChangeProductModal: boolean;
	isNewProduct: boolean;
	pricing_type: ReservationPricesUpdateTypeEnum;
	slotType: SlotTypeEnum;
}
export type MassEditFormValues = SlotFormValues & Duration;

export interface IndividualFormValues {
	individualForms?: MassEditFormValues[];
}
export type ISlotExpanded = ISlot & {
	slotIndex: number;
	eventIndex: number;
	segmentIndex: number;
	seriesIndex: number;
	sortedSlotIndex: number;
};

export interface AddSlotToSegmentDto {
	slot: SlotDto;
	addonIds?: number[];
}
export interface BaseInvoiceDto {
	platform: PlatformsEnum;
	shiftId?: number;
}
export interface SaveSlotToSegmentDto extends UpdateReservationDto {
	slots: SlotDto[];
}

export enum SlotActionsEnum {
	DUPLICATE = 'duplicate',
	DELETE = 'delete',
}

export interface IDeleteEventResponse {
	lineItems: {
		id: number;
		slotIds: number[];
		amount: number;
		quantity: number;
		canVoid: boolean;
		canRefund: boolean;
	}[];
	paymentMethods: {
		paymentType: PaymentMethodTypeEnum;
		paymentMethodId: string;
		fundLeft: number;
		ccLast4?: string;
		ccBrand?: string;
		payingUser?: IPayingUser;
	}[];
	nextFinancialStep: EFinancialSteps;
	invoice?: IInvoice;
}

export enum EFinancialSteps {
	VOID = 'void',
	REFUND = 'refund',
	NONE = 'none',
	REFUND_AND_VOID = 'refund-and-void',
	APPEND = 'append',
}

export interface IFormContextValues {
	//TODO: think of a better name?
	addons?: { productId: number; quantity: number }[];
	customer?: ICustomer;
	customerId: number;
	finalReservation: ReservationDto;
	isAddons?: boolean;
	name: string; //aka title in the client
	optionalAddons?: Product[];
	optionalAddonsBySegments?: { [key: number]: Product[] };
	order: any;
	pricing_type: ReservationPricesUpdateTypeEnum;
	reservationAddons: any;
	reservationType: ReservationTypeEnum;
	resourcesProducts: any;
	segments: SegmentsClientDto[];
	slotAddons: any;
	slotsBySeriesBySegments?: SlotDto[][][];
	userId: number;
}

export interface ICalculateAddonsResponse {
	reservation: ReservationDto;
	invoice: InvoiceDto;
}
//To do export from utils
interface IAddonProp {
	icon: Icons;
	title: string;
	price?: number;
	subTitle?: string;
	hasConflicts?: boolean;
	OnTriggerConflicts?: () => void;
}

export type ISummarySlot = {
	footerWithMaintenance?: string;
	footerWithoutMaintenance?: string;
	isMaintenancConflicting?: boolean;
	originalSlot: SlotDto;
	id?: string;
	index?: number;
	title: string;
	startDate?: string;
	startTime?: string;
	endDate?: string;
	endTime?: string;
	addons?: IAddonProp[];
	hasConflicts?: boolean;
	conflicts?: Slot[];
	spaceId: number;
} & ISlotId;

//use addons from SlotDto
//A temporary solution- need to refactor the how the summary slots are generated
export type IConflictsSummarySlot = Omit<ISummarySlot, 'addons'> & Omit<SlotDto, 'id'> & { id: string };

export type DraftEditData = ReservationDto & ISlotId & { conflicts: Slot[] };

export interface IRadio {
	componentType: TRadioType;
	description: string;
	title: string;
	value: MassEditTypeEnum | ReservationPricesUpdateTypeEnum;
}

export interface INewReservationMaintenanceFormValues {
	title: string;
	durationValue: number;
	maintenanceDurationdurationType: TimeFrameEnum;
	maintenanceTiming: MaintenanceTimingEnum;
}

export interface INewReservationSegmentsFormValues {
	resourceIds?: string[];
	sportId?: string;
	series: SeriesClientDto[];
	isPrivate?: boolean;
}

export interface INewReservationFormValues {
	pricing_type: ReservationPricesUpdateTypeEnum;
	segments: INewReservationSegmentsFormValues[];
}
