export const getXLastChars = (str: string, numberOfChars: number) => {
	if (str.length <= numberOfChars) {
		return str;
	}

	return str.slice(str.length - numberOfChars);
};

export const roundWithDecimal = (number: number, decimal: number = 100) => {
	return Math.round(number * decimal) / decimal;
};

//https://johnumarattil.medium.com/truncating-middle-portion-of-a-string-in-javascript-173bfe1f9ae3
export const truncateString = (str: string, firstCharCount = str.length, endCharCount = 0) => {
	if (str.length <= firstCharCount + endCharCount) {
		return str;
	}

	const firstPart = str.slice(0, firstCharCount);
	const endPart = str.slice(-endCharCount);
	const ellipsis = '...';

	return `${firstPart}${ellipsis}${endPart}`;
};

export function filterCharacters(content: string, ...characters: string[]): string {
	return content.replace(new RegExp(`[${characters.join('')}]+`), '');
}
