/** @jsxRuntime classic */
/** @jsx jsx */

import { SerializedStyles, useTheme, css, jsx } from '@emotion/react';
import React, { useState, useRef, useEffect, ReactNode } from 'react';

import { Popup } from '../Popup';
import { StyledSelect, StyledValue } from './style';
import { BnArrowDown, BnArrowUp } from '../../icons';
import { ISelectOptions, SelectOptions } from '../Options';
import { capitalize } from '../../functions';
import { Tooltip } from '../Tooltip';
import { getIcon } from '../../icons';
import { InputContainer } from '../Input/style';
import cx from 'classnames';
import { ErrorMessage } from '../ErrorMessage';
import { Icons } from '../../types/theme';
import { BnIcon } from '../icons_v2';

export interface IOption {
	value: string | number;
	label: string;
	icon?: Icons;
}

export interface ISelect {
	options: IOption[];
	placeholder?: string;
	value?: string;
	onSelecte?: (val: string | string[] | number[] | (string | number)[]) => void;
	isMultiple?: boolean;
	sizer?: 'M' | 'S';
	label?: string;
	disabled?: boolean;
	info?: string;
	error?: string;
	helper?: string;
	helperContent?: string;
	clear?: () => void;
	noLabel?: boolean;
	hideError?: boolean;
	onChange?: (v: unknown) => void;
	css?: SerializedStyles;
	enableSelectAll?: boolean;
	selectAllLabel?: string;
	unsetOptionsWidth?: boolean;
	isTopLayer?: boolean;
	handleLoadNextPage?: () => void;
	isFinalPage?: boolean;
	displayErrorBeforeVisited?: boolean;
}

export const Select = ({
	options,
	onSelecte = val => {},
	value = '',
	placeholder = '',
	sizer = 'S',
	label = '',
	noLabel = false,
	disabled = false,
	isMultiple = false,
	info = '',
	helper = '',
	hideError = false,
	error = '',
	helperContent = '',
	enableSelectAll = false,
	selectAllLabel = '',
	unsetOptionsWidth = false,
	CustomSelectOptionsComponent = undefined,
	clear,
	isTopLayer = false,
	displayErrorBeforeVisited = false,
	...rest
}: ISelect &
	React.SelectHTMLAttributes<HTMLSelectElement> & {
		CustomSelectOptionsComponent?: ({
			options,
			onSelect,
			value,
			autoFocus,
			isMultiple,
			enableSelectAll,
			selectAllLabel,
			handleSelectAll,
			handleRemoveAll,
		}: ISelectOptions) => JSX.Element;
	}) => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const [visited, setVisited] = useState<boolean>(false);
	const [toEnter, setToEnter] = useState<boolean>(false);
	const colors = useTheme();
	const ref = useRef<HTMLDivElement>(null);

	const isVisited = visited || displayErrorBeforeVisited;
	const newOptions = options?.map(opt => {
		return {
			...opt,
			value: String(opt.value),
		};
	});

	const style = rest.style;

	const chosen = options.filter(option => String(option.value) === String(value)).map(option => option.label);

	let multipleChosen;
	if (isMultiple) {
		try {
			multipleChosen = options
				.filter(option => (value as unknown as any[]).map(v => String(v)).includes(String(option.value)))
				.map(option => option.label);
		} catch {
			(err: any) => {
				console.log(err);
			};
		}
	}

	const handleSelectAll = () => {
		onSelecte(
			options.map(item => {
				return item.value;
			})
		);
	};

	const handleRemoveAll = () => {
		onSelecte([]);
	};

	const onChange = (val: string) => {
		if (isMultiple) {
			if (value && (value as unknown as any[])?.map(v => String(v))?.includes(String(val))) {
				onSelecte((value as unknown as any[]).map(v => String(v)).filter(v => v !== val));
			} else {
				//@ts-ignore
				onSelecte([...value, val]);
			}
		} else {
			onSelecte(val);
			setOpen(false);
			if (ref.current) {
				ref.current.focus();
			}
		}
	};

	useEffect(() => {
		if (toEnter) {
			if (!isOpen) {
				setVisited(true);
			}
		}
		setToEnter(true);
	}, [isOpen]);

	const basicSelectProps = {
		options: newOptions,
		onSelect: onChange,
		value,
	};

	const [SelectOptionsComponent, selectProps] = CustomSelectOptionsComponent
		? [CustomSelectOptionsComponent, { ...basicSelectProps }]
		: [
				SelectOptions,
				{
					...basicSelectProps,
					handleSelectAll,
					handleRemoveAll,
					isMultiple,
					enableSelectAll,
					selectAllLabel,
					handleLoadNextPage: rest.handleLoadNextPage,
					infiniteScroll: Boolean(rest.handleLoadNextPage),
					isFinalPage: rest.isFinalPage,
				},
		  ];

	return (
		<InputContainer
			className={cx(
				{
					M: sizer === 'M',
					S: sizer === 'S',
				},
				rest.className
			)}
			style={style}
			theme={{ ...colors }}
		>
			{(label || noLabel) && (
				<div className="label">
					<div>
						{capitalize(label)}
						{rest.required && !noLabel && ' *'}
					</div>
					{info && <Tooltip content={info}>{getIcon('info', 'l')}</Tooltip>}
				</div>
			)}
			<div className="select-container" data-aid="select" data-label={label}>
				<Popup
					isTopLayer={isTopLayer}
					fullWidth={!unsetOptionsWidth}
					disableAutoTrigger
					body={disabled ? undefined : <SelectOptionsComponent {...selectProps} />}
					unsetOptionsWidth={unsetOptionsWidth}
					onOpenChange={setOpen}
					padding={'0'}
					borderRadius={0}
					trigger={isOpen}
				>
					<StyledSelect
						className={cx({
							placeholder: !String(value),
							disabled: disabled,
						})}
						theme={{ ...colors }}
						tabIndex={0}
						ref={ref}
						onClick={() => {
							if (ref.current === document.activeElement) {
								setOpen(!isOpen);
							}
						}}
						onFocus={e => {
							setOpen(!isOpen);
						}}
					>
						<StyledValue className={cx('value', { error: error && isVisited, disabled })} theme={{ ...colors }}>
							{String(value) ? (isMultiple ? multipleChosen?.join(', ') : capitalize(chosen[0])) : placeholder}
						</StyledValue>
						<div className="button">{isOpen ? <BnArrowUp /> : <BnArrowDown />}</div>
					</StyledSelect>
				</Popup>
				{clear && !rest.required && (
					<div className="remove" onClick={clear}>
						<BnIcon icon={Icons.remove} />
					</div>
				)}
			</div>
			{!hideError && <div style={{ minHeight: '15px' }}>{isVisited && <ErrorMessage error={error} />}</div>}
			{helper && (
				<div className="helper">
					{helperContent && <Tooltip content={helperContent}>{getIcon('info', 'r')}</Tooltip>}
					<div>{capitalize(helper)}</div>
				</div>
			)}
		</InputContainer>
	);
};
