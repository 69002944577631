import { EndRepeatEnum, MaintenanceTimingEnum } from 'app/react/types/Booking';
import { TranslationEn } from 'assets/i18n/en';
import { ClientAddon, IUpdateSlotPrices } from 'app/react/types/calendar';
import {
	FrequencyEnum,
	ICalculateAddonsResponse as IResponse,
	INewReservationFormValues,
	InvoiceDto,
	ReservationClientDto,
	ReservationDto,
	ReservationPricesUpdateTypeEnum,
	SlotDto,
	SlotDurationTypeEnum,
	ReservationTypeEnum,
	VisibilityEnum,
} from './NewReservationTypes';
import * as dayjs from 'dayjs';
import { deepClone } from 'app/react/lib/utils';
import { DurationUnitTypesEnum, Product } from '@bondsports/types';
import { newReservationApi, IUpdatePrices } from '@app/react/lib/api/newReservationApi';

const labels = TranslationEn.errors;

//To Do: Remove- duplicated from apps\backoffice\src\app\react\lib\dates.ts
export const timeSerialization = (time: string) => {
	if (time) {
		if (dayjs(time, 'hh:mm a').isValid()) {
			return dayjs(time, 'hh:mm a').format('HH:mm:ss');
		}
		return time;
	} else {
		return '';
	}
};

export const ReservationTypeToSlotType = {
	rental: 'external',
	program: 'internal',
	maintenance: 'maintenance',
};

export const generateData = (values: ReservationClientDto, removeSlots = false) => {
	values.segments = values.segments.map((seg, segIndex) => {
		// relevant to step 2
		if (seg.addonIds) {
			seg.addonIds = seg.addonIds.filter(id => id).map(id => Number(id));
		}

		seg.series = seg.series.map((s, index) => {
			// relevant to step 2
			if (values?.slotsBySeriesBySegments) {
				s.slots = values?.slotsBySeriesBySegments?.[segIndex]?.[index];
			}

			if (removeSlots) {
				delete s.slots;
			}

			// override the start+end times with serialized values
			const startTime = timeSerialization(s.startTime);
			const endTime = timeSerialization(s.endTime);

			const data = {
				...s,
				frequency: s.frequency || FrequencyEnum.NONE,
				slotDurationType: s.duration
					? SlotDurationTypeEnum.DURATION
					: s.allDay
					? SlotDurationTypeEnum.ALL_DAY
					: SlotDurationTypeEnum.DATES,
				startTime,
				endTime,
				maintenance: s.maintenance?.filter(m => m.title),
				repeatEndDate: s.occurrencesType === EndRepeatEnum.ON ? s.repeatEndDate : null,
				numberOccurrences: s.occurrencesType === EndRepeatEnum.AFTER ? s.numberOccurrences : null,
			};

			// remove the start+end times if not exist
			if (!startTime) delete data.startTime;
			if (!endTime) delete data.endTime;
			return data;
		});
		return {
			...seg,
			isPrivate: seg.isPrivate,
		};
	});

	if (values.addons) {
		values.addons = values.addons
			.filter(addon => addon.productId)
			.map(addon => {
				return {
					...addon,
					productId: Number(addon.productId),
					quantity: Number(addon.quantity),
				};
			});
	}

	// remove
	delete values.customer;
	delete values.slotsBySeriesBySegments;
	delete values.optionalAddons;
	delete values.optionalAddonsBySegments;
	delete (values as ReservationClientDto & { finalReservation: any }).finalReservation;

	return values;
};

export const generateNewSegments = ({
	organizationId,
	facilityId,
	data,
	onError,
}: {
	organizationId: number;
	facilityId: number;
	data: any;
	onError;
}) => {
	return newReservationApi.calculateAddons(organizationId, facilityId, generateData({ ...data }, true)).then(res => {
		if ((res as { err: string[] }).err) {
			onError(TranslationEn.errors.noProducts);
		} else {
			if (
				(res as IResponse).reservation.reservationType === ReservationTypeEnum.CUSTOMER &&
				(res as { invoice: InvoiceDto }).invoice.lineItems.length === 0
			) {
				onError(TranslationEn.errors.noProducts);
			} else {
				return (res as IResponse).reservation.segments;
			}
		}
	});
};

export const generateSlotsBySegments = (res: { reservation: ReservationDto; invoice?: InvoiceDto }, form?: any) => {
	const addons = {};
	const slotsBySeriesBySegments: SlotDto[][][] = [];
	const slots = [];
	res?.reservation?.segments?.forEach(segment => {
		const slotBySeries: SlotDto[][] = [];
		segment?.series?.forEach(s => {
			slotBySeries.push(s.slots);
			s?.slots?.forEach(slot => {
				slots.push(slot);
				slot?.product?.childProductPackages?.forEach(addon => {
					if (!addons[addon.childProductId]) {
						addons[addon.childProductId] = { ...addon.childProduct, level: addon.level };
					}
				});
			});
		});
		slotsBySeriesBySegments.push(slotBySeries);
	});

	if (form) {
		form.mutators.onSelect(
			`optionalAddons`,
			Object.keys(addons).map(key => addons[key])
		);
	}

	return { addons, slotsBySeriesBySegments, slots };
};
export const getAddonsByProduct = (product: Product) => {
	const addons = {};
	product?.childProductPackages.forEach(addon => {
		if (!(addon.childProductId in addons)) {
			addons[addon.childProductId] = { ...addon.childProduct, level: addon.level };
		}
	});
	return Object.keys(addons).map(key => addons[key]) as ClientAddon[];
};

export const generateSlots = async ({
	organizationId,
	facilityId,
	data,
	onError,
	callback,
	form,
	name,
	updateInvoice,
	segmentIndex,
	updateProduct = false,
	handleDraftSlots,
}: {
	organizationId: number;
	facilityId: number;
	data: any;
	onError;
	callback?: () => void;
	form?: any;
	name?: string;
	updateInvoice?: boolean;
	segmentIndex?: number;
	updateProduct?: boolean;
	handleDraftSlots?: (v: any) => void;
}) => {
	const newValues = deepClone(data);
	const res = await newReservationApi.calculateAddons(organizationId, facilityId, generateData({ ...data }, true));
	if ((res as { err: string[] }).err) {
		onError(TranslationEn.errors.noProducts);
		return undefined;
	} else {
		if (
			(res as IResponse).reservation.reservationType === ReservationTypeEnum.CUSTOMER &&
			(res as { invoice: InvoiceDto }).invoice.lineItems.length === 0
		) {
			onError(TranslationEn.errors.noProducts);
			return undefined;
		} else {
			const { addons, slotsBySeriesBySegments, slots } = generateSlotsBySegments(res as IResponse);

			form.mutators.onSelect('slotsBySeriesBySegments', slotsBySeriesBySegments);
			newValues.slotsBySeriesBySegments = slotsBySeriesBySegments;
			form.mutators.onSelect('finalReservation', (res as IResponse).reservation);
			newValues.finalReservation = (res as IResponse).reservation;
			form.mutators.onSelect(
				`optionalAddons`,
				Object.keys(addons).map(key => addons[key])
			);
			newValues.optionalAddons = Object.keys(addons).map(key => addons[key]);

			if (handleDraftSlots) {
				handleDraftSlots(slots);
			}

			if (updateProduct) {
				const fieldName = name ? `${name}.product` : 'product';
				const product = (res as IResponse).reservation?.segments[0]?.series[0]?.slots?.[0]?.product;
				form.mutators.onSelect(fieldName, product);
				newValues[fieldName] = product;
			}

			if (segmentIndex !== undefined) {
				const newOptionalAddons = { ...data.optionalAddons };
				newOptionalAddons[segmentIndex] = Object.keys(addons).map(key => addons[key]);
				form.mutators.onSelect(`optionalAddonsBySegments`, newOptionalAddons);
				newValues.optionalAddonsBySegments = newOptionalAddons;
			}
			form.mutators.onSelect(`addons`, [{ productId: '', quantity: '' }]);
			newValues.addons = [{ productId: '', quantity: '' }];
			if (name) {
				form.mutators.onSelect(`${name}.isAddons`, true);
				newValues[`${name}.isAddons`] = true;
				form.mutators.onSelect(`${name}.addonIds`, ['']);
				newValues[`${name}.addonIds`] = [''];
			} else {
				form.mutators.onSelect(`isAddons`, true);
				newValues.isAddons = true;
				form.mutators.onSelect('addonIds', ['']);
				newValues['addonIds'] = [''];
			}
			if (callback) {
				setTimeout(() => {
					callback();
				}, 300);
			}
			if (updateInvoice) {
				form.mutators.onSelect('segments', (res as IResponse).reservation.segments);
				form.mutators.onSelect('invoice', (res as IResponse).invoice);
			}
			newValues.segments = (res as IResponse).reservation.segments;
			newValues.invoice = (res as IResponse).invoice;
			return newValues as ReservationClientDto;
		}
	}
};

export const updateAddons = ({
	values,
	form,
	organizationId,
	facilityId,
	callback,
	onError,
}: {
	values: any;
	form: any;
	organizationId: number;
	facilityId: number;
	callback: () => void;
	onError: (message: string) => void;
}) => {
	const data = JSON.parse(JSON.stringify(values));

	newReservationApi.updateAddons(organizationId, facilityId, generateData(data, false)).then(res => {
		if ((res as unknown as { err: any }).err) {
			onError(labels.noProducts);
		} else {
			const slotBySeriesBySegments: SlotDto[][][] = [];
			(res as { reservation: ReservationDto; invoice: InvoiceDto }).reservation.segments.forEach(segment => {
				const slotBySeries: SlotDto[][] = [];
				segment.series.forEach(s => {
					slotBySeries.push(s.slots);
				});
				slotBySeriesBySegments.push(slotBySeries);
			});

			form.mutators.onSelect('slotsBySeriesBySegments', slotBySeriesBySegments);

			// should be stored
			form.mutators.onSelect(
				'segments',
				(res as { reservation: ReservationDto; invoice: InvoiceDto }).reservation.segments
			);
			form.mutators.onSelect('invoice', res.invoice);
			callback();
		}
	});
};

type IUpdatedPriceObject = IUpdateSlotPrices | IUpdatePrices;

//refactor needed: values and formData are the same
export const handlePricingType = (values: any, obj: IUpdatedPriceObject, formData: any) => {
	switch (values.pricing_type) {
		case ReservationPricesUpdateTypeEnum.INDIVIDUAL:
			const resourcesProducts = generateProductUpdateInterface(formData?.resourcesProducts);
			const reservationAddons = generateProductUpdateInterface(formData?.reservationAddons);
			const slotAddons = generateProductUpdateInterface(formData?.slotAddons);
			obj.products = [...resourcesProducts, ...reservationAddons, ...slotAddons];
			break;
		case ReservationPricesUpdateTypeEnum.CATEGORY:
			obj.category = { slot: values.resourcesSubTotal, addon: values.addonsSubTotal };
			break;
		case ReservationPricesUpdateTypeEnum.GLOBAL:
			obj.globalPrice = formData.reservationTotalPrice;
			break;
		default:
			break;
	}
};

interface AddToCartProps {
	invoice: InvoiceDto;
	finalReservation: ReservationDto;
}

export const updatePrices = ({
	values,
	form,
	organizationId,
	facilityId,
	callback,
	onError,
}: {
	values: any;
	form: any;
	organizationId: number;
	facilityId: number;
	callback: (values: AddToCartProps) => void;
	onError: (message: string) => void;
}) => {
	const formData = JSON.parse(JSON.stringify(values));

	const obj: IUpdatePrices = {
		reservation: generateData({ ...formData }, false),
		type: values.pricing_type,
	};
	handlePricingType(values, obj, formData);

	newReservationApi.updatePrices(organizationId, facilityId, obj).then(res => {
		if ((res as unknown as { err: any }).err) {
			onError(labels.somethingWentWrong);
		} else {
			const slotBySeriesBySegments: SlotDto[][][] = [];
			(res as { reservation: ReservationClientDto; invoice: InvoiceDto }).reservation.segments.forEach(segment => {
				const slotBySeries: SlotDto[][] = [];
				segment.series.forEach(s => {
					slotBySeries.push(s.slots);
				});
				slotBySeriesBySegments.push(slotBySeries);
			});
			const finalReservation = { ...res.reservation, invoice: res.invoice };
			const invoice = res.invoice;
			form.mutators.onSelect('slotsBySeriesBySegments', slotBySeriesBySegments);
			form.mutators.onSelect('finalReservation', finalReservation);
			form.mutators.onSelect('invoice', invoice);
			form.mutators.onSelect(
				'addons',
				(res as { reservation: ReservationClientDto; invoice: InvoiceDto }).reservation.addons
			);
			form.mutators.onSelect('segments', (res as IResponse).reservation.segments);
			callback({ invoice, finalReservation });
		}
	});
};

export const initialData: INewReservationFormValues = {
	pricing_type: ReservationPricesUpdateTypeEnum.INDIVIDUAL,
	visibility: VisibilityEnum.PUBLIC,
	colorCodeId: undefined,
	segments: [
		{
			series: [
				{
					startDate: '',
					duration: false,
					endDate: '',
					allDay: false,
					isRepeat: false,
					repeatOn: [],
					occurrencesType: EndRepeatEnum.ON,
					slotDurationType: SlotDurationTypeEnum.DATES,
					frequency: FrequencyEnum.NONE,
					maintenance: [
						{
							title: '',
							durationValue: 10,
							maintenanceDurationdurationType: DurationUnitTypesEnum.MINUTES,
							maintenanceTiming: MaintenanceTimingEnum.BEFORE,
						},
					],
				},
			],
			isPrivate: false,
		},
	],
};

export const generateProductUpdateInterface = arr => {
	if (arr) {
		return arr.map(product => {
			return {
				productId: product.id,
				price: +product.price,
			};
		});
	}
	return [];
};
