export const ORGANIZATION_PATH = '/organization';
export const FINANCIAL_PATH = '/financial';
export const CREATE_ORGANIZATION = '/profile/new';
export const CREATE_FACILITY_PATH = '/organization/facilities/new';
export const FACILITIES_PATH = '/organization/facilities';
export const SCANNERS_TEST_PATH = '/organization/scanners';
export const SPACES_PATH = '/organization/spaces';
export const MANAGE_PAYMENT_METHODS_PATH = '/customer';
export const CREATE_SPACE_PATH = '/organization/spaces/new';
export const PROMO_CODES_PATH = '/organization/promocodes';
export const STATION_PAGE = 'station';
