<div data-aid="MoveTeamToSeasonModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog rc-modal" *ngIf="team">
		<form [formGroup]="teamEditForm" novalidate>
			<div class="modal-content">
				<div class="modal-header modal-header--assertive">
					<button data-aid="button-MoveTeamToSeasonModal-move" type="button" class="close pull-right" (click)="reset(true)" aria-label="Close">
						<i class="fa fa-times"></i>
					</button>
					<div class="modal-header__content">
						<i class="icon-rc-teams-icon"></i>
						<h5>MOVE TEAM TO SEASON</h5>
					</div>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12">
							<div class="row">
								<div class="col-md-6">
									<div class="input-group">
										<label for="">Move Team</label>
										<span>{{ team?.name?.toUpperCase() }}</span>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-group">
										<label for="">From Season</label>
										<span>{{ currentSeason?.name?.toUpperCase() }}</span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div
										class="input-group"
										[ngClass]="{
											'has-error': !teamEditForm.get('toLeagueId').valid && teamEditForm.get('toLeagueId').touched
										}"
									>
										<label for="">To League</label>
										{{ leaguesSelections?.length ? '' : 'No leagues found' }}
										<select
											*ngIf="leaguesSelections?.length"
											(change)="leagueSelected($event.target.value)"
											name="toLeagueId"
											class="select"
											formControlName="toLeagueId"
											[required]="true"
										>
											<option value="{{ i.id }}" *ngFor="let i of leaguesSelections">
												{{ i.name?.toUpperCase() }}
											</option>
										</select>
									</div>
								</div>

								<div class="col-md-6">
									<div
										class="input-group"
										[ngClass]="{
											'has-error': !teamEditForm.get('toSeasonId').valid && teamEditForm.get('toSeasonId').touched
										}"
									>
										<label for="">To Season</label>
										{{ seasonsSelections?.length ? '' : 'No seasons found' }}
										<select
											*ngIf="seasonsSelections?.length"
											(change)="seasonSelected($event.target.value)"
											name="toSeasonId"
											class="select"
											formControlName="toSeasonId"
											[required]="true"
										>
											<option value="{{ i.id }}" *ngFor="let i of seasonsSelections">
												{{ i.name?.toUpperCase() }}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6">
									<div
										class="input-group"
										[ngClass]="{
											'has-error': !teamEditForm.get('toDivisionId').valid && teamEditForm.get('toDivisionId').touched
										}"
									>
										<label for="">Division</label>
										<ng-container *ngIf="!teamEditForm.get('toSeasonId').value"> Select a season first </ng-container>
										<ng-container *ngIf="teamEditForm.get('toSeasonId').value">
											<ng-container *ngIf="divisionsSelections && !divisionsSelections.length">
												No divisions found
											</ng-container>

											<select
												*ngIf="divisionsSelections?.length"
												name="toDivisionId"
												class="select"
												formControlName="toDivisionId"
												[required]="true"
											>
												<option value="{{ i.id }}" *ngFor="let i of divisionsSelections">
													{{ i.name?.toUpperCase() }}
												</option>
											</select>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer">
					<button data-aid="button-MoveTeamToSeasonModal-cancel" type="button" class="button button-empty cancel-btn" (click)="reset(true)">Cancel</button>
					<rc-loading-btn
						[text]="'MOVE TEAM'"
						[disabled]="!teamEditForm.valid"
						(onClick)="submit(teamEditForm.value)"
						[loading]="loading"
						[classes]="['button', 'button-assertive', 'button-padded', 'submit-btn']"
					>
					</rc-loading-btn>
				</div>
			</div>
		</form>
	</div>
</div>
