/** @jsx jsx */
import React, { Fragment, useCallback, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import * as dayjs from 'dayjs';
import { RCGenderEnum } from '@rcenter/core';
import { Button, Icons } from '@bondsports/utils';
import { GenderEnum, MediaDto } from '@bondsports/types';
import { useErrorModalDepricated } from '../../components/shared/ErrorDepricated';
import { ColorPickerBlock } from '../../components/shared/formBlocks/ColorPickerBlock';
import { RightSide, mainCon, mainContainer, detailsPartCss, formLine } from '../../pages/customers/DetailsPage/styles';
import { FrameContainer } from '../../components/customers/utils/FrameContainer';
import { FormInput } from '../../components/shared/FormControls/FormInput';
import { FormSelect } from '../../components/shared/FormControls/FormSelect';
import { FormDateInput } from '../../components/shared/FormControls/FormDateInput';
import { useColorCode } from '../../hooks/useColorCode';
import { useCustomer } from '../../components/customers/customerPage/hooks/useCustomer';
import { customersApi } from '../../lib/api/customersApi';
import { checkIsDependentCustomer, getEmergencyContactDetails } from '../../components/customers/lib';
import { EDateTimeFormats } from '../../types/times';
import { ICustomer } from '../../types/customers';
import { TranslationEn } from '@assets/i18n/en';
import { ContactDetails } from './blocks/ContactDetails';
import { widthCss } from '../../styles/utils';
import { EmergencyContact } from './blocks/EmergencyContact';
import { cantactDetailsValidation } from './familyAccount/lib';

export const containerCss = css`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
`;

interface IDetails extends ICustomer {
	//TBD check if theres a better interface to extend
	id: number;
	firstName: string;
	lastName: string;
	gender: GenderEnum;
	birthDate: string;
	phoneNumber: string;
	email: string;
	street: string;
	city: string;
	zip: string;
	state: string;
	colorCodeId: number;
	emergencyContactName?: string;
	emergencyContactPhone?: string;
}

interface Props {
	state: ICustomer;
	toggle: () => void;
	organizationId: number;
	customerId: number;
	editColorModalToggle: () => void;
	newMainMedia?: MediaDto;
}

export const EditCustomerDetailsForm = ({ state, toggle, organizationId, customerId, editColorModalToggle, newMainMedia }: Props) => {
	const [error, setError] = useState('');
	const { ErrorModalDepricated, ErrorToggle } = useErrorModalDepricated();
	const { getDefaultColorCodeId } = useColorCode();
	const { fetchCustomerData } = useCustomer();
	const emergencyContact = getEmergencyContactDetails(state.emergencyContacts);
	const labels = TranslationEn.customers.detailsPage;

	const isDependentCustomer = checkIsDependentCustomer(state);

	const handleSubmit = async (organizationId, entries) => {
		const response = await customersApi.updateCustomer(organizationId, customerId, entries as ICustomer);
		return response;
	};

	const prepareData = useCallback((data: IDetails) => {
		return {
			id: data.id,
			firstName: data.firstName,
			lastName: data.lastName,
			gender: data.gender,
			birthDate: data.birthDate,
			phoneNumber: data.phoneNumber,
			email: data.email,
			street: data.street,
			city: data.city,
			zip: data.zip,
			state: data.state,
			colorCodeId: +data.colorCodeId ? +data.colorCodeId : null,
			emergencyContactName: data.emergencyContactName,
			emergencyContactPhone: data.emergencyContactPhone,
			mainMedia: newMainMedia,
		};
	}, [newMainMedia]);

	const triggerEditColorModalIfColorChanged = (colorCodeId?: number) => {
		if (colorCodeId !== state.colorCodeId) {
			editColorModalToggle();
		}
	};

	const submit = useCallback(
		async entries => {
			const newData = prepareData(entries);
			handleSubmit(organizationId, newData)
				.then(res => {
					if (res.data) {
						fetchCustomerData({
							organizationId: organizationId,
							customerId: res.data.id,
							isDisableTriggerLoader: true,
						});
						toggle();
						triggerEditColorModalIfColorChanged(entries.colorCodeId);
					} else {
						setError(String(res.errorMessage));
						ErrorToggle();
					}
				})
				.catch(err => {
					setError(String(err.message));
					ErrorToggle();
				});
		},
		[organizationId, prepareData]
	);

	const generatedState = () => {
		const newState = {
			...state.address,
			...state,
			emergencyContactName: emergencyContact?.name,
			emergencyContactPhone: emergencyContact?.phoneNumber,
		};
		delete newState.emergencyContacts;

		Object.keys(state).forEach(key => {
			if (!state[key]) {
				delete newState[key];
			}
		});
		// there is dirty data with 'null' as phone number
		if (!Number(newState.phoneNumber)) {
			delete newState.phoneNumber;
		}
		const initialValues = {
			...newState,
			birthDate:
				newState.birthDate && dayjs(newState.birthDate).isValid()
					? dayjs(newState.birthDate).format(EDateTimeFormats.YYYY_MM_DD)
					: '',
			colorCodeId: newState.colorCodeId || getDefaultColorCodeId(),
		};
		return initialValues;
	};

	return (
		<Fragment>
			<ErrorModalDepricated message={error} />

			<div data-aid="editCustomerDetails" css={containerCss}>
				<Form
					onSubmit={submit}
					mutators={{
						...arrayMutators,
						clear: (args, state, { changeValue }) => {
							changeValue(state, args[0], () => undefined);
						},
						onSelect: (args, state, { changeValue }) => {
							changeValue(state, args[0], () => args[1]);
						},
					}}
					initialValues={generatedState()}
					validate={cantactDetailsValidation}
					render={({ form, handleSubmit }) => {
						const { initialValues, values } = form.getState();

						return (
							<form
								autoComplete="off"
								style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
								onSubmit={handleSubmit}
								encType="multipart/form-data"
							>
								{/* <MiddleWhiteContainer width={600} margin="0" height="unset" isTransparent={true}> */}
								<div css={mainCon} style={{ marginTop: '-1rem' }}>
									<div css={mainContainer}>
										<FrameContainer title={TranslationEn.customers.detailsPage.personalDetails}>
											<div css={detailsPartCss}>
												<div css={formLine}>
													<div css={RightSide}>
														<FormInput
															label={TranslationEn.customers.detailsPage.firstName}
															required={initialValues.firstName}
															name="firstName"
															sizer="S"
															disableTrailing={true}
														/>
													</div>
													<FormInput
														label={TranslationEn.customers.detailsPage.lastName}
														required={initialValues.lastName}
														name="lastName"
														sizer="S"
														disableTrailing={true}
													/>
												</div>

												<div css={formLine}>
													<div css={RightSide}>
														<FormSelect
															options={[
																{
																	label: TranslationEn.gender[RCGenderEnum.MALE],
																	value: RCGenderEnum.MALE,
																},
																{
																	label: TranslationEn.gender[RCGenderEnum.FEMALE],
																	value: RCGenderEnum.FEMALE,
																},
																{
																	label: TranslationEn.gender[RCGenderEnum.OTHER],
																	value: RCGenderEnum.OTHER,
																},
															]}
															placeholder=""
															name="gender"
															onClear={form.mutators.clear}
															onSelecting={form.mutators.onSelect}
															required={initialValues.gender}
															label={TranslationEn.customers.detailsPage.gender}
														/>
													</div>
													<FormDateInput
														rightIcon={Icons.calendar}
														required
														name="birthDate"
														enablePast={true}
														sizer="S"
														isBirthday
														label={TranslationEn.customers.detailsPage.dob}
													/>
												</div>
												<div css={[formLine, widthCss(50)]}>
													<div css={RightSide}>
														<ColorPickerBlock
															label={TranslationEn.customers.detailsPage.selectColorSet}
															formProp={form}
														/>
													</div>
												</div>
											</div>
										</FrameContainer>
										<ContactDetails
											isDisplayEmailNote={!isDependentCustomer}
											isDependentCustomer={isDependentCustomer}
										/>
										{/* TODO: remove formvalues after moving to FormWrapper */}
										<EmergencyContact formvalues={values} />
										<FormSpy
											subscription={{ valid: true }}
											render={props => {
												return <FormFooter valid={props.valid} toggle={toggle} />;
											}}
										/>
									</div>
								</div>
							</form>
						);
					}}
				/>
			</div>
		</Fragment>
	);
};

const footerContainer = css`
	display: flex;
	justify-content: flex-end;
	width: 100%;
`;

const FormFooter = ({ valid, toggle }: { valid: boolean; toggle: () => void }) => {
	const submit = () => {};
	return (
		<div css={footerContainer}>
			<div>
				<Button data-aid="button-editCustomerDetails-cancel" theme="basic" type="button" sizer="M" onClick={toggle}>
					{TranslationEn.customers.detailsPage.cancel}
				</Button>
			</div>
			<div>
				<Button data-aid="button-editCustomerDetails-save" disabled={!valid} onClick={submit} theme="primary" sizer="M" type="submit">
					{TranslationEn.memberships.selectPage.save}
				</Button>
			</div>
		</div>
	);
};
