/** @jsx jsx */
import { Breadcrumbs } from '@bondsports/utils';
import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/react';
import * as dayjs from 'dayjs';
import { BnIcon, Button, EInvoicingTabs, Icons, ModalWindow, Popup, PublicTag } from '@bondsports/utils';
import { useVoidInvoiceModal } from 'app/react/components/customers/singleInvoice/voidModal/VoidModal';
import {
	ILineItem,
	PaymentStatusEnum as OrderPaymentStatusEnum,
	ProductTypesEnum,
	VoidActionType,
} from 'app/react/types/orders';
import { useInvoice } from '../../../hooks/useInvoice';
import { Capitalize } from '../../../lib/form';
import { ClockLabel, HeaderContainer, HeaderTitle, MoreMenu, SideContainer } from '../../shared/Header';
import { flex, flexCol, gapCss, primaryColor } from '../../../styles/utils';
import { colors } from '../../../styles/theme';
import { Tag } from '../../shared/Tag';
import { EStatusColorMapper, ICustomer, ICustomerResponse } from '../../../types/customers';
import { useModal } from '@bondsports/utils';
import { Tabs } from '../customerPage';
import { PrivateNotes } from '../../shared/Notes/PrivateNotes';
import { useNotes } from '../../../hooks/useNotes';
import { IInvoice } from './types';
import { EDocumentType } from '../../../types/notes';
import { useOrganization } from '../../../hooks/useOrganization';
import { useToggle } from 'app/react/hooks/useToggle';
import { paymentApi } from 'app/react/lib/api/paymentApi';
import { useNotification } from 'app/react/hooks/useNotification';
import { ENotificationType } from 'app/react/stores/baseStore';
import { FailureModal } from '../../shared/RemoveFromReservation/components/FailureModal';
import { useNavigation } from 'app/react/hooks/useNavigation';
import { ERoutePaths as EPath } from '../../../types/navigation';
import { useErrorModal } from '../../../hooks/useErrorModal';
import { TranslationEn } from '@assets/i18n/en';
import { getInvoiceStatus } from '@app/react/components/customers/utils/invoice.utils';
import { PaymentRequestFlow } from './PaymentRequestFlow';
import { PaymentStatusEnum } from '@bondsports/types';
import { customersApi } from '@app/react/lib/api/customersApi';
import { useQuery } from '@app/react/hooks/useQuery';
import { OpenSectionEnum, UrlParamEnum } from '../customerPage/CustomerPageBodyMapper/Overview/utils';
import { getCustomerPrimaryEmail } from '../lib';

const ACTION_REFUND_URL = '/action/refund';
const CUSTOMER_LIST_URL = '/';
const CUSTOMER_URL_ROOT = '/customer';
const URL_SEPARATOR = '/';
const OVERVIEW_URL = '/overview';
const INVOICES_URL = '/invoices';
const DETAILS_URL = '/details';

const containerCss = (separateHeader: boolean) => css`
	position: sticky;
	top: 0;
	z-index: ${separateHeader ? 1 : 5};
`;

const marginRight = css`
	margin-right: 8px;
`;

const TabsCss = css`
	${flex};
	margin-left: -1rem;
	margin-bottom: 1rem;
	margin-top: 0.5rem;

	.link {
		padding: 14px;
		cursor: pointer;
		text-align: center;
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 1.4rem;
		border-bottom: 3px solid transparent;
		line-height: 17px;
		text-align: center;
		color: ${colors.formInputBg};

		&:hover {
			color: ${colors.brandPrimary};
		}
	}

	.active {
		color: ${colors.brandPrimary};
		border-bottom: 3px solid ${colors.brandPrimary};

		cursor: pointer;
	}
`;

const rowContainer = (separateHeader: boolean) => css`
	${flex};
	justify-content: space-between;
	width: 100%;
	margin-top: ${separateHeader ? '0' : '12px'};
`;

const lastActiveCss = css`
	${flexCol};
	${gapCss(6)};
`;

interface Props {
	invoiceState: IInvoice;
	DiscountToggle?: (isInvoice: boolean) => void;
	fetchScheduledPayments?: () => void;
	isPrimaryButton?: boolean;
	scheduledPayments?: any;
	redirectionToRefund: string;
	tabs?: {
		title: string;
		link: string;
		disabled: boolean;
		onAction?: (tab?: EInvoicingTabs) => void;
		isActive?: boolean;
	}[];
	onVoidSuccess: (voidType: VoidActionType) => void;
	separateHeader?: boolean;
	toggleBack?: () => void;
	customerState: ICustomer;
	ChargeButton?: ReactNode;
	onSchedulePayment?: () => void;
	totalAmount?: number;
}

const getPrimarysEmail = async (organizationId: number, customerId: number) => {
	const { data } = (await customersApi.getCustomerById(organizationId, customerId)) as ICustomerResponse;

	const email = await getCustomerPrimaryEmail(data);
	return email;
};

export const Header: FC<Props> = ({
	invoiceState,
	DiscountToggle,
	fetchScheduledPayments,
	scheduledPayments,
	redirectionToRefund,
	isPrimaryButton = true,
	tabs = [],
	onVoidSuccess,
	separateHeader,
	toggleBack,
	customerState,
	ChargeButton,
	onSchedulePayment,
	totalAmount,
}) => {
	const { printInvoice, handleDownloadPdf } = useInvoice();
	const { VoidInvoiceToggle, isVoidInvoiceShowing, VoidInvoiceModal } = useVoidInvoiceModal();
	const [skippedFirstRun, setSkipped] = useState(false);
	const { organizationId } = useOrganization();
	const [isLoading, setLoading] = useState(false);
	const { setToastNotification } = useNotification();
	const { ngNavigate } = useNavigation();
	const [email, setEmail] = useState(customerState.email);

	const [isNotesPopupOpen, setIsNotesPopupOpen] = useState(false);
	const { setErrorModal } = useErrorModal();
	const invoiceLabels = TranslationEn.customers.paymentsInvoices;
	const steps = invoiceLabels.header.steps;
	const partialPayment =
		invoiceState.paidAmount > invoiceState.totalFeesAmount && invoiceState.paidAmount < invoiceState.price;
	const isPublicInvoice = invoiceState.isPublic;

	const [isPaymentRequestShowing, paymentRequestToggle] = useToggle();
	const {
		privateNotes,
		hasSavedNotes,
		setHasSavedNotes,
		createDeleteNoteModal,
		toggleDeleteNoteModal,
		setNoteForDelete,
		noteToDelete,
	} = useNotes({
		isPublic: false,
	});
	const { isShowing: isRemoveFailShowing, toggle: removeFailToggle } = useModal();
	const { getQueryValue } = useQuery();

	const baseUrl = `${CUSTOMER_URL_ROOT}${URL_SEPARATOR}${customerState.id}`;
	const overviewUrl = `${baseUrl}${OVERVIEW_URL}`;
	const invoiceListUrl = `${baseUrl}${INVOICES_URL}`;
	const invoiceUrl = `${baseUrl}${INVOICES_URL}${URL_SEPARATOR}${invoiceState.id}${DETAILS_URL}`;

	const togglePrivateNotePopup = () => {
		setIsNotesPopupOpen(prevState => !prevState);
	};

	const handlePrintInvoice = () => {
		printInvoice(invoiceState.id, invoiceState.payingUserId).then();
	};

	useEffect(() => {
		if (customerState?.id && customerState?.organizationId) {
			getPrimarysEmail(customerState.organizationId, customerState.id)
				.then(email => setEmail(prevEmail => (email ? email : prevEmail)))
				.catch(err => {
					setErrorModal({ message: err.message });
				});
		}
	}, [customerState.id, customerState.organizationId]);

	const deleteNoteModal = createDeleteNoteModal(noteToDelete, togglePrivateNotePopup);

	const {
		noVoid,
		isVoidedLineItemExist,
		noRefund,
	}: { noVoid: boolean; isVoidedLineItemExist: boolean; noRefund: boolean } = useMemo(() => {
		const validLineItems = invoiceState.lineItems.filter(
			li => !li.isVoided && !li.isRefunded && li.productType !== 'tax' && li.price > 0
		);

		const isVoidedLineItemExist = invoiceState.lineItems.some(li => li.paymentStatus === OrderPaymentStatusEnum.VOID);
		const isRentalLineItemExist = invoiceState.lineItems.some(li => li.productType === ProductTypesEnum.RESERVATION);
		if (validLineItems?.length > 0) {
			validLineItems?.sort((a, b) => a.price - b.price);
			return {
				noVoid: invoiceState.price - invoiceState.paidAmount - validLineItems[0].displayTotalPriceWithTax < 0,
				isVoidedLineItemExist,
				noRefund: isRentalLineItemExist,
			};
		} else {
			return { noVoid: true, isVoidedLineItemExist, noRefund: isRentalLineItemExist };
		}
	}, [invoiceState]);

	const lineItemsToVoid: ILineItem[] = useMemo(() => {
		return invoiceState.lineItems.filter(
			li =>
				!li.isVoided &&
				!li.isRefunded &&
				li.productType !== ProductTypesEnum.TAX &&
				![OrderPaymentStatusEnum.VOID, OrderPaymentStatusEnum.REFUNDED].includes(li.paymentStatus)
		);
	}, [invoiceState]);

	const goToRefund = () => {
		if (noRefund) {
			removeFailToggle();
		} else {
			if (!redirectionToRefund.includes(ACTION_REFUND_URL)) {
				ngNavigate(
					EPath.CUSTOMERS,
					`${EPath.CUSTOMER}/${customerState.id}/${EPath.INVOICES}/${invoiceState.id}/${EPath.ACTION}/${EPath.REFUND}`
				);
			}
			if (isVoidInvoiceShowing) VoidInvoiceToggle();
		}
	};

	const handleDownloadInvoicePdf = () => {
		handleDownloadPdf(invoiceState.id, invoiceState.payingUserId).then();
	};

	const moreMenuActionsPaid = [
		{
			label: invoiceLabels.downloadInvoice,
			action: handleDownloadInvoicePdf,
		},
		{
			label: invoiceLabels.sendInvoice,
			action: paymentRequestToggle,
		},
	];

	const handleSchedulePayments = async () => {
		onSchedulePayment?.();
	};

	const moreMenuActionsUnpaid = [
		{
			label: invoiceLabels.schedulePayments,
			action: handleSchedulePayments,
			isDisabled: customerState.isAnonymous,
		},
		{
			label: invoiceLabels.sendPaymentRequest,
			action: paymentRequestToggle,
		},
		{
			label: invoiceLabels.addDiscountToInvoice,
			action: () => DiscountToggle(true),
		},
		{
			label: invoiceLabels.downloadAsPDF,
			action: handleDownloadInvoicePdf,
		},
	];

	const status = getInvoiceStatus(invoiceState.paymentStatus, invoiceState.isScheduled);

	const moreMenuActions = useMemo(() => {
		let menu = [
			{
				label: invoiceLabels.printInvoice,
				action: handlePrintInvoice,
			},
		];
		const isReservation = invoiceState.lineItems?.some(
			lineItem => lineItem.productType === ProductTypesEnum.RESERVATION
		);
		// Select which menu
		if (invoiceState.paymentStatus === PaymentStatusEnum.FULLY_PAID) {
			menu.push(...moreMenuActionsPaid);
		} else {
			menu.push(...moreMenuActionsUnpaid);
		}

		// Add void to menu if needed
		if (invoiceState.price - invoiceState.paidAmount > 0 && !isReservation)
			menu.push({
				label: invoiceLabels.voidMenu,
				action: VoidInvoiceToggle,
			});

		// Add refund if partial payment AND no reservations
		if (invoiceState.paymentStatus === PaymentStatusEnum.PARTIAL_PAYMENT && !isReservation) {
			return [
				{
					label: invoiceLabels.issueRefund,
					action: goToRefund,
				},
				...menu,
			];
		}
		if (customerState.name === 'Anonymous User' && customerState.email === null) {
			menu = menu.filter(opt => opt.label !== invoiceLabels.sendPaymentRequest);
		}
		return menu;
	}, [invoiceState]);

	useEffect(() => {
		if (!skippedFirstRun) {
			setSkipped(true);
		}
	}, []);

	const sendPaymentRequest = (email: string, memo?: string) => {
		const isPaid = invoiceState.paymentStatus === PaymentStatusEnum.FULLY_PAID;
		const body = {
			invoiceId: invoiceState.id,
			userId: invoiceState.payingUserId,
			sendToEmail: email,
			memo: memo ?? '',
		};
		setLoading(true);
		paymentApi.sendPaymentRequest(organizationId, isPaid, body).then(res => {
			setLoading(false);
			paymentRequestToggle();
			const { err } = res;
			if (err) {
				/**
				 * error can have different structures:
				 * 1. err: { message: 'some error text: the SQL query...'}
				 * 2. err: ['some error text'] //no message key
				 */
				const errorMessage = err?.message ? `${err.message.split(':')[0]}` : err;
				setErrorModal({ message: `${res.status}\n${errorMessage}` });
			} else if (res) {
				setToastNotification(invoiceLabels.paymentRequestSuccess, ENotificationType.success);
			}
		});
	};

	const sendPaymentRequestAsPublic = (email: string, memo?: string) => {
		const body = {
			organizationId: organizationId,
			userId: invoiceState.payingUserId,
			sendToEmail: email,
			memo: memo ?? '',
		};
		setLoading(true);
		paymentApi.setInvoiceToPublic(invoiceState.id, body).then(res => {
			setLoading(false);
			paymentRequestToggle();
			const { err } = res;
			if (err) {
				/**
				 * error can have different structures:
				 * 1. err: { message: 'some error text: the SQL query...'}
				 * 2. err: ['some error text'] //no message key
				 */
				const errorMessage = err?.message ? `${err.message.split(':')[0]}` : err;
				setErrorModal({ message: `${res.status}\n${errorMessage}` });
			} else if (res) {
				setToastNotification(invoiceLabels.setInvoiceToPublicSuccessMessage, ENotificationType.success);
				fetchScheduledPayments();
			}
		});
	};

	const handleSendRequestPayment = ({
		email,
		memo,
		isPublic = false,
	}: {
		email: string;
		memo?: string;
		isPublic?: boolean;
	}) => {
		if (isPublic) {
			sendPaymentRequestAsPublic(email, memo);
		} else {
			sendPaymentRequest(email, memo);
		}
	};

	useEffect(() => {
		const isOpenNotesSection = getQueryValue(UrlParamEnum.OPEN_SECTION) === OpenSectionEnum.PRIVATE_NOTES;
		setIsNotesPopupOpen(isOpenNotesSection);
	}, []);

	return (
		<div data-aid="singleInvoiceHeader" css={containerCss(separateHeader)}>
			<HeaderContainer isColumn withTabs={!separateHeader}>
				{!separateHeader && (
					<Breadcrumbs
						steps={[
							{ label: steps.customerList, to: CUSTOMER_LIST_URL },
							{
								label: `${Capitalize(customerState.lastName)}, ${Capitalize(customerState.firstName)}`,
								to: overviewUrl,
							},
							{
								label: steps.invoiceList,
								to: invoiceListUrl,
							},
							{
								label: steps.invoice(String(invoiceState.id)),
								to: invoiceUrl,
							},
						]}
					/>
				)}
				<div data-aid="singleInvoiceHeader-side" css={rowContainer(separateHeader)}>
					<SideContainer className="no-print">
						<div data-aid="singleInvoiceHeader-side-last" css={lastActiveCss}>
							<div data-aid="singleInvoiceHeader-status" css={flex}>
								<HeaderTitle>
									{invoiceLabels.invoiceNum} {invoiceState.id}
								</HeaderTitle>
								<Tag title={TranslationEn.customers.tags[status]} color={EStatusColorMapper[status]} />
								{isPublicInvoice && <PublicTag />}
							</div>
							<ClockLabel unsetHeight>
								{TranslationEn.customers.customerHeader.lastActivity}{' '}
								{dayjs(invoiceState.updatedAt).format('MM/DD/YYYY')}
							</ClockLabel>
						</div>
					</SideContainer>
					{separateHeader ? (
						<SideContainer>
							<div />
						</SideContainer>
					) : (
						<SideContainer className="no-print">
							{invoiceState.paymentStatus === PaymentStatusEnum.FULLY_PAID ? (
								<Fragment>
									{invoiceState.price !== 0 && (
										<Button
											data-aid="button-singleInvoiceHeader-isrefund"
											theme="secondary"
											sizer="S"
											css={marginRight}
											onClick={goToRefund}
										>
											{invoiceLabels.issueRefund}
										</Button>
									)}
								</Fragment>
							) : (
								// Charge button flow
								invoiceState.paidAmount !== invoiceState.price && ChargeButton
							)}
							<Popup
								trigger={isNotesPopupOpen}
								onOpenChange={setIsNotesPopupOpen}
								unsetWidth
								isTopLayer={false}
								isBackground
								arrow
								fullWidth={false}
								body={
									<PrivateNotes
										privateNotes={privateNotes}
										invoiceId={invoiceState.id}
										changeNoteIconHandler={setHasSavedNotes}
										documentType={EDocumentType.INVOICE}
										toggleDeleteModal={toggleDeleteNoteModal}
										setNoteForDelete={setNoteForDelete}
										onClosePopup={() => {
											setIsNotesPopupOpen(false);
										}}
									/>
								}
							>
								<Button data-aid="button-singleInvoiceHeader-note" sizer="XS" theme="basic">
									{hasSavedNotes ? (
										<BnIcon icon={Icons.note_filled} css={primaryColor} />
									) : (
										<BnIcon icon={Icons.note} />
									)}
								</Button>
							</Popup>
							{deleteNoteModal}
							{moreMenuActions?.length > 0 && (
								<div className="no-print">
									<MoreMenu actions={moreMenuActions} />
								</div>
							)}
						</SideContainer>
					)}
				</div>
				{tabs?.length > 0 && (
					<div data-aid="singleInvoiceHeader-tabs" className="no-print" css={TabsCss}>
						<Tabs tabs={tabs} />
					</div>
				)}
			</HeaderContainer>

			<ModalWindow isShowing={isPaymentRequestShowing} toggle={paymentRequestToggle} padding={0}>
				<PaymentRequestFlow
					initialEmail={email}
					amountToPay={
						totalAmount
							? totalAmount
							: invoiceState.paidAmount
								? invoiceState.price - invoiceState.paidAmount
								: invoiceState.price
					}
					handleSendRequestPayment={handleSendRequestPayment}
					onClose={paymentRequestToggle}
					isLoading={isLoading}
					customTitle={status === PaymentStatusEnum.FULLY_PAID && invoiceLabels.sendInvoice}
					isPublicInvoice={isPublicInvoice}
				/>
			</ModalWindow>
			<VoidInvoiceModal
				organizationId={organizationId}
				invoiceId={invoiceState.id}
				totalDue={invoiceState.price - invoiceState.paidAmount}
				toggle={VoidInvoiceToggle}
				onFinish={onVoidSuccess}
				onRefund={goToRefund}
				partialPayment={partialPayment}
				noVoid={noVoid}
				isVoidedLineItemExist={isVoidedLineItemExist}
				lineItems={lineItemsToVoid}
			/>
			<FailureModal
				isShowing={isRemoveFailShowing}
				toggle={removeFailToggle}
				title={invoiceLabels.noRefundAllowedError.title}
				message={invoiceLabels.noRefundAllowedError.message}
			/>
		</div>
	);
};
