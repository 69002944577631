import { useCallback, useMemo, useState } from 'react';
import { IPayingUser, IPaymentMethod } from '..';
import { roundWithDecimal } from '../../../lib/utils';
import { PaymentMethodTypeEnum } from '../../../types/entities/payment';
import { uniq } from 'lodash';
import { getAmountsByMethods } from '../utils/funcs';

const OPTIONAL_METHODS_ORDER = [
	PaymentMethodTypeEnum.CARD,
	PaymentMethodTypeEnum.CARD_ON_TERMINAL,
	PaymentMethodTypeEnum.ACH,
	PaymentMethodTypeEnum.CHECK,
	PaymentMethodTypeEnum.GIFT_CARD,
];

const sortPaymentMethods = (() => {
	// reverse the actual order to make the unspecified types go at the end of the list (indexOf === -1)
	const reversedList = OPTIONAL_METHODS_ORDER.reverse();

	return ({ type: typeA }: IPaymentMethod, { type: typeB }: IPaymentMethod) =>
		reversedList.indexOf(typeA) < reversedList.indexOf(typeB) ? 1 : -1;
})();

export const usePaymentMethodPicker = (invoiceOwnerId?: number, handleDisable?: (val: boolean) => void) => {
	const [priceRemaining, setPriceRemaining] = useState<number>(0);
	const [limitPerUser, setLimitPerUser] = useState<Map<number, number>>();
	const [priceRemainingPerUser, setPriceRemainingPerUser] = useState<Map<number, number>>();
	const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
	const [currentUser, setCurrentUser] = useState<number | undefined>();
	const [height, setHeight] = useState<{ collapsed: number; expanded: number } | undefined>();

	const labels = {
		amountTitle: 'Amount',
		selectTitle: 'Which payment method?',
		showMore: 'show more',
		showLess: 'show less',
		userResfund: `User`,
		totalLeft: 'Left: ',
		refundLarger: ' is available for refund on this payment method',
		refundLargerThanUser: ' is the refund limit for this user',
		refundExcceedsLimit: ' limit set for this user',
	};

	const recalcPriceRemaining = (totalPrice: number) => {
		if (totalPrice) {
			recalcPriceRemainingPerUser();
			let total = paymentMethods.reduce((sum, pm) => sum + (pm.selected && pm.refundAmount ? pm.refundAmount : 0), 0);
			const priceRemaining = roundWithDecimal(totalPrice - total);
			setPriceRemaining(priceRemaining);
		}
	};

	const recalcPriceRemainingPerUser = () => {
		const userIds: number[] = uniq(paymentMethods.map(pm => pm.payingUserId) as number[]);
		const priceRemainingPerUser = new Map<number, number>();
		let limitExceeded = false;
		for (const userId of userIds) {
			const userMethods = paymentMethods.filter(pm => pm.payingUserId === userId);
			const userLimit = limitPerUser?.get(userId) ?? 0;
			const userTotal = userMethods.reduce(
				(sum, pm) => sum + (pm.selected && pm.refundAmount ? pm.refundAmount : 0),
				0
			);
			const userRemaining = roundWithDecimal(userLimit - userTotal);
			if (userRemaining < 0) {
				limitExceeded = true;
			}
			priceRemainingPerUser.set(userId, userRemaining);
		}
		handleDisable?.(limitExceeded);
		setPriceRemainingPerUser(priceRemainingPerUser);
	};

	const handlePaymentSelect = (id: number, totalPrice = 0, showAmount = false, amount?: number) => {
		let currPaymentMethods = [...paymentMethods];
		const currPayment = currPaymentMethods.find(pm => pm.id === id);
		const currentRemainingPrice = priceRemainingPerUser?.get(currPayment?.payingUserId!) ?? 0;
		if (currPayment) {
			const userId = currPayment.payingUserId;
			if (amount) {
				currPayment.selected = true;
			} else {
				currPayment.selected = !currPayment.selected;
			}

			// Calculate the amounts
			if (totalPrice && showAmount) {
				// Check if previously selected
				if (!currPayment.selected) {
					currPayment.refundAmount = undefined;
				} else {
					if (amount) {
						currPayment.refundAmount = amount === 0 ? undefined : amount;
					} else {
						const priceAvailable =
							userId === invoiceOwnerId && currPayment.amount && currPayment.amount < priceRemaining
								? currPayment.amount
								: Math.min(priceRemaining, currentRemainingPrice);
						const refund = priceRemaining < 0 ? 0 : priceAvailable;
						currPayment.refundAmount = refund;
					}
				}
			} else {
				// Remove all others selected (basic only allows 1 method selected at a time)
				currPaymentMethods = currPaymentMethods.map(cpm => ({
					...cpm,
					selected: cpm.id !== currPayment.id ? false : cpm.selected,
				}));
			}
			setCurrentUser(Number(userId));
		}
		calculateHeights(paymentMethods);
		setPaymentMethods(currPaymentMethods);
	};

	const calcUsersLimits = useCallback(
		(paymentMethods: IPaymentMethod[]) => {
			const limitsByUsersMap: Map<number, number> = new Map<number, number>();
			const userIds = uniq(paymentMethods.map(pm => pm.payingUserId));

			for (const payingUserId of userIds) {
				const userMethods = paymentMethods.filter(
					pm => pm.payingUserId === payingUserId || pm.payingUser?.id === payingUserId
				);
				const methodsSum = userMethods?.reduce((sum, pm) => sum + (pm.amount ? pm.amount : 0), 0);
				limitsByUsersMap.set(payingUserId!, methodsSum);
			}
			setLimitPerUser(limitsByUsersMap);
		},
		[paymentMethods, invoiceOwnerId]
	);

	const buildPaymentMethods = (usedPaymentMethods: IPaymentMethod[], owner?: IPayingUser) => {
		const [balacneAmount, cashAmount, otherAmount]: number[] = getAmountsByMethods(usedPaymentMethods, [
			PaymentMethodTypeEnum.BALANCE,
			PaymentMethodTypeEnum.CASH,
			PaymentMethodTypeEnum.OTHER,
		]);

		// First 2 are always balance and cash
		let paymentMethods: IPaymentMethod[] = [
			{
				id: 0,
				type: PaymentMethodTypeEnum.BALANCE,
				payingUser: owner,
				payingUserId: owner?.id ?? Number(owner),
				amount: balacneAmount,
			},
			{
				id: 1,
				type: PaymentMethodTypeEnum.CASH,
				payingUser: owner,
				payingUserId: owner?.id ?? Number(owner),
				amount: cashAmount,
			},
		];
		const numberOfFixedMethods = paymentMethods.length;

		// Previously used payment methods with funds left on this invoice
		usedPaymentMethods
			.filter(
				method =>
					![PaymentMethodTypeEnum.BALANCE, PaymentMethodTypeEnum.CASH, PaymentMethodTypeEnum.OTHER].includes(
						method.type
					)
			)
			.sort(sortPaymentMethods)
			.forEach((method, idx) => paymentMethods.push({ ...method, id: numberOfFixedMethods + idx }));

		// Last is always other
		paymentMethods.push({
			id: paymentMethods.length,
			type: PaymentMethodTypeEnum.OTHER,
			payingUser: owner,
			payingUserId: owner?.id ?? (owner as unknown as number),
			amount: otherAmount,
		});
		calculateHeights(paymentMethods);
		setPaymentMethods(paymentMethods);
		calcUsersLimits(paymentMethods);
	};

	const calculateHeights = (paymentMethods: IPaymentMethod[]) => {
		let collapsed = 0;
		let expanded = 0;
		const ACH_INFO_HEIGHT = 40;

		// Calculate height
		for (let i = 0; i < paymentMethods.length; i++) {
			const rec = paymentMethods[i];
			if (rec.amount && rec.refundAmount && rec.amount < rec.refundAmount) {
				if (i < 3) collapsed += 70;
				expanded += 70;
			} else {
				if (i < 3) collapsed += 50;
				expanded += 50;
			}
		}

		if (
			paymentMethods.some(paymentMethod => paymentMethod.type == PaymentMethodTypeEnum.ACH && paymentMethod.selected)
		) {
			collapsed += ACH_INFO_HEIGHT;
			expanded += ACH_INFO_HEIGHT;
		}

		setHeight({ collapsed, expanded });
	};

	const currentLimit = useMemo(() => {
		return limitPerUser?.get(currentUser!) || 0;
	}, [currentUser, limitPerUser]);

	const currentRemainingPrice = useMemo(() => {
		return priceRemainingPerUser?.get(currentUser!) || 0;
	}, [currentUser, priceRemainingPerUser]);

	return {
		priceRemaining,
		paymentMethods,
		labels,
		height,
		setHeight,
		handlePaymentSelect,
		buildPaymentMethods,
		currentUser,
		currentLimit,
		currentRemainingPrice,
		recalcPriceRemaining,
	};
};
