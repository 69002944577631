<div data-aid="LeagueFilters" class="league-filters-wrapper bottom-divider">
	<div class="row">
		<div class="col-lg-2 cold-md-3 col-sm-4">
			<div class="input-group input-group--row">
				<label>SEARCH</label>
				<input data-aid="leagueFilters-input-search" type="text" class="input" placeholder="SEARCH" />
			</div>
		</div>
		<div class="col-lg-2 cold-md-3 col-sm-4">
			<div class="input-group input-group--row">
				<label>LEAGUE STATUS</label>
			</div>
		</div>
	</div>
</div>
