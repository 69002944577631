import { PaginationMetaDto } from '@bondsports/types';
import { useState, useRef, useCallback } from 'react';
import { FetchDataCallback, TFetchOptions } from '../types/fetchData';

export const useInfiniteScroll = <T extends unknown, F>({
	fetchCallback,
	initialItemsPerPage,
	fetchOptions,
	initialItems,
	initialMeta,
}: {
	fetchCallback: FetchDataCallback<T, F>;
	initialItemsPerPage?: number;
	fetchOptions?: TFetchOptions<T>;
	initialItems?: T[];
	initialMeta?: PaginationMetaDto;
}) => {
	const [items, setItems] = useState<T[]>(initialItems || []);
	const [meta, setMeta] = useState<PaginationMetaDto>(
		initialMeta || {
			totalItems: 0,
			itemsPerPage: initialItemsPerPage || 0,
			totalPages: 0,
			currentPage: 0,
		}
	);

	const initialHasMore = initialMeta ? initialMeta.currentPage < initialMeta.totalPages : true;
	const [hasMore, setHasMore] = useState(initialHasMore);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>('');
	const observer = useRef<IntersectionObserver | null>(null);

	const resetState = () => {
		setItems([]);
		setMeta({
			totalItems: 0,
			itemsPerPage: initialItemsPerPage || 0,
			totalPages: 0,
			currentPage: 0,
		});
		setHasMore(true);
	};

	const refetch = async (newFilters?: F) => {
		resetState();
		await loadItems(false, newFilters);
	};
	const loadItems = async (append = true, filters?: F) => {
		if (isLoading) return;

		setIsLoading(true);

		try {
			const nextPage = append ? meta.currentPage + 1 : 1;
			const response = await fetchCallback({
				page: nextPage,
				itemsPerPage: meta.itemsPerPage,
				filters,
			});

			if (!response) {
				return;
			}

			if ((response as any).err) {
				throw response;
			}

			fetchOptions?.onSuccess?.(response);
			setMeta({ ...response.meta, currentPage: nextPage });
			setHasMore(nextPage < response.meta.totalPages);
			setItems(append ? [...items, ...response.items] : response.items);
		} catch (error) {
			setHasMore(false);
			fetchOptions?.onError?.(error);
			setError(error);
		} finally {
			setIsLoading(false);
			fetchOptions?.onComplete?.();
		}
	};

	const lastItemElementRef = useCallback(
		(node: Element): void => {
			if (isLoading || !hasMore) {
				return;
			}

			if (observer?.current?.disconnect) {
				observer?.current?.disconnect();
			}

			observer.current = new IntersectionObserver(async entries => {
				const [entry] = entries;
				if (entry.isIntersecting && hasMore && !isLoading) {
					await loadItems(true);
				}
			});

			if (node) {
				observer?.current?.observe(node);
			}
		},
		[isLoading, hasMore, loadItems]
	);
	return { items, refetch, meta, hasMore, isLoading, error, lastItemElementRef, loadItems, resetState };
};
