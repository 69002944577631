import React, { Fragment } from 'react';
import * as dayjs from 'dayjs';
import { Field as FinalField } from 'react-final-form';
import { TranslationEn } from '@assets/i18n/en';
import { Icons, DateInput } from '@bondsports/utils';
import { EDateTimeFormats, EDurations } from '@app/react/types/times';
import { isDateInRange } from '@app/react/lib/dates';

export interface DateInputProps {
	placeholder?: string;
	label?: string;
	info?: string;
	helper?: string;
	helperContent?: string;
	sizer: 'M' | 'S';
	leftIcon?: Icons;
	rightIcon?: Icons;
	error?: string | undefined;
	setChange?: (val: string | number) => void;
	disableTrailing?: boolean;
	clear?: () => void;
	enablePast?: boolean;
	noLabel?: boolean;
	hideError?: boolean;
	notBefore?: string;
	isBirthday?: boolean;
	visualFormat?: string;
	timezone?: string;
	// colors?: IColors;
}

export interface IFormProps extends DateInputProps {
	name: string;
	onClear?: (val: string) => void;
	required?: boolean;
	enablePast?: boolean;
	isBirthday?: boolean;
	untilDate?: string;
	disabled?: boolean;
	noLabel?: boolean;
	style?: any;
	initialValue?: any;
	openOnDate?: Date | string;
	displayErrorBeforeVisited?: boolean;
	grayedOut?: boolean;
	notBeforeText?: string;
	notBeforeFieldName?: string;
}

interface IUseYupValidation {
	useyup?: boolean;
}

const FormDateInputWithYup = ({ onClear = (val: string) => ({}), ...props }: IFormProps) => {
	const clear = () => onClear(props.name);
	return (
		<FinalField name={props.name} displayEmpty>
			{({ input, meta }) => {
				const newProps = { ...props };

				return (
					<Fragment>
						<DateInput
							{...props}
							{...input}
							{...newProps}
							error={meta.error || props.error}
							clear={clear}
							displayErrorBeforeVisited={props.displayErrorBeforeVisited}
						/>
					</Fragment>
				);
			}}
		</FinalField>
	);
};

const OldFormDateInput = ({ onClear = (val: string) => ({}), ...props }: IFormProps) => {
	const clear = () => onClear(props.name);

	return (
		<FinalField
			name={props.name}
			validate={(val, allValues, fieldState) => {
				const errors = {};
				if (props.required) {
					if (!val) {
						errors[props.name] = 'required';
						return errors;
					}
				}
				if (props.untilDate && val) {
					if (!isDateInRange(val, props.untilDate)) {
						errors[props.name] = `${TranslationEn.forms.validation.untilDateError} ${dayjs(props.untilDate).format(
							EDateTimeFormats.DAY_FORMAT
						)}`;
						return errors;
					}
				}

				if (props.notBefore && props.notBeforeText && val) {
					if (
						dayjs(val).isBefore(
							dayjs(props.notBeforeFieldName ? allValues[props.notBeforeFieldName] : props.notBefore),
							EDurations.DAY
						)
					) {
						errors[props.name] = `${TranslationEn.forms.validation.notBeforeError} ${props.notBeforeText}`;
						return errors;
					}
				}
				if (!props.enablePast && fieldState.visited && val) {
					if (dayjs(val).isBefore(dayjs(), EDurations.DAY)) {
						errors[props.name] = TranslationEn.forms.validation.pastDateError;
						return errors;
					}
				}
				return undefined;
			}}
			displayEmpty={Boolean(props.initialValue)}
			initialValue={props.initialValue}
		>
			{({ input, meta }) => {
				return (
					<Fragment>
						<DateInput
							{...props}
							{...input}
							error={meta.error && meta.error[props.name]}
							clear={clear}
							onChange={e => {
								if (!dayjs(String(e)).isSame(dayjs(input?.value))) {
									input.onChange(e);
								}
							}}
						/>
					</Fragment>
				);
			}}
		</FinalField>
	);
};

export const FormDateInput = ({ onClear = (val: string) => ({}), ...props }: IFormProps & IUseYupValidation) => {
	return props.useyup ? (
		<FormDateInputWithYup {...props} onClear={onClear} />
	) : (
		<OldFormDateInput {...props} onClear={onClear} />
	);
};
