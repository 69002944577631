<table data-aid="SeasonInvitedTable" class="table table--has-checkbox">
	<thead>
		<tr>
			<td></td>
			<td>Name</td>
			<td>Email/Phone</td>
			<td class="color-brand-accent action-column">
				<a data-aid="seasonInvited-button-all" href="" (click)="onSendMultipleInvites.emit(true); $event.preventDefault()" *ngIf="showSendAllInvitesBtn"
					><i class="fa fa-envelope"></i>
					resend all
				</a>
				<a data-aid="seasonInvited-button-selected" href="" (click)="onSendMultipleInvites.emit(); $event.preventDefault()" *ngIf="!showSendAllInvitesBtn"
					><i class="fa fa-envelope"></i>
					send selected
				</a>
			</td>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let invitee of invitedList">
			<td>
				<div data-aid="seasonInvited-checkbox" class="checkbox">
					<input
						type="checkbox"
						class="styled-checkbox"
						[(ngModel)]="invitee.selected"
						(click)="inviteeSelected($event)"
					/>
					<label></label>
				</div>
			</td>
			<td class="bold">
				{{ invitee.name }}
			</td>
			<td>
				{{ invitee.email || invitee.phone }}
			</td>
			<td class="action-column">
				<div *ngIf="!invitee.isInviteResent">
					<a
						data-aid="seasonInvited-button-remove"
						href=""
						*ngIf="invitee.id"
						(click)="removeInvite(invitee.id); $event.preventDefault()"
						nzTooltipPlacement="right"
						style="font-size: 16px; display: inline-block; margin-right: 10px"
						nz-tooltip
						nzTooltipTitle="Remove invite"
					>
						<i class="fa fa-times-circle"></i>
					</a>
					<a
					    data-aid="seasonInvited-button-resend"
						href=""
						(click)="onPlayerCardResendInvite.emit(invitee); $event.preventDefault()"
						nzTooltipPlacement="right"
						style="font-size: 16px"
						nz-tooltip
						nzTooltipTitle="Resend invite"
					>
						<i class="fa fa-share"></i>
					</a>
				</div>
				<div data-aid="seasonInvited-button-is" *ngIf="invitee.isInviteResent" class="color-brand-success">
					<i class="fa fa-check-circle"></i> invite sent
				</div>
			</td>
		</tr>
	</tbody>
</table>
