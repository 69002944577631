<div data-aid="MatchResultsModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog rc-modal">
		<form class="form" novalidate [formGroup]="matchResultsForm">
			<div class="modal-content">
				<div class="modal-header modal-header--success">
					<button data-aid="button-MatchResultsModal-hide" type="button" class="close pull-right" (click)="modal.hide()" aria-label="Close">
						<i class="fa fa-times"></i>
					</button>
					<div class="modal-header__content">
						<i class="icon-rc-games-per-season"></i>
						<h5>MATCH DETAILS</h5>
					</div>
				</div>
				<div class="modal-body no-padding">
					<div class="match-participants-header">
						<div class="match-participant">
							<div class="participant-top">
								{{ match?.match?.participants[0]?.entity?.name }}
							</div>
							<div class="participant-bottom text-capitalize">
								{{ match?.startDate | momentDate: 'MMM D, YYYY':match?.timezone }}
							</div>
						</div>
						<div class="match-participant match-participant--divider">
							<div class="participant-top">
								<i class="icon-rc-double-flag"></i>
							</div>
							<div class="participant-bottom">
								{{ match?.startDate | momentDate: 'hh:mmA':match?.timezone }} -
								{{ match?.endDate | momentDate: 'hh:mmA':match?.timezone }}
							</div>
						</div>
						<div class="match-participant">
							<div class="participant-top">
								{{ match?.match?.participants[1]?.entity?.name }}
							</div>
							<div class="participant-bottom"><i class="icon-rc-location"></i> {{ match?.venueName }}</div>
						</div>
					</div>
					<div class="match-results-wrapper">
						<div class="match-result">
							<div
								class="ResultBox"
								[ngClass]="{
									'ResultBox--success-border':
										matchResultsForm.get('teamAScore').value > matchResultsForm.get('teamBScore').value,
									'ResultBox--danger-border':
										matchResultsForm.get('teamAScore').value < matchResultsForm.get('teamBScore').value
								}"
							>
								<input data-aid="leagueMatchResult-input-result" type="number" formControlName="teamAScore" min="0" max="999" required />
							</div>
						</div>
						<div class="match-result match-result--divider">
							<span> : </span>
						</div>
						<div class="match-result">
							<div
								class="ResultBox"
								[ngClass]="{
									'ResultBox--success-border':
										matchResultsForm.get('teamAScore').value < matchResultsForm.get('teamBScore').value,
									'ResultBox--danger-border':
										matchResultsForm.get('teamAScore').value > matchResultsForm.get('teamBScore').value
								}"
							>
								<input data-aid="leagueMatchResult-input-score" type="number" formControlName="teamBScore" min="0" max="999" required />
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer">
					<button data-aid="button-MatchResultsModal-edit" class="button button-empty pull-left left-action-button" (click)="editMatch(); modal.hide()">
						<i class="fa fa-pencil"></i> edit match details
					</button>
					<button data-aid="button-MatchResultsModal-cancel" class="button button-empty cancel-btn" (click)="modal.hide()">Cancel</button>
					<rc-loading-btn
						[text]="editMode ? 'SAVE CHANGES' : 'ADD SCORE'"
						[disabled]="!matchResultsForm.valid"
						(onClick)="onSubmit(matchResultsForm.value)"
						[loading]="loading"
						[classes]="['button', 'button-success', 'button-padded', 'submit-btn']"
					>
					</rc-loading-btn>
				</div>
			</div>
		</form>
	</div>
</div>
