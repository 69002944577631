import { useEffect, useState } from 'react';
import { useQuery } from './useQuery';
import { pageQueryTag, pageQuryDefaultValue } from '../lib/constants';

export const useTablePagination = () => {
	const { getQueryValue, resetQuery, setQuery } = useQuery();
	const [currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		const currentPage = getQueryValue(pageQueryTag);
		if (currentPage) {
			setCurrentPage(Number(currentPage) - 1);
		}
	}, [getQueryValue]);
	const initiateFirstPage = () => {
		setQuery(pageQueryTag, pageQuryDefaultValue);
	}
	const removeFromQuery = () => {
		resetQuery();
	}
	return { currentPage: currentPage, nextPage: currentPage + 1, initiateFirstPage, removeFromQuery };
};
