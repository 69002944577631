/** @jsx jsx */
import { Fragment, useEffect, useRef, useState } from 'react';
import { jsx } from '@emotion/react';
import {
	ModalFooterWithTwoButtons,
	ModalHeaderWithPrimaryAndSecondaryLabels,
	ModalLayout,
	ModalWindow,
	capitalizeFirstLetter,
} from '@bondsports/utils';
import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { AttendanceEntityTypeEnum } from '@bondsports/types';
import { TranslationEn } from '@assets/i18n/en';
import { ItemsPerPageCalculator, Table } from '@app/react/components/shared/NewTable';
import { AlignType } from '@app/react/components/shared/NewTable/types';
import { useErrorModal } from '@app/react/hooks/useErrorModal';
import { useNotification } from '@app/react/hooks/useNotification';
import { ENotificationType } from '@app/react/stores/baseStore';
import { useAttendance } from '@app/react/hooks/useAttendance';
import { useActivities } from '@app/react/hooks/useActivities';
import { alternativeModalCss } from './styles';
import { ICustomer } from '@app/react/types/customers';

const labels = TranslationEn.customers.customerHeader.customerCheckinModal;

const CHECKIN_MODAL_COLUMNS = [
	{
		id: 'name',
		label: labels.table.name,
		type: 'string',
		styling: { align: AlignType.LEFT },
	},
	{
		id: 'type',
		label: labels.table.type,
		type: 'string',
		styling: { align: AlignType.LEFT },
	},
];

interface IProps {
	customerState: ICustomer;
	userId: number;
	organizationId: number;
	toggle: () => void;
}

export const CustomerCheckInModal = ({ customerState, userId, organizationId, toggle }: IProps) => {
	const tableRef = useRef<HTMLDivElement>(null);
	const { maxHeight } = ItemsPerPageCalculator(tableRef, true);
	const [selected, setSelected] = useState<number>(0);
	const [page, setPage] = useState<number>(0);

	const { createAttendance, createAttendanceError, createAttendanceSuccess, isCreatingAttendance } = useAttendance();
	const { fetchAvailableActivities, isFetchingActivities, availableActivities, activitiesMeta, fetchActivitiesError } =
		useActivities();

	const { setErrorModal } = useErrorModal();
	const { setToastNotification } = useNotification();

	const rows = availableActivities.map(item => ({
		id: item.activityId,
		name: item.activityName,
		type: capitalizeFirstLetter(item.activityType),
	}));

	useEffect(() => {
		fetchAvailableActivities(userId, organizationId, page + 1, 4);
	}, [page, userId, organizationId]);

	useEffect(() => {
		if (createAttendanceSuccess) {
			setToastNotification(labels.successMessage(`${customerState.firstName} ${customerState.lastName}`), ENotificationType.success);
			toggle();
		}
		if (createAttendanceError) {
			setErrorModal({ message: createAttendanceError });
		}
	}, [createAttendanceSuccess, createAttendanceError]);

	const onSubmit = (entityId?: number) => {
		if (isCreatingAttendance) {
			return;
		}
		createAttendance(organizationId, userId, {
			entityId: entityId ?? selected,
			entityType: AttendanceEntityTypeEnum.MEMBERSHIP,
		});
	};

	if (activitiesMeta?.totalItems === 1) {
		onSubmit(rows[0].id);

		return <Fragment />;
	}

	return (
		<ModalWindow isShowing={!isFetchingActivities || activitiesMeta?.totalItems > 1} toggle={toggle}>
			<ModalLayout alternativeCss={alternativeModalCss}>
				<ModalLayout.Header data-aid="customer-checkin-modal-header">
					<ModalHeaderWithPrimaryAndSecondaryLabels labels={{ title: labels.title, subtitle: labels.description }} />
				</ModalLayout.Header>
				<ModalLayout.Content data-aid="customer-checkin-modal-content">
					<Table
						meta={activitiesMeta}
						page={page}
						rows={rows}
						columns={CHECKIN_MODAL_COLUMNS}
						subject={labels.subject}
						maxHeight={maxHeight}
						selectedArr={[selected].filter(Boolean)}
						onSelect={setSelected}
						emptyTableMessage={labels.emptyTableMessage}
						isLoading={isFetchingActivities}
						bottomBorder={rows.length > 0}
						isRowsError={!!fetchActivitiesError}
						rowsErrorMessage={fetchActivitiesError}
						isSelectRow
						isRadio
						ssr
						pagination
						onPageChange={setPage}
					/>
				</ModalLayout.Content>
				<ModalLayout.Footer data-aid="customer-checkin-modal-footer">
					<ModalFooterWithTwoButtons
						isLoading={isCreatingAttendance}
						labels={{
							cancel: labels.cancelButtonText,
							next: labels.actionButtonText,
						}}
						nextDisabled={!selected}
						onNext={() => onSubmit()}
						onCancel={toggle}
					/>
				</ModalLayout.Footer>
			</ModalLayout>
		</ModalWindow>
	);
};
