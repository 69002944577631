<div data-aid="TournamentEditPage" class="row" [formGroup]="form">
	<div class="col-md-12" [formGroupName]="groupName">
		<div class="form__section">
			<div class="form__section__header">ABOUT</div>
			<div class="form__section__helper">Custom your season information</div>

			<div class="input-group">
				<label for="">
					<i class="icon-rc-calendar"></i> TOURNAMENT NAME
					<span class="pull-right">{{ form.get(groupName).get('name').value?.length }}/60</span>
				</label>

				<div class="input-wrapper">
					<span
						class="rc-tooltip"
						nzTooltipPlacement="right"
						nz-tooltip
						nzTooltipTitle='If you are running multiple seasons of the same league at the same time, each on a different day of the week or at a different facility, you can create the two concurrent seasons. See image below. If there is just one season at a time in the league, simply name the season the respective time period and year. "Spring 2017" or "Late Fall 2017."'
					>
						<i class="fa fa-info"></i>
					</span>
					<input data-aid="tournamentEdit-input-name" type="text" class="input" formControlName="name" maxlength="60" />
				</div>
			</div>
			<div class="input-group">
				<label for="">
					<i class="icon-rc-long-des"></i> DESCRIPTION
					<span class="pull-right">{{ form.get(groupName).get('description_length').value?.length }}/2000</span>
				</label>
				<div data-aid="tournamentEdit-input-long" class="input-wrapper">
					<span
						class="rc-tooltip"
						nzTooltipPlacement="right"
						nz-tooltip
						nzTooltipTitle="Only include information not provided in the league description.
                 You may want to write: what weeks games are played/not played,
                 how many times a week a team will play, season specific rules,
                 the location of post game drinks, and any other useful information."
					>
						<i class="fa fa-info"></i>
					</span>

					<rc-rich-text-wrapper
						[initialValue]="form.get(this.groupName).get('description').value || ''"
						max="2000"
						(handleChange)="descInputChanged($event)"
						(setDescLength)="descInputLengthChanged($event)"
					></rc-rich-text-wrapper>
				</div>
			</div>
			<div data-aid="tournamentEdit-input-short" class="input-group">
				<label for="">
					<i class="icon-rc-long-des"></i> SHORT DESCRIPTION
					<span class="pull-right">{{ form.get(groupName).get('shortDescription_length').value?.length }}/2000</span>
				</label>
				<div class="input-wrapper">
					<rc-rich-text-wrapper
						[initialValue]="form.get(this.groupName).get('shortDescription').value || ''"
						max="2000"
						(handleChange)="descShortInputChanged($event)"
						(setDescLength)="descShortInputLengthChanged($event)"
					></rc-rich-text-wrapper>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div data-aid="tournamentEdit-input-location" class="input-group">
						<label for=""> <i class="icon-rc-location"></i> LOCATION </label>

						<div class="input-wrapper">
							<rc-location-search-input
								[timeZoneSelector]="true"
								[timezone]="form.get(groupName).get('timezone').value"
								(onSelected)="venueSelected($event)"
								formControlName="venueAddress"
								[placeholder]="'Enter city, neighborhood, borough or full address.'"
							>
							</rc-location-search-input>
						</div>
					</div>
				</div>
			</div>

			<div class="input-group">
				<label for=""> SPORT TYPE </label>

				<div data-aid="tournamentEdit-picker-sports" class="input-wrapper">
					<rc-quick-sport-picker formControlName="sport" [singleSelect]="true"></rc-quick-sport-picker>
				</div>
			</div>
		</div>

		<div class="form__section">
			<div class="form__section__header">TOURNAMENT LOGO & COVER</div>

			<div data-aid="tournamentEdit-input-logo" class="row mg-top-20">
				<div class="col-md-8 mg-bottom-20">
					<rc-image-uploader
						(onFileAdded)="mainImageAdded($event)"
						[text]="
							'Upload an image that will be used as the opening image for your tournament page, Image should be at least 350x230px and 1MB max size'
						"
					>
					</rc-image-uploader>
				</div>
				<div class="col-md-4 text-center">
					<div
						class="BoxedDropZone round-image-uploader image-wrapper image--round image--shadow"
						[rcBackgroundImage]="logoImage"
						(click)="logoImageUploader.click()"
					>
						<span *ngIf="!logoImage"
							>ADD <br />
							LOGO</span
						>

						<div class="BoxedDropZone__preview__action" *ngIf="logoImage">
							<i class="fa fa-camera"></i>
						</div>
					</div>

					<input data-aid="file" type="file" ng2FileSelect [uploader]="logoUploader" #logoImageUploader style="display: none" />
				</div>
			</div>
		</div>

		<div class="form__section">
			<div class="form__section__header">TOURNAMENT SETTINGS</div>
			<div class="form__section__helper">These settings will consolidate all relevant tournament events</div>

			<div class="input-group">
				<label for=""> <i class="icon-rc-person-icon"></i> AGE & GENDER </label>

				<div class="input-wrapper">
					<div class="row">
						<div data-aid="tournamentEdit-picker-age" class="col-md-6">
							<rc-range-picker formControlName="ageRange"></rc-range-picker>
						</div>
						<div data-aid="tournamentEdit-picker-gender" class="col-md-6">
							<rc-gender-picker formControlName="gender"></rc-gender-picker>
						</div>
					</div>
				</div>
			</div>
			<div class="input-group">
				<label for=""> <i class="icon-rc-star-icon"></i> LEVEL OF PLAY </label>

				<div data-aid="tournamentEdit-levelOfPlay" class="input-wrapper">
					<rc-level-of-play-selector formControlName="levelOfPlay"></rc-level-of-play-selector>
				</div>
			</div>
		</div>

		<div class="form__section">
			<div class="form__section__header">GAME DURATION</div>
			<div class="input-group game-duration-input">
				<div class="input-wrapper">
					<input data-aid="tournamentEdit-input-duration" type="number" class="input" formControlName="gameDuration" maxlength="500" />
					Min
				</div>
			</div>
		</div>
	</div>
</div>
