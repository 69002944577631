<div data-aid="TeamPlayerInviteModal" bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog rc-modal">
    <rc-team-player-invite-customer *ngIf="isCustomerInviteStep()" [inviteForm]="inviteForm"
      [currentTeamId]="currentTeamId" [teams]="teams" [loading]="loading" (hide)="modal.hide()"
      (submit)="submit($event)"></rc-team-player-invite-customer>

    <rc-team-player-select-invite-type *ngIf="isSelectionStep()" (hide)="modal.hide()"
      (submit)="submitStep($event)"></rc-team-player-select-invite-type>

    <rc-team-player-customer-search (select)="onCustomerSelect($event)" *ngIf="isCustomerSearchStep()"
      (hide)="modal.hide()" [loading]="loading"></rc-team-player-customer-search>
  </div>
</div>