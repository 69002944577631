<div data-aid="RangeAgePicker" class="age-picker-container" *ngIf="initLoaded">
	<div data-aid="rangeAge-select" class="age-picker-select">
		<select [value]="isMonth" (change)="onOptionsSelected($event.target.value)">
			<option data-aid="option-months" [value]="true">Months</option>
			<option data-aid="option-years" [value]="false">Years</option>
		</select>
	</div>
	<div class="age-picker-age">
		<button data-aid="button-RangeAgePicker-dec" [disabled]="fromNumber <= 1" (click)="decrement()">-</button>
		<input data-aid="rangeAge-input-from" type="number" [value]="fromNumber" (input)="setMinVal($event.target.value)" />
		<button data-aid="button-RangeAgePicker" [disabled]="toNumber <= fromNumber" (click)="increment()">+</button>
	</div>
	<div class="age-picker-age">
		<button data-aid="button-RangeAgePicker-decmax" [disabled]="toNumber <= fromNumber" (click)="decrement_max()">-</button>
		<input data-aid="rangeAge-input-to" type="number" [value]="toNumber" (input)="setMaxVal($event.target.value)" />
		<button data-aid="button-RangeAgePicker-inc" [disabled]="toNumber >= 100" (click)="increment_max()">+</button>
	</div>
</div>
