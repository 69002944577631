<div data-aid="SeasonTeamsPage" style="position: relative; height: 100%; overflow: hidden">
	<div class="season-teams-page-wrapper" style="overflow: auto; height: 100%; padding-top: 10px" #autoscroll>
		<div class="row">
			<div class="col-md-12">
				<div class="row">
					<div
						class="col-md-6"
						*ngIf="
							(seasonTeams?.length || teamsPoolParticipants?.length || freeAgentPoolParticipants?.length) &&
							!season?.playoffType
						"
					>
						<h3 class="sub-title sub-title--assertive no-mg-top">REGISTRATIONS</h3>

						<div class="tabs__navigation">
							<ul class="no-style list list--inline">
								<li [ngClass]="{ active: selectedTeamsNavigation === 'freeAgents' }">
									<a href="" (click)="changeSelectedNavigation('freeAgents'); $event.preventDefault()">FREE AGENTS</a>
								</li>
								<li [ngClass]="{ active: selectedTeamsNavigation === 'teams' }">
									<a href="" (click)="changeSelectedNavigation('teams'); $event.preventDefault()">TEAMS</a>
								</li>
								<li [ngClass]="{ active: selectedTeamsNavigation === 'invited' }">
									<a href="" (click)="changeSelectedNavigation('invited'); $event.preventDefault()"
										>INVITED BY LEAGUE</a
									>
								</li>
								<li [ngClass]="{ active: selectedTeamsNavigation === 'archived' }">
									<a href="" (click)="changeSelectedNavigation('archived'); $event.preventDefault()">ARCHIVED</a>
								</li>
							</ul>
						</div>

            <div class="tabs__content">
              <div *ngIf="loadingSeasonPool">
                <loaders-css [loader]="'ball-pulse'" [loaderClass]="'rc-loader-assertive'"></loaders-css>
              </div>

							<div *ngIf="selectedTeamsNavigation === 'archived'">
								<div class="row">
									<div class="col-md-3" *ngFor="let member of archivedPlayerEntities">
										<rc-player-card
											[hideTopControls]="true"
											(onMoreDetailsClick)="
												playerInfoModal.showModal(
													member.userId,
													undefined,
													member.orderId,
													member.remainingAmount,
													member.paymentStatus
												)
											"
											[player]="member"
										>
										</rc-player-card>
									</div>
								</div>
								<div class="row" style="min-height: 100px">
									<div class="col-md-6 col-sm-12" *ngFor="let teamParticipant of archivedTeamEntities">
										<rc-pool-team-card
											(onRestoreClicked)="restoreTeamPool($event)"
											[restoreToPoolMode]="true"
											[team]="teamParticipant.teamEntity"
										>
										</rc-pool-team-card>
									</div>
								</div>
							</div>

							<rc-season-invited-table
								(onRemoveInvite)="inviteRemoved($event)"
								*ngIf="selectedTeamsNavigation === 'invited'"
								[invitedList]="invitedList"
								(onSendMultipleInvites)="sendMultipleInvites($event)"
								(onPlayerCardResendInvite)="onPlayerCardResendInvite($event)"
							>
							</rc-season-invited-table>

							<div *ngIf="selectedTeamsNavigation === 'freeAgents' && !loadingSeasonPool">
								<div
									class="row"
									style="min-height: 600px"
									id="freeAgentsPool"
									[dragula]="'members-bag'"
									[dragulaModel]="freeAgentPoolParticipants"
								>
									<div class="row no-drag" *ngIf="!freeAgentPoolParticipants?.length">
										<div class="col-md-12">
											<div class="EmptyStateMessage" style="margin-top: 40px">
												<i class="icon-rc-like"></i>
												<h5>GREAT JOB!</h5>
												<p>You’ve assigned all the free agents, If your roster is ready to publish… go for it!</p>
											</div>
										</div>
									</div>
									<div
										class="col-md-3"
										*ngFor="let member of freeAgentPoolParticipants"
										id="agentPoolItem-{{ member.userId }}"
									>
										<rc-player-card
											(onMoreDetailsClick)="
												playerInfoModal.showModal(
													member.userId,
													undefined,
													member.orderId,
													member.remainingAmount,
													member.paymentStatus
												)
											"
											(onMovePlayerClick)="movePlayerToSeasonModalOpen(season, $event.player)"
											[player]="member"
											[draggable]="true"
										>
										</rc-player-card>
									</div>
								</div>
							</div>

							<div *ngIf="selectedTeamsNavigation === 'teams' && !loadingSeasonPool">
								<div class="row" style="min-height: 100px">
									<div
										class="col-md-6 col-sm-12"
										*ngFor="let teamParticipant of teamsPoolParticipants"
										id="teamPoolItem-{{ teamParticipant.teamEntity?.id }}"
									>
										<rc-pool-team-card
											(onAssignedClicked)="assignTeamToSeason($event)"
											[team]="teamParticipant.teamEntity"
										>
										</rc-pool-team-card>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row" *ngIf="season">
							<div class="col-md-12 title-button-group">
								<h3 class="sub-title sub-title--assertive no-mg-top pull-left">
									TEAMS ({{ seasonTeamsVM?.length || 0 }})
								</h3>
								<a
									href=""
									class="button button-assertive--inverted button-bold pull-right"
									*ngIf="!season?.playoffType"
									(click)="openCreateTeamModal(); $event.preventDefault()"
								>
									CREATE NEW TEAM
								</a>
								<a
									href=""
									class="button button-assertive--inverted button-bold pull-right"
									style="margin-right: 10px"
									*ngIf="!season?.playoffType && !season?.tournamentType"
									(click)="createDivisionModal.showModal(); $event.preventDefault()"
								>
									CREATE DIVISIONS
								</a>
								<a
									href=""
									class="button button-assertive--inverted button-bold pull-right"
									style="margin-right: 10px"
									(click)="showUploadRosterModal(); $event.preventDefault()"
								>
									UPLOAD ROSTERS
								</a>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12" *ngIf="!seasonTeams?.length">
								<div class="box-item box--shadow box-item--empty-results">
									<div class="box-item--empty-results__icon">
										<i class="icon-rc-teams-icon"></i>
									</div>
									<div class="box-item--empty-results__text text-center">
										set up awesome teams and <br />get the ball rollin'
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div
									class="teams-list-wrapper"
									style="padding-right: 10px"
									*ngIf="seasonTeamsVM?.length"
									[dragula]="'teams-bag'"
								>
									<div
										class="box-item box--padding box--collapsable mg-bottom-20"
										id="teamBox-{{ seasonTeam.id }}"
										*ngFor="let seasonTeam of getDivisionTeams(null)"
										[ngClass]="{
											'box--collapsable-active box--border': currentVisibleCard[seasonTeam.id],
											'box--shadow-small': currentVisibleCard[seasonTeam.id]
										}"
									>
										<div
											class="box-header padding-bottom-20"
											id="teamsPoolWrapper-{{ seasonTeam.team?.id }}"
											[dragula]="'members-bag'"
											[dragulaModel]="seasonTeam.members"
											(click)="openTeamCard(seasonTeam.id)"
										>
											<div class="no-drag box-title text-uppercase" style="position: relative">
												<i class="icon-rc-teams-icon color-brand-accent"></i> {{ seasonTeam.team.name }}

												<ng-template #popTemplate>
													<div class="PopupMenuWrapper">
														<ul>
															<li *ngIf="seasonTeam.team?.creatorType !== 'user'">
																<a
																	href=""
																	(click)="
																		editTeamModalOpen(seasonTeam);
																		$event.preventDefault();
																		$event.stopPropagation();
																		pop.hide()
																	"
																	><i class="icon-rc-teams-icon"></i> Edit Team Details</a
																>
															</li>
															<li
																*ngIf="
																	season?.rosterStatus === RCSeasonRosterStatusEnum.PUBLISHED &&
																	seasonTeam?.team?.creatorType !== 'user'
																"
															>
																<a
																	href=""
																	(click)="
																		teamInviteModal.showModal(seasonTeam.team.id); $event.preventDefault(); pop.hide()
																	"
																	><i class="icon-rc-mail"></i> Invite To Team</a
																>
															</li>
															<li *ngIf="seasonTeam?.team?.creatorType !== 'user'">
																<a
																	href=""
																	(click)="
																		teamConstraintModal.showModal(seasonTeam); $event.preventDefault(); pop.hide()
																	"
																	><i class="icon-rc-calendar"></i> Add Constraints</a
																>
															</li>
															<li *ngIf="seasonTeam.team?.creatorType !== 'user'">
																<a
																	href=""
																	(click)="
																		moveTeamtoSeasonModalOpen(season, seasonTeam);
																		$event.preventDefault();
																		$event.stopPropagation();
																		pop.hide()
																	"
																	><i class="icon-rc-teams-icon"></i> Move team to season</a
																>
															</li>
															<li class="text-center">
																<a href="" (click)="$event.preventDefault(); pop.hide()">Close Menu</a>
															</li>
														</ul>
													</div>
												</ng-template>
												<nz-button
													class="pull-right more-options-button"
													(click)="$event.stopPropagation()"
													nz-tooltip
													[nzTooltipTitle]="popTemplate"
													nzTooltipColor="#fff"
													nzTooltipPlacement="bottom"
												>
													<i class="icon-rc-nav-more"></i>
												</nz-button>
												<span
													class="season-team-label pull-right"
													customClass="rc-tooltip-white"
													nz-tooltip
													[nzTooltipTitle]="'Accepting Free Agents'"
													nzTooltipPlacement="bottom"
													*ngIf="seasonTeam.team?.creatorType !== 'user'"
												>
													<i class="icon-rc-person-icon"></i>
												</span>
											</div>
										</div>
										<div class="box-content">
											<div class="row">
												<div class="col-md-12">
													<div
														class="row"
														id="teamsPoolWrapper-{{ seasonTeam.team?.id }}"
														[dragula]="'members-bag'"
														[dragulaModel]="seasonTeam.members"
													>
														<div
															class="col-md-3 col-sm-4 col-lg-3 mg-bottom-20 bloc--inner"
															*ngFor="let member of seasonTeam.members"
															[ngClass]="{ 'no-drag': !member.userId || member.captain || !member.canUnassign }"
															id="teamMemberCard-{{ member.userId }}"
														>
															<rc-player-card
																[player]="member"
                                                                [team]="seasonTeam"
																(onMoreDetailsClick)="
																	playerInfoModal.showModal(
																		member.userId,
																		undefined,
																		member.orderId,
																		member.remainingAmount,
																		member.paymentStatus
																	)
																"
                                                                (onRemoveFromTeam)="showRemoveUserFromTeamModal(seasonTeam.team.id, member)"
																(onInviteSend)="onPlayerCardResendInvite($event)"
															>
															</rc-player-card>
														</div>
														<div
															class="col-md-3 col-sm-4 col-lg-3 no-drag"
															*ngIf="
																season?.rosterStatus === RCSeasonRosterStatusEnum.PUBLISHED &&
																seasonTeam?.team?.creatorType !== 'user'
															"
														>
															<div
																class="empty-player-card text-center"
																style="margin-bottom: 15px"
																(click)="teamInviteModal.showModal(seasonTeam.team.id)"
															>
																<div class="inner-content">
																	<i class="fa fa-plus"></i>
																	Add Team <br />
																	Member
																</div>
															</div>
														</div>
														<div
															class="col-md-3 col-sm-4 col-lg-3 no-drag"
															*ngIf="
																season?.rosterStatus === RCSeasonRosterStatusEnum.DRAFT &&
																seasonTeam?.team?.creatorType !== 'user'
															"
														>
															<div
																class="empty-player-card text-center"
																style="margin-bottom: 15px"
																nz-tooltip
																[nzTooltipTitle]="
																	'Sorry, we can\'t let you do that... sending an invite to an unpublished roster would be weird right? So you\'re not allowed to invite players to the teams before publishing the roster... you can only add players to teams from the registration pool'
																"
															>
																<div class="inner-content">
																	<i class="fa fa-info-circle"></i>
																	Add Player
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div
									class="DivisionTeamsBlock"
									[ngClass]="{ 'DivisionTeamsBlock--style-disabled': !season?.multipleDivision }"
									*ngFor="let division of season?.seasonDivisions"
									[style.border]="'1px solid ' + division.color"
								>
									<div
										class="DivisionTeamsBlock__header no-drag"
										[ngClass]="{ 'no-drag-to': currentVisibleDivisionCard[division.id] }"
										(click)="toggleDivisionCard(division.id)"
										[dragula]="'teams-bag'"
										id="divisionBlock-{{ division.id }}"
										[style.backgroundColor]="division.color"
									>
										<b>{{ division.name }}</b> | {{ getDivisionTeams(division.id).length }} TEAMS

										<span class="pull-right no-drag" style="position: relative; top: -3px">
											<i
												class="fa"
												[ngClass]="currentVisibleDivisionCard[division.id] ? 'fa-chevron-up' : 'fa-chevron-down'"
											></i>
										</span>
									</div>
									<div
										class="DivisionTeamsBlock__content"
										[dragula]="'teams-bag'"
										id="divisionBlock-{{ division.id }}"
										*ngIf="currentVisibleDivisionCard[division.id]"
									>
										<div
											id="teamBox-{{ seasonTeam.id }}"
											class="box-item box--padding box--collapsable mg-bottom-20"
											*ngFor="let seasonTeam of getDivisionTeams(division.id); let i = index"
											[ngClass]="{
												'box--collapsable-active box--border': currentVisibleCard[seasonTeam.id],
												'box--shadow-small': currentVisibleCard[seasonTeam.id]
											}"
										>
											<div
												class="box-header padding-bottom-20"
												id="teamsPoolWrapper-{{ seasonTeam.team?.id }}"
												[dragula]="'members-bag'"
												[dragulaModel]="seasonTeam.members"
												(click)="openTeamCard(seasonTeam.id)"
											>
												<div class="no-drag box-title text-uppercase" style="position: relative">
													<span class="drag-left-handle" *ngIf="divisionsEnabled && !currentVisibleCard[seasonTeam.id]">
														<i class="icon-rc-drag-points"></i>
													</span>
													<i class="icon-rc-teams-icon color-brand-accent"></i> {{ seasonTeam.team.name }}

													<ng-template #popTemplate>
														<div class="PopupMenuWrapper">
															<ul>
																<li *ngIf="seasonTeam.team?.creatorType !== 'user'">
																	<a
																		href=""
																		(click)="
																			editTeamModalOpen(seasonTeam);
																			$event.preventDefault();
																			$event.stopPropagation();
																			pop.hide()
																		"
																		><i class="icon-rc-teams-icon"></i> Edit Team Details</a
																	>
																</li>
																<li
																	*ngIf="
																		season?.rosterStatus === RCSeasonRosterStatusEnum.PUBLISHED &&
																		seasonTeam?.team?.creatorType !== 'user'
																	"
																>
																	<a
																		href=""
																		(click)="
																			teamInviteModal.showModal(seasonTeam.team.id); $event.preventDefault(); pop.hide()
																		"
																		><i class="icon-rc-mail"></i> Invite To Team</a
																	>
																</li>
																<li>
																	<a
																		href=""
																		(click)="
																			teamConstraintModal.showModal(seasonTeam); $event.preventDefault(); pop.hide()
																		"
																		><i class="icon-rc-calendar"></i> Add Constraints</a
																	>
																</li>
																<li>
																	<a
																		href=""
																		(click)="promptTeamRemove(seasonTeam); $event.preventDefault(); pop.hide()"
																	>
																		<i class="icon-rc-circle-close"></i> Remove Team
																	</a>
																</li>
																<li>
																	<a
																		href=""
																		(click)="
																			moveTeamtoSeasonModalOpen(season, seasonTeam);
																			$event.preventDefault();
																			$event.stopPropagation();
																			pop.hide()
																		"
																		><i class="icon-rc-teams-icon"></i> Move team to season</a
																	>
																</li>
																<li class="text-center">
																	<a href="" (click)="$event.preventDefault(); pop.hide()">Close Menu</a>
																</li>
															</ul>
														</div>
													</ng-template>

													<nz-button
														class="pull-right more-options-button"
														(click)="$event.stopPropagation()"
														nz-tooltip
														[nzTooltipTitle]="popTemplate"
														nzTooltipColor="#fff"
														nzTooltipPlacement="bottom"
													>
														<i class="icon-rc-nav-more"></i>
													</nz-button>
													<span
														class="season-team-label pull-right"
														customClass="rc-tooltip-white"
														nz-tooltip
														[nzTooltipTitle]="'Accepting Free Agents'"
														nzTooltipPlacement="bottom"
														*ngIf="seasonTeam.team?.creatorType !== 'user'"
													>
														<i class="icon-rc-person-icon"></i>
													</span>
												</div>
											</div>
											<div class="box-content">
												<div class="row">
													<div class="col-md-12">
														<div
															class="row"
															id="teamsPoolWrapper-{{ seasonTeam.team?.id }}"
															[dragula]="'members-bag'"
															[dragulaModel]="seasonTeam.members"
														>
															<div
																class="col-md-3 col-sm-4 col-lg-3 mg-bottom-20 bloc--inner"
																*ngFor="let member of seasonTeam.members"
																[ngClass]="{ 'no-drag': !member.userId || member.captain || !member.canUnassign }"
																id="teamMemberCard-{{ member.userId }}"
															>
																<rc-player-card
																	[player]="member"
																	[allowForceRemove]="allowForceRemoveTeamMember(seasonTeam)"
																	(onMoreDetailsClick)="
																		playerInfoModal.showModal(
																			member.userId,
																			seasonTeam,
																			undefined,
																			undefined,
																			member.paymentStatus
																		)
																	"
																	(onRemoveFromTeam)="showRemoveUserFromTeamModal(seasonTeam.team.id, member)"
																	(onTeamCaptainAssignClick)="
																		markMemberAsCaptain(seasonTeam.team.id, $event.player.userId)
																	"
																	[team]="seasonTeam"
																	(onInviteSend)="onPlayerCardResendInvite($event)"
																>
																</rc-player-card>
															</div>

															<div class="col-md-3 col-sm-4 col-lg-3 no-drag">
																<div
																	class="empty-player-card text-center"
																	style="margin-bottom: 15px"
																	(click)="teamInviteModal.showModal(seasonTeam.team.id)"
																>
																	<div class="inner-content">
																		<i class="fa fa-plus"></i>
																		Add Team <br />
																		Member
																	</div>
																</div>
															</div>
															<div
																class="col-md-3 col-sm-4 col-lg-3 no-drag"
																*ngIf="
																	season?.rosterStatus === RCSeasonRosterStatusEnum.DRAFT &&
																	seasonTeam?.team?.creatorType !== 'user'
																"
															>
																<div
																	class="empty-player-card text-center"
																	style="margin-bottom: 15px"
																	nz-tooltip
																	[nzTooltipTitle]="
																		'Sorry, we can\'t let you do that... sending an invite to an unpublished roster would be weird right? So you\'re not allowed to invite players to the teams before publishing the roster... you can only add players to teams from the registration pool'
																	"
																>
																	<div class="inner-content">
																		<i class="fa fa-info-circle"></i>
																		Add Player
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="BottomActionsBar" [ngClass]="{ 'BottomActionsBar--hidden': !actionsPool?.length }">
		<div class="BottomActionsBar__container">
			<rc-loading-btn
				[loading]="processing"
				(onClick)="saveChanges()"
				[text]="'SAVE CHANGES'"
				[classes]="['button', 'button-assertive', 'pull-right']"
			>
			</rc-loading-btn>
		</div>
	</div>
</div>

<rc-remove-user-from-team-modal
	[player]="removeFromTeamPlayer?.member"
	[customerId]="removeFromTeamPlayer && removeFromTeamPlayer.customerId"
	[invoiceId]="removeFromTeamPlayer && removeFromTeamPlayer.invoiceId"
	#removeUserFromTeamModal="modal"
	(cancel)="closeRemoveUserFromTeamModal()"
	(navigateInvoice)="navigateInvoicePage($event)"
>
</rc-remove-user-from-team-modal>
<rc-team-member-transfer-modal
	#memberTransferModal="modal"
	(onSubmit)="playerTransferTeams($event)"
></rc-team-member-transfer-modal>
<rc-team-player-invite-modal
	(closed)="onInviteModalClose()"
	[step]="teamInviteModalStep"
	(changeStep)="onChangeStep($event)"
	(reload)="retreiveSeasonTeams()"
	#teamInviteModal="modal"
></rc-team-player-invite-modal>
<rc-team-edit-modal #teamEditModal="modal" (onSubmit)="reloadTeams()" [seasonId]="seasonId" [leagueId]="leagueId">
</rc-team-edit-modal>
<rc-move-team-to-season-modal #moveTeamToSeasonModal="modal" (onSubmit)="reloadTeams()"> </rc-move-team-to-season-modal>
<rc-move-player-to-season-modal #movePlayerToSeasonModal="modal" (onSubmit)="retreiveSeasonPool()">
</rc-move-player-to-season-modal>
<rc-confirmation-modal
	[title]="'SAVE CHANGES'"
	[content]="'You must save roster changes before making further actions'"
	[confirmText]="'SAVE'"
	(onConfirm)="saveChanges()"
	#saveModal="modal"
>
</rc-confirmation-modal>

<rc-teams-constraints-modal #teamConstraintModal="modal"> </rc-teams-constraints-modal>

<rc-create-division-modal [season]="season" (onUpdate)="divisionsUpdated()" #createDivisionModal="modal">
</rc-create-division-modal>

<rc-player-season-info-modal
	(onPlayerRejectClick)="removePlayerConfirmModal.showModal($event)"
	(onPlayerRestoreClick)="entityRestoreToPool($event.seasonPool.id)"
	(onChange)="playerInfoChanged()"
	[season]="season"
	#playerInfoModal="modal"
>
</rc-player-season-info-modal>

<rc-confirmation-modal
	[type]="'danger'"
	[title]="'REMOVE PLAYER FROM SEASON'"
	[content]="
		'Are you sure you want to remove the player from season? He will be moved to the archive and notified that he is no longer playing the season'
	"
	[confirmText]="'YES, REMOVE PLAYER'"
	(onConfirm)="removeSeasonPlayer($event)"
	#removePlayerConfirmModal="modal"
>
</rc-confirmation-modal>

<rc-confirmation-modal
	[type]="'danger'"
	[title]="'REMOVE TEAM FROM SEASON'"
	[content]="
		'Are you sure you want to remove the team from season? all players will be moved to the archive and notified they\'re no longer playing'
	"
	[confirmText]="'YES, REMOVE TEAM'"
	(onConfirm)="removeSeasonTeam($event)"
	#removeTeamConfirmModal="modal"
>
</rc-confirmation-modal>

<rc-roster-xls-upload-modal
	[season]="season"
	#rosterXlsModal="modal"
	(onUploaded)="playerInfoChanged()"
></rc-roster-xls-upload-modal>
