import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private auth: AuthenticationService) {}

	canActivate() {
		if (this.auth.isLoggedIn()) {
			return true;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}
}
