import { ICalendarPropState } from '@app/react/types/calendar';
import { ReactNode } from 'react';
import { atom, AtomEffect, DefaultValue, selector } from 'recoil';
import * as moment from 'moment';
import { Resources } from '../types/packege';
import { start } from 'repl';

const resources = atom<Resources[]>({
	key: 'resources',
	default: [],
});

interface ISlot {}

const slots = atom<{ events: any; eventsToSpaces: any }>({
	key: 'slots',
	default: { events: {}, eventsToSpaces: {} },
});
const draftSlots = atom<{ events: any; eventsToSpaces: any }>({
	key: 'draftSlots',
	default: { events: {}, eventsToSpaces: {} },
});

interface IFacility {}

const facilities = atom<IFacility[]>({
	key: 'facilities',
	default: [],
});

const selectedFacility = atom<number>({
	key: 'selectedFacility',
	default: 0,
});

const today = moment().format('YYYY-MM-DD');

const selectedDate = atom<string>({
	key: 'selectedDate',
	default: today,
});

const spaces = atom<any[]>({
	key: 'spaces',
	default: [],
});

const showSpaces = atom<{ [key: number]: number[] }>({
	key: 'showSpaces',
	default: {},
});

export enum ECalendarMode {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
}

export enum ECalendarView {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal',
	LIST = 'list',
}

const view = atom<ECalendarView>({
	key: 'view',
	default: ECalendarView.VERTICAL,
});

const mode = atom<ECalendarMode>({
	key: 'mode',
	default: ECalendarMode.DAILY,
});

const dateRange = atom<{ startDate: string; endDate: string }>({
	key: 'dateRange',
	default: { startDate: today, endDate: today },
});

const isFilters = atom<boolean>({
	key: 'isFilters',
	default: false,
});

const filters = atom<{ [x: string]: string[] | number[] } | null>({
	key: 'filters',
	default: null,
});

interface IGroup {
	name: string;
	childrenSlotIds: number[];
}

export interface IGroups {
	[x: number]: IGroup[];
}

const groups = atom<IGroups>({
	key: 'groups',
	default: {},
});

interface IFilterResources {
	[x: number]: number[];
}

const filterResources = atom<IFilterResources | {}>({
	key: 'filterResources',
	default: {},
});

const selectedMonthlyResource = atom<number>({
	key: 'selectedMonthlyResource',
	default: 0,
});

const isLoading = atom<boolean>({
	key: 'isLoading',
	default: false,
});

const isFirstRender = atom<boolean>({
	key: 'isFirstRender',
	default: true,
});

const groupedResources = atom<number[]>({
	key: 'groupedResources',
	default: [],
});

const scrollTo = atom<any>({
	key: 'scrollTo',
	default: null,
});

const calendarPropState = atom<ICalendarPropState>({
	key: 'calendarPropState',
	default: undefined,
});

const calendarKey = atom<number>({
	key: 'calendarKey',
	default: 0,
});

const selectedGroup = atom<{ [x: number]: string }>({
	key: 'calendarKey',
	default: {},
});

const scrollPosition = atom<{ scrollTop: number; scrollLeft: number }>({
	key: 'scrollPosition',
	default: {
		scrollTop: 0,
		scrollLeft: 0,
	},
});

export const calendarStore = {
	resources,
	isFirstRender,
	slots,
	facilities,
	scrollTo,
	selectedFacility,
	spaces,
	selectedDate,
	mode,
	isFilters,
	filters,
	groups,
	showSpaces,
	filterResources,
	view,
	selectedMonthlyResource,
	dateRange,
	draftSlots,
	isLoading,
	groupedResources,
	calendarPropState,
	calendarKey,
	selectedGroup,
	scrollPosition,
};
