import { ReactNode } from 'react';
import { ColumnType } from './HeaderRow';

export enum AlignType {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
}

type TFooterComponents = 'pagination' | 'labels' | 'footer';

export enum ETableSource {
	PARTICIPANTS = 'participants',
}

export enum CellOptionsEnum {
	DATE = 'date',
	TIME = 'time',
	SPACE = 'space',
	CUSTOM = 'custom',
	ICON = 'icon',
	STATUS = 'status',
	STATUS_WITH_ICON = 'statusWithIcon',
	BALANCE = 'balance',
	AGE = 'age',
	BOOLEAN = 'boolean',
	DATETIME = 'datetime',
	CURRENCY = 'currency',
	DESCRIPTION = 'description',
	INVOICE = 'invoice',
	LONG_STRING = 'longString',
	PAYMENT_METHOD_WITH_ICON = 'paymentMethodWithIcon',
	PAYMENT_METHOD = 'paymentMethod',
	FEE = 'fee',
	FEE_WITH_ICON = 'feeWithIcon',
	DATE_WITH_TIME_RANGE = 'dateWithTimeRange',
	ADDON_COUNT = 'addonCount',
	RESOURCE_TYPE = 'resourceType',
	PRICE_TYPE = 'priceType',
}


export interface TableProps {
	rows: any;
	columns: ColumnType[];
	isLoading?: boolean;
	isSelectRow?: boolean;
	isHoverRow?: boolean;
	isRadio?: boolean;
	page?: number;
	ssr?: boolean;
	subBottomBorder?: boolean;
	expandable?: boolean;
	expandableKey?: string;
	expandOn?: string[];
	pagination?: boolean;
	isExpand?: boolean;
	expandedRows?: number[];
	maxHeight?: number;
	onSelect?: (val: unknown) => void;
	isAllSelected?: boolean;
	onSelectAll?: (val: unknown) => void;
	selectedArr?: unknown[];
	subject: string;
	bottomBorder?: boolean;
	meta?: {
		totalItems?: number;
		itemsPerPage?: number;
		totalPages?: number;
		currentPage?: number;
		totalSelected?: number; // this for support the selection in the session schedule
	};
	source?: ETableSource;
	maxSelected?: number;
	customEmptySpace?: boolean;
	specificDisabledRows?: number[];
	padding?: boolean;
	disablePaddingForPagination?: boolean;
	disableShadow?: boolean;
	infiniteScroll?: boolean;
	fetchNextPage?: () => void;
	removeShadows?: boolean;
	removeRowSeparator?: boolean;
	removeHeader?: boolean;
	footerOrder?: TFooterComponents[];
	footer?: ReactNode;
	isVirtualized?: boolean;
	defaultSortColumn?: string;
	emptyTableMessage?: string;
	isRowsError?: boolean;
	rowsErrorMessage?: string;
	isSelectIndeterminate?: boolean;
	timezone?: string;
	onPageChange?: (page: number) => void;
}
